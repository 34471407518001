import React from "react";
import PropTypes from "prop-types";

// styles
import styles from "./styles.css";

// images
import RightTick from "./images/right-tick.svg";

// components

const ConvenienceServicesIncludedSection = ({
    servicesCovered = []
}) => {

    return (
        <div>
            {
                (servicesCovered || []).map((serviceItem, idx) => {
                    return <p key={idx}  styleName={"styles.servicesConveredItem"}  > <img src={RightTick} /> {serviceItem}</p>;
                })
            }
        </div>
    );
};

ConvenienceServicesIncludedSection.propTypes = {
    servicesCovered: PropTypes.array
};

export default ConvenienceServicesIncludedSection;
