/* eslint-disable no-invalid-this */
/* eslint-disable max-statements */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";

// Icons
import ArrowIcon from "../../shared/arrow";
import IconLocationOrange from "../header-revamp-v2/images/location-on-orange.svg";

// Tracking
import { trackDesktopCustomEventsAE, trackDesktopMultipleEventsAE } from "../../../tracking/desktop/tracking";

// Components
import Logo from "../../shared/logo/component";
import withLogin from "../with-login";
import UsedCarDropdown from "./../used-car-dropdown";
import MenuSideBar from "./../menu-side-bar";

// Styles
import styles from "./styles.css";
import homeObject from "../../../locales/desktop/home";

// Constants
import { appUrl } from "../../../constants/url-constants";
import { SELL_CAR_HEADER_INFO, GA_EVENT_DATA } from "../header-revamp-v2/constant";
import { ROUTE_NAME, ROUTE_PATHS } from "../../../constants/routes-constants";
import MakeModalSearchInfo from "../make-modal-search-info";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import useOutsideClick from "../../../hooks/use-outside-click";
import useLink from "../../../hooks/use-link";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { pageSectionsConfig } from "../home/constants";
import { parseJsonObj } from "../../../utils/helpers/json-parse";

// eslint-disable-next-line complexity
const HomePageHeader = ({
    selectedCity: {
        name: selectedCityName
    },
    allFilters,
    updateLocationPopupShownConnect,
    isLoggedIn,
    cities,
    homepageTemplate,
    reloadCarListConnect
}) => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = parseLocationSearchParams(location.search);
    const [searchTerm, setSearchTerm] = useState(queryParams.search);
    const [isSearchModal, setIsSearchModal] = useState(queryParams.source === "searchModel" ? true : false);
    const { make = {} } = allFilters || {};
    const { pageSections = [] } = homepageTemplate || {};

    const { getLinkProps } = useLink();
    const { param, label } = GA_EVENT_DATA[ROUTE_NAME.HOME] || {};

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [history]);

    const handleClickSearchBox = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        await yieldToMainThread();
        setIsSearchModal(!isSearchModal);
        if (!isSearchModal) {
            trackDesktopMultipleEventsAE("newHeaderSearchbarClick", param, { eventLabel: label });
        }
    };
    const toggelSearchBarModal = async () => {
        await yieldToMainThread();
        setIsSearchModal(!isSearchModal);
    };
    const popupRef = useOutsideClick(() => {
        if (isSearchModal) setIsSearchModal(false);
    });
    const onLogoClick = async () => {
        await yieldToMainThread();
        history.push("/");
        await yieldToMainThread();
        trackDesktopCustomEventsAE("logoClick");
        trackDesktopMultipleEventsAE("newHeaderCars24LogoClick", param, { eventLabel: label });
    };

    const onLocationClick = async () => {
        await yieldToMainThread();
        updateLocationPopupShownConnect(true);
        trackDesktopCustomEventsAE("locationPageShown", { eventLabel: "listing_change" });
        trackDesktopMultipleEventsAE("newHeaderLocationClick", param, { eventLabel: label });
    };

    const fireGaEvents = (eventAction, eventLabel) => {
        trackDesktopMultipleEventsAE("newHeaderSeoItemsClick", param, { eventAction, eventLabel });
    };

    const onSellItemClick = async (url) => {
        await yieldToMainThread();
        window.open(url, "_blank");
        fireGaEvents("sell_any_car_seo_click", url);
    };

    const handleEvents = (url) => {
        fireGaEvents("buy_user_car_seo_click", url);
    };

    const navBarTemplate = pageSections.find(pageSection => pageSection?.pageSection?.name === pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_NAV_BAR) || {};
    const { pageSection = {}} = navBarTemplate || {};
    const { backgroundColor = "#0032a6", gradientColor = ""} = pageSection || {};

    const { sectionData = [] } = pageSection || {};

    // Dynamic Nav bar Content

    const logoInfo = sectionData.find(info => info?.title === "cars24Logo") || {};
    const locationInfo = sectionData.find(info => info?.title === "location") || {};
    const sellCarInfo = sectionData.find(info => info?.title === "sellCar") || {};
    const userNameInfo = sectionData.find(info => info?.title === "userName") || {};
    const menuBarInfo = sectionData.find(info => info?.title === "menuBar") || {};

    const {
        image: {
            url: logoImageUrl = ""
        } = {}
    } = logoInfo || {};

    const {
        image: {
            url: locationImageUrl = ""
        } = {},
        description: locationDescription = ""
    } = locationInfo || {};

    const {
        image: {
            url: userNameImageUrl = ""
        } = {},
        description: userNameDescription = ""
    } = userNameInfo || {};

    const {
        image: {
            url: menuBarImageUrl = ""
        } = {}
    } = menuBarInfo || {};

    const {
        image: {
            url: sellCarImageUrl = ""
        } = {},
        description: sellCarDescription = ""
    } = sellCarInfo || {};

    const locationStyles = parseJsonObj(locationDescription);
    const {color: locationTextColor = "#ffffff"} = locationStyles || {};

    const userNameStyles = {...parseJsonObj(userNameDescription), userNameImageUrl };
    const sellCarStyles = {...parseJsonObj(sellCarDescription), sellCarImageUrl };

    return (
        <React.Fragment>
            <div styleName={"styles.headerRef"} >
                <div styleName={"styles.mainHeader"} style={{ backgroundColor: gradientColor || backgroundColor }}>
                    <div styleName={"styles.mainWrapper"}>
                        <div className={""} styleName={"styles.align_center styles.flexRow"}>
                            <div styleName="styles.logoLocationWrap">
                                <div styleName="styles.logoWrapper">
                                    {
                                        <a {...getLinkProps(`/`, { onClickHandler: () => onLogoClick() })}>
                                            <Logo isHomePage={true} height={36} width={80} imageUrl={logoImageUrl}/>
                                        </a>
                                    }
                                </div>
                                <span styleName={"styles.locationWrap"} style={{color: locationTextColor}} onClick={onLocationClick}>{selectedCityName} <img src={locationImageUrl || IconLocationOrange} alt="" height={20} width={20} /></span>
                            </div>
                            <div styleName={"styles.globalSearch"} onClick={handleClickSearchBox}>
                                <div styleName="styles.hederGlobalSearch">
                                    <div ref={popupRef}>
                                        <MakeModalSearchInfo
                                            toggelSearchBarModal={toggelSearchBarModal}
                                            source={ROUTE_NAME.HOME}
                                            setSearch={setSearchTerm}
                                            searchVal={searchTerm}
                                            param={param} label={label} isNew
                                            showSearchContent={isSearchModal}
                                            isNewHeader={true}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div styleName={"styles.rightMenuGridWrap"}>
                                <MenuSideBar
                                    locales={homeObject.userDropDown}
                                    isHomePage={true}
                                    routeName={ROUTE_NAME.HOME}
                                    isLoggedIn={isLoggedIn}
                                    showLogin={!isLoggedIn}
                                    param={param}
                                    label={label}
                                    isNewHeader={true}
                                    reloadCarListConnect={reloadCarListConnect}
                                    userNameStyles={userNameStyles}
                                    menuBarImageUrl={menuBarImageUrl}
                                    sellCarStyles={sellCarStyles}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div styleName={"styles.secondaryMenu"}>
                <div styleName={"styles.container"}>
                    <ul styleName={"styles.dFlex styles.align_center"}>
                        <UsedCarDropdown make={make} isListingPage={true} handleEvents={handleEvents} cities={cities} />
                        <li styleName={"styles.cityWrap styles.marginLeft"}>
                            <p styleName={"styles.cities"}>
                                <label>Sell car</label>
                                <span><ArrowIcon darkblue rotateBy={0} /></span>
                            </p>
                            <div styleName={"styles.dropdownMenu"}>
                                <ul>
                                    {SELL_CAR_HEADER_INFO.map((info, index) => (
                                        <li key={index}>
                                            <a
                                                {...getLinkProps(
                                                    `${info.link}`,
                                                    {
                                                        onClickHandler: () =>
                                                            onSellItemClick(
                                                                `${appUrl()}${info.link}`
                                                            )
                                                    }
                                                )}
                                            >{info.text}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                        <a styleName={`styles.carLoan`} href={`/${ROUTE_PATHS.carLoan}/`} onClick={() => {
                            trackDesktopCustomEventsAE("carLoanDetailPage", { eventLabel: label });
                        }}>Car loan calculator</a>
                    </ul>
                </div>
            </div>
        </React.Fragment >
    );
};

HomePageHeader.propTypes = {
    history: PropTypes.object.isRequired,
    selectedCity: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string
    }),
    allFilters: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    userName: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    userProfileConnect: PropTypes.func,
    updateLocationPopupShownConnect: PropTypes.func,
    source: PropTypes.string,
    cities: PropTypes.array,
    homepageTemplate: PropTypes.object
};

export default withLogin(HomePageHeader);
