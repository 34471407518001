import { trackCustomEventsV2 } from "./custom-event";

export const trackPlatformCustomEventsAEV2 = async (eventData, extras = {}) => {
    if (window && (typeof eventData !== "string")) {
        window.setTimeout(() => {
            trackCustomEventsV2({
                ...(eventData || {}),
                ...(extras || {})
            });
        }, 0);
    }
};
