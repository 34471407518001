/* eslint-disable max-statements */

/* eslint-disable max-params */
export default (api) => {

    const fetchLeadAppointmentInfo = (appointmentId, params, token) => {
        return api.get(`/v1/sell-online/appointment/apt-${appointmentId}`, { params,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const fetchVehicleSpecs = () => {
        return api.get(`/v1/config/vehicle-inspection-metric`);
    }; // NU

    const createLeadAppointment = (payload, token) => {
        return api.post(`/v1/sell-online/appointment`, { ...payload }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const updateLeadAppointment = (appointmentId, payload, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}`, { ...payload }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const sendAppointmentOtp = (appointmentId, token) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/otp/send`, null, {
            headers: {
                X_COUNTRY: "AE",
                "Authorization": `Bearer ${token}`
            }
        });
    }; // NU

    const verifyAppointmentOtp = (appointmentId, payload, token) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/otp/verify`, { ...payload }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }; // NU

    const fetchDsOfferedPrice = (appointmentId, token) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/offered-price`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const fetchAppointmentSlots = (token) => {
        return api.get(`/v1/sell-online/appointment/booking-slot`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }; // NU

    const confirmLeadAppointment = (appointmentId, payload, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/confirm-appointment`, { ...payload }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }; // NU

    const fetchPricePostInspection = (appointmentId, token) => {
        return api.get(`/v1/sell-online/appointment/apt-${appointmentId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const updateInspectionEvaluationStatus = (appointmentId, statusCode, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/post-inspection/${statusCode}`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const submitDisputesToInspection = (appointmentId, payload, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/raise-dispute`, { ...payload }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }; // NU

    const uploadSellerDocuments = (appointmentId, formData, token) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/upload-documents`, formData, {
            headers: {
                "content-type": "multipart/form-data;",
                "Authorization": `Bearer ${token}`
            }
        });
    }; // NU

    const updateStatePostUpload = (appointmentId, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/upload-document`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const updatePriceStatus = (appointmentId, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/accept-price`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }; // NU

    const fetchCarStores = () => {
        return api.get(`/v1/config/cars24-stores`);
    }; // NU

    const cancelSellerInspection = (appId, payload, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appId}/cancel-appointment`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getAppointmentsWithPhone = (payload, token) => {
        return api.post(`v2/sell-online/sell-orders?page=1&size=30`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const provideCRMLeadConfirmation = (appointmentId, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/provide-appointment-confirmation`, {}, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getFeedbackOptions = () => {
        return api.get(`/v1/config/reasons`);
    };
    const submitFeedbackReason = (appointmentId, payload, token) => {
        return api.put(`/v1/sell-online/appointment/apt-${appointmentId}/submit-feedback`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const bookInspection = (appointmentId, type, payload, token) => {
        return api.post(`/v1/sell-online/appointment/apt-${appointmentId}/book-appointment/${type}`, { ...payload }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const uploadDocument = (appointmentId, payload, token, isWebview) => {

        const customHeader = {
            "content-type": "multipart/form-data",
            "Authorization": `Bearer ${token}`
        };

        return api.post(`/v1/document/${appointmentId}/upload`, payload, {
            headers: {...customHeader, "source": isWebview ? "SELLER_APP" : "SELLER"}
        });
    };

    const removeDocument = (appointmentId, documentId, token) => {
        return api.delete(`/v1/document/${appointmentId}/remove/${documentId}`,  {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const fetchDocuments = (appointmentId, token) => {
        return api.get(`/v1/document/fetch/${appointmentId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    };

    const getConfig = () => {
        return api.get(`/v1/config/variables`);
    };

    const getPaymentStatus = (appId, bookingOrderId, orderId, secureToken) => api.get(`/v1/appointment-booking/apt-${appId}/payment-status/${bookingOrderId}/${orderId}`, {headers: { "Authorization": `Bearer ${secureToken}` }});

    const getSellingOptions = (appId, secureToken) => api.get(`/v1/sell-option/appointment/apt-${appId}`, {headers: { "Authorization": `Bearer ${secureToken}` }});

    const getInspectionModes = (appId, secureToken, sellingOptionType) => api.get(`/v1/sell-option/appointment/apt-${appId}/inspection-modes${sellingOptionType ? `?sellingOptionType=${sellingOptionType}` : ""}`, {headers: { "Authorization": `Bearer ${secureToken}` }});

    const getSellConfig = () => {
        return api.get(`/v1/config/selling-options`);
    };

    const getListPackages = (appId, secureToken) => {
        return api.get(`/v1/sell-option/appointment/apt-${appId}/listing-packages`, {headers: { "Authorization": `Bearer ${secureToken}` }});
    };

    const createListOrderByPayment = (payload, secureToken) => api.post(`/v1/list/appointment/create-order`, payload, { headers: { "Authorization": `Bearer ${secureToken}` } });

    const getListPaymentStatus = (appointmentId, bookingOrderId, ledgerId,  secureToken) => {
        return api.get(`/v1/list/appointment/apt-${appointmentId}/payment-status/${bookingOrderId}/${ledgerId}`, {headers: { "Authorization": `Bearer ${secureToken}` }});
    };
    return {
        fetchVehicleSpecs,
        fetchLeadAppointmentInfo,
        createLeadAppointment,
        updateLeadAppointment,
        sendAppointmentOtp,
        verifyAppointmentOtp,
        fetchDsOfferedPrice,
        fetchAppointmentSlots,
        confirmLeadAppointment,
        fetchPricePostInspection,
        submitDisputesToInspection,
        updateInspectionEvaluationStatus,
        uploadSellerDocuments,
        updateStatePostUpload,
        updatePriceStatus,
        fetchCarStores,
        cancelSellerInspection,
        getAppointmentsWithPhone,
        provideCRMLeadConfirmation,
        getFeedbackOptions,
        submitFeedbackReason,
        bookInspection,
        uploadDocument,
        removeDocument,
        fetchDocuments,
        getConfig,
        getPaymentStatus,
        getSellingOptions,
        getInspectionModes,
        getSellConfig,
        getListPackages,
        createListOrderByPayment,
        getListPaymentStatus
    };
};
