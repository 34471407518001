import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import TestimonialsBg from "./images/testimonials-bg.webp";
import LazyImage from "../../shared/lazy-image";
import SlickWrapper from "../../shared/slick-wrapper";
import TestimonialCard from "./testimonial-card";

// components

// constants

// tracking

const ServicingTestimonials = ({
    sectionInfo = {},
    googleReviewsSection = {}
}) => {

    const { title = "What our customers say" } = sectionInfo || {};

    const {
        reviewInfo = [],
        googleReviews = []
    } = googleReviewsSection || {};

    const sliderSettings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrow: true,
        dots: false,
        infinite: false,
        gap: 2
    };

    return (
        <div styleName={"styles.testimonialsSectionWrapper"}   >
            <div className="container" >
                <div styleName={"styles.testimonialsBgWrapper"} >
                    <LazyImage src={TestimonialsBg} />
                </div>
                <div styleName={"styles.testimonialsSectionBody"}  >
                    <div className="container" >
                        <div styleName={"styles.titleCtn"}  >
                            <p styleName={"styles.titleText"}  >{title}</p>
                        </div>
                        {reviewInfo && reviewInfo.length > 0 && <div styleName={"styles.satisfactionMetricCtn"} >
                            {
                                (reviewInfo || []).map(review => {
                                    const { title: reviewTitle, description } = review;
                                    return (<div styleName={"styles.satisfactionMetricItem"} key={reviewTitle} >
                                        {title && <p styleName={"styles.mainText"}  >{reviewTitle}</p>}
                                        {description && <p styleName={"styles.subText"}  >{description}</p>}
                                    </div>);
                                })
                            }

                        </div>}
                        {googleReviews && googleReviews.length > 0 && <div>
                            <div styleName={"styles.testimonialsCardsSlider"}   >
                                <SlickWrapper {...sliderSettings}>
                                    {
                                        (googleReviews || []).map(googleReview => {
                                            const { name, rating, comment, reviewDate } = googleReview || {};
                                            return (<TestimonialCard key={`google_review_${name}`} name={name} rating={rating} comment={comment} reviewDate={reviewDate} />);
                                        })
                                    }
                                </SlickWrapper>

                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

ServicingTestimonials.propTypes = {
    sectionInfo: PropTypes.object,
    googleReviewsSection: PropTypes.object
};

export default ServicingTestimonials;
