import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ServicingTypesOfService from "./component";
import { fetchServiceDescription } from "../car-servicing-home-page/actions";

const mapStateToProps = ({
    carServicing: {
        tofServices = {}
    }
}) => ({ tofServices });

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchServiceDescriptionConnect: fetchServiceDescription
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServicingTypesOfService);
