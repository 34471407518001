import Types from "./types";
import { ListingService, DeliveryService, couponService, CFService, CheckoutAEService as CheckoutService } from "../../../service";
// import cleverTap from "../../../tracking/clevertap";
import { showToast } from "../../shared/toast-message/actions";
import { HTTP_CODES } from "../../../constants/app-constants";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { TOAST_MESSAGE, TOAST_VARIANT } from "../../shared/toast-message/constant";
import { b2cDetailPageEvents } from "../../../tracking/msite/b2c-detail-page-events";
// import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
// import spinCarImageListParser from "../../../utils/helpers/spin-car-image-list-parser";

const fetchDetails = () => ({
    type: Types.FETCH_CAR_DETAILS
});

const fetchCarDetailsSuccess = (data) => ({
    type: Types.FETCH_CAR_DETAILS_SUCCESS,
    data
});

const fetchCarDetailsFailure = (error) => ({ type: Types.FETCH_CAR_DETAILS_FAILURE, error });

// eslint-disable-next-line max-params
const fetchCarDetails = (carId, variantType = "updatedGalleryDesign") => (dispatch, getState) => {
    dispatch(fetchDetails());
    const {user: {secureToken = ""}} = getState() || {};
    return new Promise((resolve, reject) => {
        ListingService.fetchCarDetail(carId, variantType, secureToken)
            .then(response => {
                dispatch(fetchCarDetailsSuccess(response.data));
                // if (trackCleverTap) {
                //     const { interiorImages, exteriorImages } = response.data.detail?.spinMedia || {};
                //     const imagesList = [...spinCarImageListParser(exteriorImages).slice(NUMBER.ZERO, NUMBER.THREE), ...spinCarImageListParser(interiorImages).slice(NUMBER.ZERO, NUMBER.TWO)];
                //     const imageHost = response?.data?.config?.imageHost;
                //     cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.VIEWED_CAR, { content: response.data.detail, config: response.data.config });
                //     cleverTap.mobileCleverTapEvent(cleverTap.mobileConstants.CAR_DETAILS_PAGE, {
                //         carDetailsContent: response.data.detail,
                //         imageHost,
                //         image1: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.ZERO]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         image2: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.ONE]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         image3: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.TWO]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         image4: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.THREE]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         image5: fastlyUrl({
                //             basePath: imageHost,
                //             path: imagesList[NUMBER.FOUR]?.path,
                //             type: imageTypes.aeCarDetailPreview
                //         }),
                //         cityName: getState()?.cities?.selectedCity?.name
                //     });
                // }
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarDetailsFailure(error));
                reject(error);
            });
    });
};

// eslint-disable-next-line max-params
const fetchPostBcCarDetails = (carId) => (dispatch, getState) => {
    const {user: {secureToken = ""}} = getState() || {};
    dispatch(fetchDetails());
    return new Promise((resolve, reject) => {
        ListingService.fetchPostBcCarDetail(carId, secureToken)
            .then(response => {
                dispatch(fetchCarDetailsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchCarDetailsFailure(error));
                reject(error);
            });
    });
};

// Similar Cars
const fetchSimilarCarsInit = () => ({
    type: Types.FETCH_SIMILAR_CARS
});
const fetchSimilarCarsSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_CARS_SUCCESS,
    data
});
const fetchSimilarCarsFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CARS_FAILURE,
    error
});
const fetchSimilarCars = (carId, params) => dispatch => {
    dispatch(fetchSimilarCarsInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCars(carId, params)
            .then(response => {
                dispatch(fetchSimilarCarsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsFailure(error));
                reject(error);
            });
    });
};

const fetchSimilarCarsV2 = (carId, params) => dispatch => {
    dispatch(fetchSimilarCarsInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCarsV2(carId, params)
            .then(response => {
                dispatch(fetchSimilarCarsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsFailure(error));
                reject(error);
            });
    });
};

const fetchSiteMap = (params) => () => {
    return new Promise((resolve, reject) => {
        ListingService.fetchSiteMap(params)
            .then(response => {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};

const fetchLinkingPattern = (params) => () => {
    return new Promise((resolve, reject) => {
        ListingService.fetchLinkingPattern(params)
            .then(response => {
                resolve(response.data);
            }).catch(err => reject(err));
        });
};

// Similar Cars For Modal
const fetchSimilarCarsForModalInit = () => ({
    type: Types.FETCH_SIMILAR_CARS_FOR_MODAL_INIT
});

const fetchSimilarCarsForModalSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_CARS_FOR_MODAL_SUCCESS,
    data
});

const fetchSimilarCarsForModalFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CARS_FOR_MODAL_FAILURE,
    error
});

const fetchSimilarCarsForModal = (carId, params) => (dispatch, getState) => {
    const {user: {secureToken = ""}} = getState() || {};
    dispatch(fetchSimilarCarsForModalInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCarsV2(carId, params, secureToken)
            .then(response => {
                dispatch(fetchSimilarCarsForModalSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsForModalFailure(error));
                reject(error);
            });
    });
};

// Price Benefit
const fetchPriceBenefitInit = () => ({
    type: Types.FETCH_PRICE_BENEFIT_INIT
});

const fetchPriceBenefitSuccess = (data) => ({
    type: Types.FETCH_PRICE_BENEFIT_SUCCESS,
    data
});

const fetchPriceBenefitFailure = (error) => ({
    type: Types.FETCH_PRICE_BENEFIT_FAILURE,
    error
});

const fetchPriceBenefit = (carId, params) => dispatch => {
    dispatch(fetchPriceBenefitInit());
    return new Promise((resolve, reject) => {
        CFService.fetchPriceBenefit(carId, params)
            .then(response => {
                dispatch(fetchPriceBenefitSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchPriceBenefitFailure(error));
                reject(error);
            });
    });
};

// Similar Cars for Comparison
const fetchSimilarCarsCompareInit = () => ({
    type: Types.FETCH_SIMILAR_CARS_COMPARE
});
const fetchSimilarCarsCompareSuccess = (data) => ({
    type: Types.FETCH_SIMILAR_CARS_COMPARE_SUCCESS,
    data
});
const fetchSimilarCarsCompareFailure = (error) => ({
    type: Types.FETCH_SIMILAR_CARS_COMPARE_FAILURE,
    error
});
const fetchSimilarCarsCompare = (carId, params) => dispatch => {
    dispatch(fetchSimilarCarsCompareInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchSimilarCars(carId, params)
            .then(response => {
                dispatch(fetchSimilarCarsCompareSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchSimilarCarsCompareFailure(error));
                reject(error);
            });
    });
};

const updateCarDetailsSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const setGsGalleryData = (data) => ({
    type: Types.SET_GS_GALLERY_DATA,
    data
});

const updateSpincarModalStatus = (isSpinCarModalOpen) => ({
    type: Types.UPDATE_SPINCAR_MODAL_STATUS,
    isSpinCarModalOpen
});

const sendReportNotificationSuccess = (data) => ({
    type: Types.SEND_REPORT_NOTIFICATION_SUCCESS,
    data
});

const sendReportNotification = (params, token) => dispatch => {
    if (!params.orderId) {
        trackPlatformCustomEventsAEV2(b2cDetailPageEvents.b2cReportDownloadLinkRequest, {
            eventLabel: params.appointmentId
        });
    }
    return new Promise((resolve, reject) => {
        DeliveryService.sendReportNotification(params, token)
            .then(response => {
                dispatch(sendReportNotificationSuccess(response.data));
                resolve(response.data);
                if (response.status === HTTP_CODES.SUCCESS && !params.orderId) {
                    trackPlatformCustomEventsAEV2(b2cDetailPageEvents.b2cReportDownloadLinkSent, {
                        eventLabel: params.appointmentId
                    });
                    dispatch(showToast({
                        variant: TOAST_VARIANT.SUCCESS,
                        text: TOAST_MESSAGE.downloadLinkSentSuccessfully
                    }));
                } else if (response.status !== HTTP_CODES.SUCCESS && !params.orderId) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.EXCLAIM,
                        text: TOAST_MESSAGE.errorSendingDownloadLink
                    }));
                }
            }).catch(error => {
                if (!params.orderId) {
                    dispatch(showToast({
                        variant: TOAST_VARIANT.EXCLAIM,
                        text: TOAST_MESSAGE.errorSendingDownloadLink
                    }));
                }
                reject(error);
            });
    });
};

// Coupon info
const fetchCouponList = (params, version) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutService.getCouponList(params, version)
            .then(response => {
                resolve(response.data);
            }).catch(err => reject(err))
            .finally(() => dispatch({
                type: Types.COUPON_INFO_FETCHED,
                data: true
            }));
    });
};

const fetchLockedCouponList = (params) => () => {
    return new Promise((resolve, reject) => {
        couponService.getLockedCoupons(params)
            .then(response => {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};

const updateUnlockCoupon = (params, token) => () => {
    return new Promise((resolve, reject) => {
        couponService.unlockCoupon(params, token)
            .then(response => {
                resolve(response.data);
            }).catch(err => reject(err));
    });
};

const refreshCouponList = () => ({
    type: Types.REFRESH_COUPON_LIST
});

// Fetch hand picked cars
const fetchHandPickedCarsInit = () => ({
    type: Types.FETCH_HAND_PICKED_CARS_INIT
});

const fetchHandPickedCarsSuccess = (data) => ({
    type: Types.FETCH_HAND_PICKED_CARS_SUCCESS,
    data
});

const fetchHandPickedCarsFailure = (error) => ({
    type: Types.FETCH_HAND_PICKED_CARS_FAILURE,
    error
});

const fetchHandPickedCars = (params) => (dispatch, getState) => {
    const {user: {secureToken = ""}} = getState() || {};
    dispatch(fetchHandPickedCarsInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchHandPickedCars(params, secureToken)
            .then(response => {
                dispatch(fetchHandPickedCarsSuccess(response.data));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchHandPickedCarsFailure(error));
                reject(error);
            });
    });
};

const toggleIsShowStickyTabs = (data = false) => ({
    type: Types.TOGGLE_STICKY_TABS,
    data
});

const togglePriceBenifits = () => ({
    type: Types.TOGGLE_PRICE_BENIFITS
});

export {
    fetchCarDetails,
    updateCarDetailsSSRStatus,
    fetchSimilarCars,
    fetchSiteMap,
    fetchLinkingPattern,
    fetchSimilarCarsForModal,
    fetchSimilarCarsCompare,
    setGsGalleryData,
    fetchPriceBenefit,
    updateSpincarModalStatus,
    sendReportNotification,
    sendReportNotificationSuccess,
    fetchCouponList,
    fetchLockedCouponList,
    updateUnlockCoupon,
    refreshCouponList,
    fetchHandPickedCars,
    fetchPostBcCarDetails,
    toggleIsShowStickyTabs,
    fetchSimilarCarsV2,
    togglePriceBenifits
};
