export const SELLER_HELPLINE_NUMBER = { label: "+971 427 80404", value: "+97142780404", whatsapp: "97142780404" };
export const SELL_DEFAULT_CHAT_MESSAGE = `Hi,\r\n I want to sell my Car.`;
export const SELLER_CHAT_ON_WHATSAPP_TEXT = "From 8:30 AM to 8:30 PM";
export const SELLER_REQUEST_CALL_TIME_TEXT = "From 10:00 AM to 7:00 PM";
export const SELLER_CALL_NUMBER = "8001110900";

export const INSPECTION_REVAL_STATUS_CODE = {
    NO_INSPECTION_DONE: 0,
    SATISFIED_WITH_INSPECTION: 5,
    NOT_SATISFIED_WITH_INSPECTION: 10,
    PRICE_NOT_SATISFACTORY: 15,
    INSPECTION_NOT_ACCURATE: 20
};

export const PRICE_CHANGE_STATUS = {
    PRICE_CHANGED: "PRICE_CHANGED",
    PRICE_NOT_CHANGED: "PRICE_NOT_CHANGED",
    PRICE_NOT_GENERATED: "PRICE_NOT_GENERATED"
};

export const PRICE_DEVIATION_REJECTION_TYPE = {
    PRICE_NOT_SATISFACTORY: "PRICE_NOT_SATISFACTORY",
    INSPECTION_NOT_ACCURATE: "INSPECTION_NOT_ACCURATE"
};

export const INSPECTION_REVAL_STATUS = {
    NO_INSPECTION_DONE: "NO_INSPECTION_DONE",
    SATISFIED_WITH_INSPECTION: "SATISFIED_WITH_INSPECTION",
    NOT_SATISFIED_WITH_INSPECTION: "NOT_SATISFIED_WITH_INSPECTION",
    PRICE_NOT_SATISFACTORY: "PRICE_NOT_SATISFACTORY",
    INSPECTION_NOT_ACCURATE: "INSPECTION_NOT_ACCURATE",
    PRICE_REJECT_COMPLETE: "PRICE_REJECT_COMPLETE"
};

export const SELLER_DOCUMENTS_MASTER = [
    {
        type: "EMIRATES_ID",
        label: "Emirates ID",
        expanded: false,
        files: [
            {
                subType: "FRONT",
                name: "EMIRATES_ID_FRONT"
            },
            {
                subType: "BACK",
                name: "EMIRATES_ID_BACK"
            }
        ],
        mandatory: true
    },
    {
        type: "MULKIYA",
        label: "Mulkiya",
        expanded: false,
        files: [
            {
                subType: "FRONT",
                name: "MULKIYA_FRONT"
            },
            {
                subType: "BACK",
                name: "MULKIYA_BACK"
            }
        ],
        mandatory: true
    }
];

export const PAYMENT_MODE = {
    "BANK": "BANK",
    "CASH": "CASH"
};

export const POST_PA_CONSTANTS = {
    CUSTOMER: "CUSTOMER",
    PENDING: "PENDING",
    DONE: "DONE",
    UNDER_VERIFICATION: "UNDER_VERIFICATION",
    NOT_FILLED: "NOT_FILLED",
    COMPLETED: "COMPLETED",
    REJECTED: "REJECTED",
    UPLOADED: "UPLOADED",
    APPROVED: "APPROVED",
    PENDING_CLOSURE: "PENDING_CLOSURE"
};

export const PAYMENT_MODE_STATUS = {
    NOT_FILLED: "NOT_FILLED",
    UNDER_VERIFICATION_FROM_RA: "UNDER_VERIFICATION_FROM_RA",
    COMPLETED_CASH: "COMPLETED_CASH",
    UNDER_VERIFICATION_FROM_SELLER: "UNDER_VERIFICATION_FROM_SELLER",
    COMPLETED_BANK: "COMPLETED_BANK"
};

export const TOP_SELL_CITIES = [{ name: "Dubai" }, { name: "Abu Dhabi" }];
export const INSPECTION_TYPE = {
    HOME: "HOME_INSPECTION",
    WALK_IN: "WALK_IN",
    ONLINE: "ONLINE",
    SELF_INSPECTION: "SELF_INSPECTION",
    ONLINE_IMG: "ONLINE_IMG"
};
export const INSPECTION_EXPERIMENTS = {
    CONTROL: "HOME_INSPECTION",
    VARIANT: "ONLINE"
};
export const NPS_TYPES = {
    POST_PAYMENT: "post-payment",
    POST_INSPECTION: "post-inspection"
};
export const FEEDBACK_CATEGORY = {
    "post-payment": "postPayment",
    "post-inspection": "postInspection"
};
export const QUESTION_TYPES = {
    RATING: "rating",
    TEXT: "text",
    OPTION: "option"
};
export const SELLER_COOKIE_CONSTANTS = {
    PAYMENT_NPS: "postPaymentNps",
    INSPECTION_NPS: "postPaymentInspection",
    BACK_JOURNEY: "back_journey"
};
export const STATUS_TYPES = {
    SUCCESS: "SUCCESS",
    PENDING: "PENDING",
    APPROVED_FOR_CASH: "APPROVED_FOR_CASH"
};
export const SELLER_POPULAR_CITY = {
    "dubai": "Dubai",
    "abu-dhabi": "Abu Dhabi"
};

export const CURRENCY = {
    IN: "₹",
    AE: "AED",
    AU: "$"
};

export const AVAILABLE_INSPECTION_TYPES = [
    { inspectionType: INSPECTION_TYPE.HOME, active: true, label: "HOME", description: ["Inspection at your location to get instant price.", "40-45 mins of inspection by our expert."], amount: null},
    { inspectionType: INSPECTION_TYPE.ONLINE_IMG, active: true, label: "UPLOAD PHOTOS", description: ["Skip the wait, click photos of your car & submit", "5-10 Min of inspection by yourself"], amount: null},
    { inspectionType: INSPECTION_TYPE.WALK_IN, active: true, label: "CARS24 BRANCH", description: ["Choose from multiple hubs near your location", "40-45 mins of inspection by our expert"], amount: null}
];

export const SELLING_OPTIONS = {
    SELL: "SELL",
    LIST: "LIST",
    SELL_LIST: "SELL_LIST",
    NA: "NA"
};

export const PRE_INSPECTION_STEPS = [
    {id: "MAKE_PAYMENT", label: "Make Payment"},
    {id: "INSPECTION", label: "Inspection"},
    {id: "SET_CAR_PRICE", label: "Set car Price"}
];

export const POST_INSPECTION_STEPS = [
    {id: "SELECT_PACK", label: "Select Pack"},
    {id: "MAKE_PAYMENT", label: "Make Payment"},
    {id: "SET_CAR_PRICE", label: "Set car Price"}
];
