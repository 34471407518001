import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import LeftOrangeTrackArrow from "./images/left-orange-track-arrow.png";

// components
import LazyImage from "../../shared/lazy-image";

// constants

// tracking

const LearnMoreAboutCars24 = ({ learnMoreSection = {} }) => {

    const {
        description = "",
        title = ""
    } = learnMoreSection || {};

    return (
        <div styleName={"styles.learnMoreServicingWrap"} >
            <div styleName={"styles.leftArrow"} >
                <LazyImage src={LeftOrangeTrackArrow} />
            </div>
            <div styleName={"styles.rightArrow"} >
                <LazyImage src={LeftOrangeTrackArrow} />
            </div>
            <div styleName={"styles.learnMoreServicingBody"} className="container" >
                {title && <p styleName={"styles.mainText"}  >{title}</p>}
                {description && <p styleName={"styles.subText"}  >{description}</p>}
            </div>
        </div>
    );
};

LearnMoreAboutCars24.propTypes = {
    learnMoreSection: PropTypes.object
};

export default LearnMoreAboutCars24;
