export const pageSectionsConfig = {
    "BVC_HOME_PAGE_DESKTOP_HERO_BANNER": "valuationCertificateDesktopHeroBanner",
    "BVC_HOME_PAGE_DESKTOP_DEFINITION_SECTION": "valuationCertificateDesktopDefinitionSection",
    "BVC_HOME_PAGE_DESKTOP_PARTNER_BANKS_SECTION": "valuationCertificateDesktopPartnerBanksSection",
    "BVC_HOME_PAGE_DESKTOP_HOW_IT_WORKS_SECTION": "valuationCertificateDesktopHowItWorksSection",
    "BVC_HOME_PAGE_DESKTOP_BENEFITS_SECTION": "valuationCertificateDesktopBenefitsSection",
    "BVC_HOME_PAGE_DESKTOP_TESTIMONIAL_SECTION": "valuationCertificateDesktopTestimonialSection",
    "BVC_HOME_PAGE_DESKTOP_BLOG_SECTION": "valuationCertificateDesktopBlogSection",
    "BVC_HOME_PAGE_DESKTOP_REQUEST_CALLBACK_SECTION": "valuationCertificateDesktopRequestCallBack",
    "BVC_HOME_PAGE_DESKTOP_FAQ_SECTION": "valuationCertificateDesktopFaqSection",
    "BVC_HOME_PAGE_DESKTOP_SEO_CONTENT_SECTION": "valuationCertificateDesktopSeoContentSection"
};

export const defaultPageContent = {
    "isDefaultConfig": true,
    "name": "Valuation Certificate (Desktop)",
    "slug": "valuation-certificate-desktop",
    "seo": "",
    "breadcrumb": [],
    "schema": null,
    "pageSections": [
        {
            "displayOrder": 1,
            "pageSection": {
                "id": 1008,
                "name": "valuationCertificateDesktopHeroBanner",
                "title": "The easiest & fastest car valuation service in UAE for you is here",
                "subTitle": "Get bank valuation certificates in the UAE with CARS24 immaculate services",
                "sectionType": "HeroBanner",
                "subSections": [
                    {
                        "id": 1009,
                        "name": "valuationCertificateDesktopHeroBannerDescription",
                        "title": "Valuation Certificate Hero Banner Description",
                        "sectionType": "Grid",
                        "sectionData": [
                            {
                                "image": {
                                    "id": 1320,
                                    "url": "https://cdn.cars24.com/qa/cms/2024/12/30/7b4c499b-fe3f-4855-89f6-b134c354ae47Group%201171280322.png",
                                    "caption": null,
                                    "alternativeText": "no_hidden_charge"
                                },
                                "title": "Starts @ AED 299 + VAT"
                            },
                            {
                                "image": {
                                    "id": 1319,
                                    "url": "https://cdn.cars24.com/qa/cms/2024/12/30/d25b49ca-0987-4959-ae86-6cbc4d513facFrame%201171280324.png",
                                    "caption": null,
                                    "alternativeText": "genuine_product"
                                },
                                "title": "Digital and convenient"
                            },
                            {
                                "image": {
                                    "id": 1327,
                                    "url": "https://cdn.cars24.com/qa/cms/2024/12/30/11e76f4e-a0ca-444b-b94e-b9878f8f451aFrame.png",
                                    "caption": null,
                                    "alternativeText": "same_day_service"
                                },
                                "title": "Valuation certificate in 30 mins "
                            }
                        ],
                        "subsectionOrder": 1,
                        "buNames": [],
                        "statsig": false
                    }
                ],
                "subsectionOrder": null,
                "backgroundImage": {
                    "caption": null,
                    "alternativeText": "hero_banner",
                    "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/10/475b5905-157b-451b-9a9c-3929aaf7b290Bank%20Valuation%20Hero%20shot%20%281%29.webp"
                },
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 2,
            "pageSection": {
                "id": 1010,
                "name": "valuationCertificateDesktopDefinitionSection",
                "title": "What is a Car Valuation Certificate?",
                "sectionType": "Content",
                "subsectionOrder": null,
                "backgroundImage": {
                    "caption": null,
                    "alternativeText": "benefits_guarantee",
                    "url": "https://static-cdn.cars24.com/qa/cms/2025/01/07/3449093e-e6a8-45e8-a2f4-c3ed8bb66d19Vector.png"
                },
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 3,
            "pageSection": {
                "id": 1011,
                "name": "valuationCertificateDesktopHowItWorksSection",
                "title": "How to get a car valuation certificate online",
                "sectionType": "Grid",
                "sectionData": [
                    {}
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 4,
            "pageSection": {
                "id": 1012,
                "name": "valuationCertificateDesktopBenefitsSection",
                "title": "Why Choose CARS24 for Car Valuation?",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": 1440,
                            "url": "https://cdn.cars24.com/qa/cms/2025/01/07/b45c6959-031e-4c3c-93fa-0be8cd90f4e1Group%20639162.png",
                            "caption": null,
                            "alternativeText": "same_day_service"
                        },
                        "title": "Online and quick",
                        "subTitle": "It takes only 30 minutes* to get a car valuation certificate online from CARS24."
                    },
                    {
                        "image": {
                            "id": 1442,
                            "url": "https://cdn.cars24.com/qa/cms/2025/01/07/72ae9599-8b5f-438f-ab87-ec205cc24339Group%20639162%20%282%29.png",
                            "caption": null,
                            "alternativeText": "certified_technicians"
                        },
                        "title": "Top used car player globally",
                        "subTitle": "CARS24 is a top player in the used car market for serving customers with commitment."
                    },
                    {
                        "image": {
                            "id": 1439,
                            "url": "https://cdn.cars24.com/qa/cms/2025/01/07/78c26f4f-c019-4c96-8542-faeb4ea38bb4Group%20639162%20%281%29.png",
                            "caption": null,
                            "alternativeText": "free_pick_up_drop"
                        },
                        "title": "10,000+ cars evaluated",
                        "subTitle": "Our team of experts has inspected and evaluated ten thousands+ cars globally."
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 5,
            "pageSection": {
                "id": 1013,
                "name": "valuationCertificateDesktopPartnerBanksSection",
                "title": "Our partnered banks",
                "subTitle": "Dedicated Advisors, Expert Care, Anywhere",
                "sectionType": "WithBoxGrid",
                "sectionData": [
                    {
                        "image": {
                            "id": 2455,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/02/17/8d44656e-a536-4065-a3c5-b9b261e0b135Abu_Dhabi_Commercial_Bank_logo.svg%201.png",
                            "caption": null,
                            "alternativeText": "abcd-bank"
                        }
                    },
                    {
                        "image": {
                            "id": 2457,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/02/17/2806818a-ea1e-48ed-be6a-582cd11f29b8image%2021991.png",
                            "caption": null,
                            "alternativeText": "emirates_nbd"
                        }
                    },
                    {
                        "image": {
                            "id": 2456,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/02/17/eff3a330-c43d-41d8-b401-1e42e68e807c781-7810767_dubai-islamic-bank-logo-png-transparent-png%201.png",
                            "caption": null,
                            "alternativeText": "dubai_islamic_bank"
                        }
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 6,
            "pageSection": {
                "id": 1015,
                "name": "valuationCertificateDesktopFaqSection",
                "title": "Frequently asked questions",
                "sectionType": "Faq",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 7,
            "pageSection": {
                "id": 1016,
                "name": "valuationCertificateDesktopSeoContentSection",
                "title": "Benefits of car valuation certificate in UAE",
                "sectionType": "SEOContentSection",
                "sectionData": [
                    {}
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        }
    ],
    "globalCities": null

};
