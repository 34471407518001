import Types from "./types";
import { CheckoutAEService, UaeApiGatewayService, cmsService } from "../../../service";
import { INITIAL_STATE as abExperimentInitialState } from "../../shared/ab-experiment/reducers";
import { getExperimentsData } from "../../../utils/helpers/get-experiments-data";
import { setGaIdExperiment } from "../../shared/ab-experiment/actions";

import { cmsPageSlugs } from "../../../constants/app-constants";

const getConfigSuccess = ({ configData, cityList }) => ({
    type: Types.FETCH_CONFIG_SUCCESS,
    configData,
    cityList
});

const updateSuperAppConfig = (data) => ({
    type: Types.UPDATE_SUPER_APP_CONFIG,
    data
});

const updateBottomNavConfig = (data) => ({
    type: Types.UPDATE_BOTTOM_NAV_CONFIG,
    data
});

const setRatingConfig = ({ratingInfo}) => ({
    type: Types.FETCH_RATINGS,
    ratingInfo
});

const getConfig = (params) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        CheckoutAEService.getConfig(params)
            .then(response => {
                const { config: { cityList = [] } } = getState();
                dispatch(getConfigSuccess({
                    configData: response.data,
                    cityList,
                    experiments: {
                        ...abExperimentInitialState,
                        ...getExperimentsData(response.data?.experiments)
                    }
                }));
                dispatch(setGaIdExperiment({...getExperimentsData(response.data?.experiments)}));
                resolve(response.data);
            })
            .catch(err => reject(err));
    });
};

const setBookingCount = (data) => ({
    type: Types.FETCH_BOOKING_COUNT,
    data
});

const getNumberOfBookings = (token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        CheckoutAEService.getBookingCount(token).then(response => {
            dispatch(setBookingCount(response.data));
            resolve(response.data);
        }).catch(error => {
            // dispatch(logoutFailure(error));
            reject(error);
        });
    });
};

// COUPON LIST
const fetchCouponListInit = () => ({
    type: Types.FETCH_COUPON_LIST_INIT
});

const fetchCouponListSuccess = (data) => ({
    type: Types.FETCH_COUPON_LIST_SUCCESS,
    data
});

const fetchCouponListFailure = () => ({
    type: Types.FETCH_COUPON_LIST_FAILURE
});

const fetchCouponList = () => (dispatch) => {
    dispatch(fetchCouponListInit());
    return new Promise((resolve, reject) => {
        CheckoutAEService.getCouponList().then(response => {
            const data = response?.data || {};
            dispatch(fetchCouponListSuccess(data));
            resolve(data);
        }).catch(err => {
            dispatch(fetchCouponListFailure());
            reject(err);
        });
    });
};

const getStatsigDynamicConfig = (configName) => (dispatch) => {
    return new Promise((resolve, reject) => {
        UaeApiGatewayService.fetchStatsigDynamicConfig(configName)
            .then(response => {
                const responseData = {...response?.data, isDefaultConfig: false };
                dispatch(updateSuperAppConfig(responseData));
                resolve(response?.data);
            }).catch(err => {
                reject(err);
            });
    });
};

const fetchBottomNavConfig = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const pageContentResponse = await cmsService.fetchPageContent(cmsPageSlugs.bottomNavigationMsite);
            const data = {...pageContentResponse?.data?.data, isDefaultConfig: false};
            dispatch(updateBottomNavConfig(data));
            resolve(data);
        } catch (err) {
            reject(err);
        }
    });
};
const updateSaleDataConfig = (data) => ({
    type: Types.UPDATE_SALE_DATA,
    data
});

const updateSeoData = (data) => ({
    type: Types.UPDATE_SEO_DATA,
    data
});

export {
    getConfig,
    getNumberOfBookings,
    fetchCouponList,
    updateSaleDataConfig,
    updateSeoData,
    getStatsigDynamicConfig,
    setRatingConfig,
    fetchBottomNavConfig
};
