export const b2cHomePageEvents =  {
    "homeSearchResultClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Home_Page",
        "eventAction": "Search Result Click"
    },
    "emirateSelect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Home_Page",
        "eventAction": "emirate_select"
    },
    "currentLocation": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Home_Page",
        "eventAction": "current_location"
    },
    "locationPermission": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Home_Page",
        "eventAction": "location_permission"
    },
    "emirateAnimation": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Home_Page",
        "eventAction": "emirate_animation"
    },
    "startPurchaseTDModal": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Home_Page",
        "eventAction": "Start Purchase"
    },
    "abSellerBackButton": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Home_Page",
        "eventAction": "ab_seller_backbutton"
    },
    "viewAllCarsHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "View All Cars",
        "eventLabel": "NA"
    },
    "appDownloadBottomDrawer": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "app_download_bottom_drawer"
    },
    "popularCategories": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "popular_categories"
    },
    "buySellMyCar": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "buy_sell_my_car_click"
    },
    "homePagePopularBrand": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "popular_brand"
    },
    "homePageBudgetFriendly": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "budget_friendly"
    },
    "homePageTopBodytype": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "top_bodytype"
    },
    "homePageViewAll": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_view_all"
    },
    "homePageCarClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_car_click"
    },
    "homePageCarSwipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_car_swipe"
    },
    "homePageWhyToBuy": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "why_to_buy"
    },
    "homePageSearch": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_search",
        "eventLabel": "NA"
    },
    "homePageFilter": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_filter_clicked",
        "eventLabel": "NA"
    },
    "homePageFaqViewAll": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "faq_view_all",
        "eventLabel": "NA"
    },
    "faqClickHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "faq_click"
    },
    "homePageResumeSessionViewAll": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "resume_session",
        "eventLabel": "view_all"
    },
    "homePageResumeSessionFilter": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "resume_session",
        "eventLabel": "filter"
    },
    "b2cCustomerReviewSwipe": {
        "event": "Customer_review_swipe",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "Customer_review_swipe",
        "eventLabel": "NA"
    },
    "b2cHomeSalesBanner": {
        "event": "b2c_home_sales_banner",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_sales_banner"
    },
    "b2cSalesPopupLanding": {
        "event": "b2c_SalesPopup_landing",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "SalesPopup_landing"
    },
    "b2cBackToTopShownHomePage": {
        "event": "b2c_Back_to_Top_Shown",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "Back_to_Top_Shown",
        "eventLabel": "NA"
    },
    "b2cBackToTopClickedHomePage": {
        "event": "b2c_Back_to_Top_Clicked",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "Back_to_Top_Clicked"
    },
    "addToWishlistHomePage": {
        "event": "add_to_wishlist",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "add_to_wishlist"
    },
    "homeServiced10000km": {
        "eventAction": "home_serviced_8000km",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "event": "custom_event",
        "eventLabel": "Why to buy - serviced"
    },
    "homeWarrantyClick": {
        "eventAction": "home_warranty_click",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "event": "custom_event",
        "eventLabel": "Why to buy - warranty"
    },
    "homeInsurnaceClick": {
        "eventAction": "home_insurnace_click",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "event": "custom_event",
        "eventLabel": "Why to buy - homeInsurance"
    },
    "b2cWTBFreeTDHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_free_td_click",
        "eventLabel": "Why to buy - free td"
    },
    "detailInsurnaceClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "detail_insurnace_click",
        "eventLabel": "Why to buy - detail_insurnace"
    },
    "b2cWTBFreeTDDetail": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "b2c_WTB_Free_TD_Detail",
        "eventLabel": "Why to buy - free td detail"
    },
    "saleSnackbarLogin": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "login"
    },
    "saleHomeBannerLogin": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "login"
    },
    "homePageSaleBanner": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_banner"
    },
    "homepageVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "homepage_visited"
    },
    "listingFilterNudge": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "nudge_showing",
        "eventLabel": "NA"
    },
    "abCarsForYou": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "ab_cars_for_you_v2"
    },
    "saleBottomLeftNudgeCloseHomePage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "sale_bottom_left_nudge_close"
    },
    "saleSnackBarClickHomePage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "sale_snackbar",
        "eventLabel": "login"
    },
    "popularCategorySaleItemClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "popular_category"
    },
    "recentlyViewedCarWishlistClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "recent_view_wishlist_click"
    },
    "recommendedCarWishlistClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "recommended_wishlist_click"
    },

    "getSellMyCar": {
        "event": "buy_sell_my_car",
        "eventCategory": "Homepage",
        "eventAction": "buy_sell_my_car_click"
    },

    "superAppHpWishlistClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "wishlist_click"
    },
    "superAppHpViewAllClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "view_all_top_widget"
    },
    "superAppHpBuyCarSelect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "buy_car_select"
    },
    "superAppHpSellCarSelect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "sell_car_select"
    },
    "superAppHpCarServicingSelect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "car_servicing_select"
    },
    "superAppHpCarLoanSelect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "car_loan_select"
    },
    "superAppHpBvcSelect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "bank_certificate_select"
    },
    "superAppHpCarCareSelect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "car_subscription_select"
    },
    "superAppHpBottomNavSelect": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "bottom_nav_select"
    },
    "bottomNavBuyCarServiceSelect": {
        "event": "custom_event",
        "eventCategory": "v1_BottomNav",
        "eventAction": "Open_BuyCar"
    },
    "bottomNavHomePageServiceSelect": {
        "event": "custom_event",
        "eventCategory": "v1_BottomNav",
        "eventAction": "Open_HomePage"
    },
    "bottomNavSellCarServiceSelect": {
        "event": "custom_event",
        "eventCategory": "v1_BottomNav",
        "eventAction": "Open_SellCar"
    },
    "bottomNavCarLoanServiceSelect": {
        "event": "custom_event",
        "eventCategory": "v1_BottomNav",
        "eventAction": "Open_CarLoan"
    },
    "bottomNavValuationCertificatreServiceSelect": {
        "event": "custom_event",
        "eventCategory": "v1_BottomNav",
        "eventAction": "Open_ValuationCertificate"
    },
    "bottomNavCarServicingServiceSelect": {
        "event": "custom_event",
        "eventCategory": "v1_BottomNav",
        "eventAction": "Open_CarServicing"
    },
    "bottomNavCarCareServiceSelect": {
        "event": "custom_event",
        "eventCategory": "v1_BottomNav",
        "eventAction": "Open_CarCare"
    },
    "bottomNavMenuServiceSelect": {
        "event": "custom_event",
        "eventCategory": "v1_BottomNav",
        "eventAction": "Open_Menu"
    }

};
