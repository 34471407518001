import { createReducer } from "../../../utils/redux/create-reducer";
import { defaultPageContent } from "./constants";
import Types from "./types";

export const INITIAL_STATE = {
    isSSR: false,
    pageContent: defaultPageContent,
    seoContent: null,
    tofServices: null,
    showAppDownloadWidget: true
};

export const fetchPageContentSuccess = (state = INITIAL_STATE, { pageContent, isSSR, seoContent, tofServices = null }) => {
    return {
        pageContent: pageContent || state.pageContent || {},
        seoContent: seoContent || state.seoContent || {},
        tofServices: tofServices || state.tofServices || {},
        isSSR
    };
};

export const fetchServiceDescriptionSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        tofServices: data || state.tofServices || {}
    };
};

export const HANDLERS = {
    [Types.FETCH_PAGE_CONTENT_SUCCESS]: fetchPageContentSuccess,
    [Types.FETCH_SERVICE_DESCRIPTION_SUCCESS]: fetchServiceDescriptionSuccess
};

export default createReducer(INITIAL_STATE, HANDLERS);
