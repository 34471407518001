export const b2cAllPageEvents = {
    "logoClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "logo_click",
        "eventLabel": "logo"
    },
    "footerClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "footer_click"
    },
    "callButtonClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Call Button"
    },
    "enterMobileNumber": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Enter Mobile Number"
    },
    "otpVerify": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Verify",
        "eventLabel": "NA"
    },
    "menuOpened": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Menu",
        "eventLabel": "NA"
    },
    "menuClosed": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Menu Close",
        "eventLabel": "NA"
    },
    "wishlistClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Wishlist"
    },
    "myBookingClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "My Booking",
        "eventLabel": "NA"
    },
    "loginClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Login/Sign up",
        "eventLabel": "NA"
    },
    "faqClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Menu - FAQ"
    },
    "b2cMobileNumber1digit": {
        "event": "b2c_mobile_number_1digit",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "mobile_number_1digit"
    },
    "b2cOtpEntered": {
        "event": "b2c_otp_entered",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "otp_entered"
    },
    "b2cNameEntered": {
        "event": "b2c_name_entered",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "name_entered"
    },
    "b2cEmailEntered": {
        "event": "b2c_email_entered",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "email_entered"
    },
    "b2cActivateCompare": {
        "event": "b2c_activate_compare",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "activate_compare"
    },
    "b2cDeactivateCompareListing": {
        "event": "b2c_deactivate_compare_listing",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "deactivate_compare_listing"
    },
    "b2cAddcarCompare": {
        "event": "b2c_Addcar_compare",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Addcar_compare"
    },
    "b2cRemovecarCompare": {
        "event": "b2c_removecar_compare",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "removecar_compare"
    },
    "b2cCompareCarCta": {
        "event": "b2c_compare_car_cta",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "compare_car_cta"
    },
    "b2cCompareCarError3cars": {
        "event": "b2c_compare_car_error3cars",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "compare_car_error3cars",
        "eventLabel": "NA"
    },
    "b2cBookNowComparepage": {
        "event": "b2c_book_now_comparepage",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "book_now_comparepage"
    },
    "b2cDifferencesComparetable": {
        "event": "b2c_Differences_comparetable",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Differences_comparetable"
    },
    "b2cSpecsFeatureComparetable": {
        "event": "b2c_specs_feature_comparetable",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "specs_feature_comparetable",
        "eventLabel": "NA"
    },
    "b2cWishlistDetailpageCompare": {
        "event": "b2c_wishlist_detailpage_compare",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "wishlist_detailpage_compare",
        "eventLabel": "NA"
    },
    "b2cDndProfileOn": {
        "event": "b2c_DND_Profile_On",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "DND_Profile_On",
        "eventLabel": "NA"
    },
    "b2cDndProfileOff": {
        "event": "b2c_DND_Profile_Off",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "DND_Profile_Off",
        "eventLabel": "NA"
    },
    "b2cWhatsappChat": {
        "event": "b2c_Whatsappchat",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Whatsappchat"
    },
    "b2cDownloadIconClicked": {
        "event": "b2c_Download_Icon_Clicked",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Download_Icon_Clicked"
    },
    "tofScMenu": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Menu"
    },
    "searchResultTracking": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "search_result_tracking"
    },
    "menuOfferClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "menu_offer"
    },
    "bankOfferClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "static_page_bank_offers"
    },
    "partnerOfferClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "static_page_partner_offers"
    },
    "bottomNavigation": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "bottom_nav"
    },
    "liteClick": {
        "event": "custom_event",
        "eventAction": "Lite_click",
        "eventCategory": "Buy_used_cars_B2C_All_Page"
    },
    "primeClick": {
        "event": "custom_event",
        "eventAction": "prime_click",
        "eventCategory": "Buy_used_cars_B2C_All_Page"
    },
    "privateSellerClick": {
        "event": "custom_event",
        "eventAction": "private_seller_click",
        "eventCategory": "Buy_used_cars_B2C_All_Page"
    },
    "privateSellerProClick": {
        "event": "custom_event",
        "eventAction": "private_seller_pro_click",
        "eventCategory": "Buy_used_cars_B2C_All_Page"
    },
    "greenClick": {
        "event": "custom_event",
        "eventAction": "green_click",
        "eventCategory": "Buy_used_cars_B2C_All_Page"
    },
    "loanPageHamburgerMenu": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "hamburger_menu",
        "eventLabel": "car_finance"
    },
    "loanEligibilityInfo": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Salary_EMI"
    },
    "similarCarNudgeClickAllPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "similar_car_nudge_click"
    },
    "userLogoutEvent": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "logout",
        "eventLabel": "menu"
    },
    "buyUsedCarsFilterNudgeShown": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_page",
        "eventAction": "filter_nudge_shown"
    },
    "buyUsedCarsSellTagShown": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_page",
        "eventAction": "sell_tag_shown"
    },
    "carAffordabilityCtaRoute": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_page",
        "eventAction": "menu"
    },
    "carAffordabilityCarLoanEntry": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_page",
        "eventAction": "loan_page_entry"
    },
    "captureUserEmirate": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_page",
        "eventAction": "user_emirate"
    },
    "captureUserIp": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_page",
        "eventAction": "ip_address"
    },
    "abNewLocation": {
        "event": "custom_event",
        "eventCategory": "B2C_Buy_Used_Car_All_Page",
        "eventAction": "ab_new_location_msite"
    },

    "couponPopup": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Allpage",
        "eventAction": "coupon_popup"
    },
    "locationPageShown": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Allpage",
        "eventAction": "location_page_shown"
    },

    "b2cMobileNumberDigit": {
        "event": "b2c_mobile_number_1digit",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "mobile_number_1digit"
    },
    "verifyOtp": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "Verify"
    },
    "otpEntered": {
        "event": "b2c_otp_entered",
        "eventCategory": "Buy_used_cars_B2C_All_Page",
        "eventAction": "otp_entered"
    }

};
