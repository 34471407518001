export default {
    logoClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "logo_click",
        eventLabel: "logo"
    },
    footerClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "footer_click"
    },
    footerCountry: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Country dropdown"
    },
    callButtonClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Call Button",
        eventLabel: "NA"
    },
    enterMobileNumber: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Enter Mobile Number"
    },
    otpVerify: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Verify",
        eventLabel: "NA"
    },
    menuOpened: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu",
        eventLabel: "NA"
    },
    menuClosed: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu Close",
        eventLabel: "NA"
    },
    wishlistClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Wishlist",
        eventLabel: "NA"
    },
    myBookingClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "My Booking",
        eventLabel: "NA"
    },
    loginClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Login/Sign up",
        eventLabel: "NA"
    },
    faqClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu - FAQ",
        eventLabel: "NA"
    },
    menuCallbackClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu - Request Call Back",
        eventLabel: "NA"
    },
    menuCityChange: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Menu City Change",
        eventLabel: "NA"
    },
    viewAllCarsHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "View All Cars"
    },
    carClickFeaturedHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "car_click_featured"
    },
    byBodyTypeHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "By Body Type"
    },
    faqClickHome: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "faq_click"
    },
    homePageCarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_car_click"
    },
    homePageCarSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "faq_click"
    },
    homePageViewAll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_view_all"
    },
    homePagefullPaymentBanner: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "fp_banner",
        eventLabel: "home"
    },
    carDetailsClickListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "car_details_click"
    },
    b2cListingPageVisited: {
        event: "b2c_listing_page_visited",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_page_visited"
    },
    filterSortListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_sort"
    },
    filterApplyActionsListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_apply_actions"
    },
    listingfullPaymentBanner: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "fp_banner",
        eventLabel: "listing"
    },
    viewAllGalleryDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Gallery_click",
        eventLabel: "view_all"
    },
    b2cDetailPageVisited: {
        event: "b2c_detail_page_visited",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "detail_page_visited"
    },
    galleryClickDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Gallery_click"
    },
    viewFullReportDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "View Full Report"
    },
    emiCalculatorDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "emi_calculator"
    },
    downPaymentDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "down_payment"
    },
    tentureDropdownDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "tenture dropdown"
    },
    paymentMode: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    cdpCouponViewDetailsClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "coupon_click_detail"
    },
    startPurchaseDetailPage: {
        event: "b2c_start_purchase",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Start Purchase"
    },
    bookNowCheckout: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "book_now"
    },
    slotSelectionPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Slot_Selection_Page"
    },
    b2cDeliveryPagePickupCardClickCheckout: {
        event: "b2c_delivery_page_pickup_card_click",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "delivery_page_pickup_card_click"
    },
    deliveryPageHomeDeliveryCardClickCheckout: {
        event: "b2c_delivery_page_pickup_card_click",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "delivery_page_home_delivery_card_click"
    },
    b2c_delivery_page_pickup_selected: {
        event: "b2c_delivery_page_pickup_selected",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "delivery_page_pickup_selected"
    },
    b2c_delivery_page_home_delivery_selected: {
        event: "b2c_delivery_page_pickup_selected",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "delivery_page_home_delivery_selected"
    },
    b2c_callback_request_flow: {
        event: "b2c_ordersummary_callback",
        eventCategory: "b2c_callback_request_flow",
        eventAction: "callback_request_cta_click",
        eventLabel: "NA"
    },
    b2c_finance_option_explore: {
        event: "b2c_finance_option_explore",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page"
    },
    b2c_finance_option_proceed_fullprice: {
        event: "b2c_finance_option_proceed_fullprice",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "proceed_fullprice"
    },
    b2c_finance_option_proceed_get_finance: {
        event: "b2c_finance_option_proceed_get_finance",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "proceed_get_finance"
    },
    listingSearchResultClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Search Result Click"
    },
    filterApply: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page"
    },
    clearAllFilter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Clear All Filter"
    },
    paginationButton: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "Pagination Button"
    },
    breadcrumbsDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "breadcrumbs"
    },
    CARS24RefurbishmentStandards: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "CARS24 Refurbishment Standards"
    },
    changeCityDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Change City"
    },
    deliveryDropdownDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Delivery dropdown"
    },
    exploreAllUsedCarDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Explore all used car"
    },
    backFromCarDetail: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Back"
    },
    b2c_personal_detail_page_open: {
        event: "b2c_personal_detail_page_open",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "personal_detail_page_open",
        eventLabel: "NA"
    },
    b2c_personal_detail_page_confirm: {
        event: "b2c_personal_detail_page_confirm",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "personal_detail_page_confirm",
        eventLabel: "NA"
    },
    b2cConfirmBookingPageCheckout: {
        event: "b2c_confirm_booking_page_checkout",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "confirm_booking_page_checkout",
        eventLabel: "NA"
    },
    b2c_confirm_booking_page_confirmation: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "confirm_booking_page_confirmation"
    },
    b2c_checkout_price_breakup: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "checkout_price_breakup"
    },
    b2cCheckoutPriceBreakupClose: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "checkout_price_breakup_close"
    },
    b2cAppBannerSms: {
        event: "b2c_app_banner_sms",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "App_Banner_SMS"
    },
    b2cAppBannerEmail: {
        event: "b2c_app_banner_email",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "App_Banner_Email"
    },
    b2c_pushedBookingInitiated: {
        event: "b2c_pushed_Booking_initiated",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "pushed_booking_initiated"
    },
    b2cPushedDeliveryMode: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "pushed_delivery_mode"
    },
    b2cTdTypeProceed: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "td_type_proceed"
    },
    tradeInNo: {
        eventAction: "trade_in",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        event: "custom_event",
        eventLabel: "trade_in_no"
    },
    tradeInYes: {
        eventAction: "trade_in",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        event: "custom_event",
        eventLabel: "trade_in_yes"
    },
    b2cPushedPaymentInitiated: {
        event: "b2c_pushed_payment_initiated",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "pushed_payment_initiated"
    },
    b2cPushedBookingConfirmation: {
        event: "b2c_pushed_booking_confirmation",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "pushed_booking_confirmation"
    },
    carClick: {
        event: "carClick"
    },
    carDetails: {
        event: "carDetails"
    },
    startPurchase: {
        event: "startPurchase"
    },
    checkout: {
        event: "checkout"
    },
    thankYou: {
        event: "Purchase success"
    },
    b2cMobileNumber1digit: {
        event: "b2c_mobile_number_1digit",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "mobile_number_1digit"
    },
    b2cOtpEntered: {
        event: "b2c_otp_entered",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "otp_entered"
    },
    b2cNameEntered: {
        event: "b2c_name_entered",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "name_entered"
    },
    b2cEmailEntered: {
        event: "b2c_email_entered",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "email_entered"
    },
    filterNoCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_no_car"
    },
    reservedcarCardClick: {
        event: "b2c_reservedcar_card_click",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "reservedcar_card_click"
    },
    reservedcarNotifyMe: {
        event: "b2c_reservedcar_notify_me",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "reservedcar_notify_me"
    },
    b2cNotifymeClick: {
        event: "b2c_notifyme_click",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "notifyme_click"
    },
    b2cAppDownloadBannerListing: {
        event: "b2c_App_Download_Banner",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "App_Download_Banner"
    },
    b2cBackCheckout: {
        event: "b2c_back_checkout",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "back_checkout"
    },
    b2cPurchaseProcessProceedCTA: {
        event: "b2c_purchase_process_proceed_CTA",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "purchase_process_proceed_CTA",
        eventLabel: "NA"
    },
    //coming-soon page
    appStoreClick: {
        event: "sell_download_app_ios",
        eventCategory: "Seller_website",
        eventAction: "sell_download_app_ios_click"
    },
    playStoreClick: {
        event: "sell_download_app_android",
        eventCategory: "Seller_website",
        eventAction: "sell_download_app_android_click"
    },
    addToWishlistHomePage: {
        event: "add_to_wishlist",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "add_to_wishlist"
    },
    addToWishlistListingPage: {
        event: "add_to_wishlist",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "add_to_wishlist"
    },
    addToWishlistDetailPage: {
        event: "add_to_wishlist",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "add_to_wishlist"
    },
    removeFromWishlist: {
        event: "remove_from_wishlist",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "remove_from_wishlist"
    },
    retryPaymentCheckout: {
        event: "retry_payment_checkout",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "retry_payment",
        eventLabel: "NA"
    },
    // GA4 Events
    ga4Login: {
        event: "login"
    },
    ga4Logout: {
        event: "logout"
    },
    ga4Search: {
        event: "search"
    },
    ga4ViewItemList: {
        event: "view_item_list"
    },
    ga4SelectContent: {
        event: "select_content"
    },
    ga4ViewItem: {
        event: "view_item"
    },
    ga4AddToCart: {
        event: "add_to_cart"
    },
    ga4BeginCheckout: {
        event: "begin_checkout"
    },
    ga4EcommercePurchase: {
        event: "ecommerce_purchase"
    },
    clearEccomerce: {
        ecommerce: null
    },
    desktopShareLink: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "desktop_share_link"
    },
    emirateSelect: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "emirate_select"
    },
    currentLocation: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "current_location"
    },
    locationPermission: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "location_permission"
    },
    locationPageShown: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Allpage",
        eventAction: "location_page_shown"
    },
    buyUsedCarsLoginPageBI: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "loginpage_bi"
    },
    buyUsedCarsMobileNumber1Digit: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "mobile_number_1digit"
    },
    buyUsedCarsEnterMobileNumber: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Enter Mobile Number"
    },
    buyUsedCarsOTPEntered: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "otp_entered"
    },
    buyUsedCarsResendOTP: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "resend_otp"
    },
    buyUsedCarsVerify: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "verify"
    },
    buyUsedCarsBookingInitiated: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "booking_initiated"
    },
    checkoutLand: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "checkout_land"
    },
    buyUsedCarsMRLExplored: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "MRL_explored"
    },
    buyUsedCarsHomeTab: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Home_tab"
    },
    bctypeSelected: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "bctype_selected"
    },
    buyUsedCarsCouponApply: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "coupon_apply"
    },
    buyUsedCarsPaymentFailPop: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "paymentfail_pop"
    },
    buyUsedCarsPaymentFailRetry: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "paymentfail_retry"
    },
    buyUsedCarsPaymentInitiated: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "payment_inititated"
    },
    locationSkip: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Home_Page",
        eventAction: "location_skip"
    },
    carsImpression: {
        event: "carsImpression"
    },
    searchBarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "search_bar_click"
    },
    c24LogoClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "c24 logo click"
    },
    locationClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "location_click"
    },
    wishlistOpen: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "wishlist_open"
    },
    loginClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login_click"
    },
    listingMenu: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_menu"
    },
    myBookings: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "my_bookings"
    },
    faqListing: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "faq_listing"
    },
    callListing: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "call_listing"
    },
    primeClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "prime_click"
    },
    privateSellerClick: {
        event: "custom_event",
        eventAction: "private_seller_click",
        eventCategory: "Buy_used_cars_B2C_All_Page"
    },
    privateSellerProClick: {
        event: "custom_event",
        eventAction: "private_seller_pro_click",
        eventCategory: "Buy_used_cars_B2C_All_Page"
    },
    liteClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "lite_click"
    },
    greenClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "green_click"
    },
    searchSuggestion: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "search_suggestion"
    },
    viewSimilarCarCardCta: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "view_similar_car_card_cta"
    },
    appDownload: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "app_download"
    },
    clearAllFilters: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "clear_all_filters"
    },
    clearFiltertag: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "clear_filtertag"
    },
    buyUserCarSeoClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "buy_user_car_seo_click"
    },
    sellAnyCarSeoClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "sell_any_car_seo_click"
    },
    browseSeoClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "browse_seo_click"
    },
    zeroResults: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "zero_results"
    },
    filterApplyActions: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_apply_actions"
    },

    // Detail Page Revamp
    searchBarDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "search_bar_click"
    },

    locationDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "location_click"
    },

    c24LogoDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "c24 logo click"
    },

    wishlistDetailOpen: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "wishlist_open"
    },

    loginDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "login_click"
    },

    menuDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "menu"
    },

    buyUserCarSEODetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "buy_user_car_seo_click"
    },

    sellAnyCarSEODetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "sell_any_car_seo_click"
    },

    browseSEODetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "browse_seo_click"
    },

    breadcrumbsDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "breadcrumbs"
    },

    galleryDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Gallery_click"
    },

    gallerySwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Gallery_swipe"
    },

    galleryTabPDPDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "gallery_tab_pdp"
    },

    inGalleryTabDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "in_gallery_tab"
    },

    certifiedDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "certified_click"
    },

    tdLinerDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "td_liner"
    },

    fixedPriceDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "fixed_price_click"
    },

    customizeEMIDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "customize_emi"
    },

    emiCalculatorDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "emi_calculator"
    },

    addToWishlistDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "add_to_wishlist"
    },
    shareDetailClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "share"
    },

    couponClickDetail: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "coupon_click_detail"
    },

    // New Checkout
    checkoutLocationSelected: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "location_selected",
        eventLabel: "NA"
    },
    checkoutSelectDate: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "Select_Date"
    },
    checkoutSelectSlot: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "Select_Slot"
    },
    checkoutEnterAddress: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "enter_address",
        eventLabel: "NA"
    },
    checkoutTdsBooked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "TDS_Booked"
    },
    checkoutTdVisited: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "test_drive_visited"
    },
    checkoutHomeEmptySlot: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "home_plus_empty_slot"
    },
    checkoutHubEmptySlot: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "hub_plus_empty_slot"
    },
    checkoutTdReschedule: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "TD_RESCHEDULE"
    },
    MeetlinkOpen: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "MeetlinkOpen"
    },
    availableEmptySlot: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "available_empty_slot"
    },
    addMoreCarsPlusbutton: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Order_Summary",
        eventAction: "addmorecars_plusbutton"
    },

    // New Home page
    newHeaderSearchbarClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`,
        eventAction: "search_bar_click"
    }),
    newHeaderCars24LogoClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`,
        eventAction: "c24 logo click"
    }),
    newHeaderLocationClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`,
        eventAction: "location_click"
    }),
    newHeaderWishlistClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`,
        eventAction: "wishlist_open"
    }),
    newHeaderLoginClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`,
        eventAction: "login_click"
    }),
    newHeaderMenuClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`,
        eventAction: "menu"
    }),
    newHeaderMenuItemsClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`
    }),
    newHeaderSeoItemsClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`
    }),
    newHeaderSearchSuggestionsClick: (page) => ({
        event: "custom_event",
        eventCategory: `Buy_used_cars_B2C_${page}`,
        eventAction: `search_suggestion`
    }),
    newHomeFilterTabClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "home_filter_tab_click"
    },
    newHomePopularBrandClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "popular_brand"
    },
    newHomeBudgetFriendlyClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "budget_friendly"
    },
    newHomeBodyTypeClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "top_bodytype"
    },
    newHomePopularCategoriesClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "popular_categories"
    },
    testimonialClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "testimonial"
    },
    faqViewAllClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "faq_view_all"
    },
    vinCopy: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "VIN_copy"
    },
    featureImageSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Feature_image_swipe"
    },
    featureImageClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Feature_image_click"
    },
    featureIconClclick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Feature_icon_clclick"
    },
    featureViewall: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Feature_viewall"
    },
    featureImageIconClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Feature_image_icon_click"
    },
    featuresSearchBarClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Features_search_bar_clicked"
    },
    featuresSearchBarType: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Features_search_bar_type"
    },
    conditionVideoWatch: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Condition_video_watch"
    },
    imperfectionAccordionExpanded: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "imperfection_accordion_expanded"
    },
    viewFullReportClicked: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "view_full_report_clicked"
    },
    serviceImageSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Service_image_swipe"
    },
    fullServiceHistory: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "full_service_history"
    },
    emiCalculator: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "emi_calculator"
    },
    addToWishlist: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "add_to_wishlist"
    },
    viewDetailsSimilarCarsToCompare: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "view_details_similar_cars _to_compare"
    },
    seeMoreSimilarCarsToCompare: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "see_more_similar_cars _to_compare"
    },
    testimonial: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "testimonial"
    },
    similarCarClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_car_click"
    },
    similarCarSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_car_swipe"
    },
    viewSimilarCarCard: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "view_similar_car_card"
    },
    moreCarsClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "more_cars_click"
    },
    similarCarCTA: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "similar_car_cta"
    },
    notifyMeClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "notifyme_click"
    },
    notifyMeSuccess: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "notifyme_success"
    },
    startPurchaseComparision: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Start Purchase",
        eventLabel: "similar_cars_to_compare"
    },
    imperfectionClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "imperfection_click"
    },
    imperfectionClickInspection: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "imperfection_click_inspection"
    },
    detailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "ab_desktop_revamp"
    },
    carLoanDetailPage: {
        event: "custom_event",
        eventCategory: "B2C_Buy_Used_Cars_Detail_Page",
        eventAction: "loan_calculator"
    },
    // Loan Finance Page
    loanPageCalculator: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "calculator"
    },
    loanPageShowCarCTA: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "showcar_CTA"
    },
    loanPageFinanceFaq: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "finance_faq"
    },
    loanPageTestimonialScroll: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Finance_page",
        eventAction: "testimonial_scroll"
    },
    loanPageZeroDpBanner: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_page",
        eventAction: "zero_dp_banner"
    },
    negotiatePrice: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "negotiable_click",
        eventLabel: "private_seller"
    },
    //c2c checkout events
    c2cEnterPrice: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Enterprice"
    },
    c2cSubmitOffer: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Submit_Offer"
    },
    c2cEnteredLowPrice: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Entered_Lowprice",
        eventLabel: "Web"
    },
    c2cSubmitOfferPostRejection: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Submit_Offer_PostRejection"
    },
    c2cCheckoutPageVisit: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Checkout_Page",
        eventAction: "Checkout_Page_Visit"
    },
    c2cBcDonePage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_PostBC_Page",
        eventAction: "BC_DonePageVisit"
    },
    newGalleryClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "Gallery_click"
    },
    desktopDPAbExperiment: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "ab_desktop_dp"
    },
    //Sale Related GA Events
    saleBannerLoginClickListingPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_banner",
        eventLabel: "sale_login"
    },
    saleBannerLoginClickDetailPage: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_page",
        eventAction: "dp_sale_login",
        eventLabel: "detail"
    },
    saleListingBannerLogin: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "login"
    },
    dpSaleLineLogin: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_page",
        eventAction: "login"
    },
    priceBenefit: {
        event: "custom_event",
        eventAction: "understand_price",
        eventCategory: "Buy_used_cars_B2C_Detail_Page"
    },
    recentlyViewedCarWishlistClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "recent_view_wishlist_click"
    },
    recommendedCarWishlistClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "recommended_wishlist_click"
    },
    listingCarCardWishlistClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "listing_card_wishlist_click"
    },
    listingPageAppDownloadBannerClick: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "app_download_banner_click"
    },
    testimonialsShown: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "testimonials_shown"
    },
    login: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "login"
    },
    userLogoutEvent: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "logout",
        eventLabel: "menu"
    },
    biAlphaLux: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Detail_Page",
        eventAction: "BI_ALPHA_LUX"
    },
    b2cWhatsappChat: {
        event: "b2c_Whatsappchat",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Whatsappchat"
    },
    filterByBudget: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "filter_by_budget"
    },
    financeFilter: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Listing_Page",
        eventAction: "zero_dp_details",
        eventLabel: "finance_filter"
    },
    suggestedModelClick: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "suggested_model"
    },
    buySellMyCar: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_Homepage",
        eventAction: "buy_sell_my_car_click"
    },
    recommendedCars: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recommended_car_click"
    },
    recommendedCarsViewAll: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recommended_view_all"
    },
    recentlyAddedCar: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recently_added_car_click"
    },
    recentlyAddedViewAll: {
        eventAction: "search_suggestion",
        event: "custom_event",
        eventLabel: "recently_added_view_all"
    },
    homePageSearch: {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Homepage",
        "eventAction": "home_search",
        "eventLabel": "NA"
    },
    listingSearchClick: {
        "eventAction": "listing_search",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "event": "listing_search_clicked"
    },
    b2cWishlistDetailpageCompare: {
        event: "b2c_wishlist_detailpage_compare",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "wishlist_detailpage_compare",
        eventLabel: "NA"
    },
    happyVideoSwipe: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Happy_video_swipe",
        eventLabel: "v2"
    },
    happyVideoWatch: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_D",
        eventAction: "Happy_video_watch",
        eventLabel: "v2"
    }
};
