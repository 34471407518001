/* eslint-disable no-magic-numbers */
import oridinalSuffix from "./oridinal-suffix";
import { NUMBER } from "../../constants/app-constants";
import { differenceInDays, format, intervalToDuration, isPast, isValid, differenceInCalendarDays } from "date-fns";

export const DATE_FORMAT_TYPE = {
    default: "dd/MM/yyyy", // 26/04/2021
    DAY_DATE_MONTH_TIME: "eee do MMM, hh:mm a", // Fri 4th Mar, 06:57 PM
    FULL_DATE_MONTH_TIME: "eee dd MMM, hh:mm a", // Fri 4 Mar, 06:57 PM
    WEEK_DATE_MONTH: "eee, dd MMM", // Sun, 27 Oct
    DATE_MONTH_YEAR: "do LLLL yyyy",
    DATE_MONTH_TIME: "do MMMM, hh:mm a", // 12th April, 12:45 PM
    DATE_MONTH: "do MMMM", // 12th April
    FULL_DAY_MONTH: "eeee, do MMM", // Sun, 27 Oct
    HOUR_MINUTE_TIME: "hh:mm a", // 12:47 AM
    DAY_DATE_MONTH: "EEEE, do MMM", // Sunday, 27th Oct
    DAY_DATE_MONTH_YEAR: "EEEE, do MMM yyyy", // Sunday, 27th Oct 2024
    DATE_MONTH_YEAR_TIME: "dd/MM/yyyy  hh:mm:ss", // 26/04/2021 12:23:32
    DAY_MONTH_YEAR_TIME: "do MMM yyyy;  hh:mm:ss a", // 26/04/2021 12:23:32
    YEAR_MONTH_DATE: "yyyy-MM-dd", // 1996-10-27,
    DAY_MONTH: "do LLL", // 21 June
    TIME: "hh:mm",
    SHORT_TIME: "h:mm",
    TIME_AM_PM: "hh:mm a",
    SHORT_TIME_AM_PM: "h:mm a",
    DAY_OF_WEEK: "EEEE", // Monday
    FULL_DAY_MONTH_YEAR: "dd MMMM yyyy", // 02 November 2023
    DAY_MONTH_YEAR: "do MMM, yyyy", // 02nd Nov, 2023
    DATE_MONTH_YEAR_V2: "dd-MMM-yyyy",
    SHORT_DAY_OF_WEEK: "eee",
    DATE_TIME: "dd-mm-yyyy hh:mm a"

};

const DAY_LIST = [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
];

const ABBR_DAY_LIST = [
    "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
];

export const formatDate = (dateString = 0, type = "default", timeZoneOffset = 4) => {

    let dateStringParsed = new Date();
    if (dateString) {
        dateStringParsed = new Date(dateString);
    }

    const gstOffset = timeZoneOffset * 60;
    const localOffset = dateStringParsed?.getTimezoneOffset();
    const gstDate = new Date(dateStringParsed?.getTime() + (gstOffset + localOffset) * 60000);

    let formattedDate = "";
    try {
        formattedDate = format(gstDate, (DATE_FORMAT_TYPE[type] || type));
    } catch (err) {
        formattedDate = format(gstDate, "default");
    }
    return formattedDate;
};

export const getWeekDay = (date = new Date(), abbr = false) => {
    const dayList = abbr ? ABBR_DAY_LIST : DAY_LIST;
    return dayList[date.getDay()];
};

export const getParticularDay = (date = new Date()) => {
    return date.getDate();
};

const getOrdinalDate = (date = new Date()) => {
    let selector;
    const number = date.getDate();
    if (number <= 0) {
        selector = 4;
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0;
    } else {
        selector = number % 10;
    }

    return number + ["th", "st", "nd", "rd", ""][selector];
};

const MONTH_LIST = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const ABBR_MONTH_LIST = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
];

export const getMonth = (date = new Date(), abbr = false) => {
    const monthList = abbr ? ABBR_MONTH_LIST : MONTH_LIST;
    return monthList[date.getMonth()];
};

export const getSlotMonth = (dateList = []) => {
    const monthsList = new Set();
    for (const date of dateList) {
        monthsList.add(getMonth(date, true));
    }
    return Array.from(monthsList);
};

export const getMonthWithYear = (date = new Date()) => {
    return `${getMonth(date)} ${date.getFullYear()}`;
};

export const getDayWithMonth = (date = new Date(), ordinal = true) => {
    return `${ordinal ? oridinalSuffix(date.getDate()) : date.getDate()} ${getMonth(date)}`;
};

export const getTwelveHourFormat = (date = new Date()) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Check whether AM or PM
    const meridiem = hours >= NUMBER.TWELVE ? "PM" : "AM";

    // Find current hour in AM-PM Format
    hours %= NUMBER.TWELVE;

    // To display "0" as "12"
    hours = hours ? hours : NUMBER.TWELVE;
    minutes = minutes < NUMBER.TEN ? `0${minutes}` : minutes;
    return {
        string: `${hours}:${minutes} ${meridiem}`,
        hours,
        minutes,
        format: meridiem
    };
};

export const getDayMonthYear = (date = new Date()) => {
    return `${getParticularDay(date)} ${getMonth(date)} ${date.getFullYear()}`;
};

export const getDayMonthYearAbbr = (date = new Date()) => {
    return `${getParticularDay(date)} ${getMonth(date, true)} ${date.getFullYear()}`;
};

export const getDateAndDayMonthYearAbbr = (date = new Date()) => {
    return `${getWeekDay(date, true)}, ${getParticularDay(date)} ${getMonth(date, true)} ${date.getFullYear()}`;
};

export const getBookingDateAndTime = (date = new Date(), showTime = true, options = { separator: "," }) => {
    return ` ${getParticularDay(date)}-${getMonth(date, true)}-${date.getFullYear()}${showTime ? `${options.separator} ${getTwelveHourFormat(date).string}` : ``}`;
};

export const getDeliveryDate = (date = new Date()) => {
    return ` ${getParticularDay(date)}-${getMonth(date, true)}-${date.getFullYear()}`;
};

export const getCustomFormattedDate = (date = new Date(), config = { showYear: true, showWeek: true }) => {
    const { showYear, showWeek } = config;
    return isValid(date) ? `${showWeek ? getWeekDay(date, true) : ""} ${getOrdinalDate(date)} ${getMonth(date, true)} ${showYear ? date.getFullYear() : ""}` : "";
};

export const getDuration = (toDate = new Date(), fromDate = new Date()) => {
    return { ...intervalToDuration({ start: fromDate, end: toDate }), isPast: isPast(toDate) };
};

export const getTimeLableFromSlots = (slot) => {
    if (slot && slot.startTime && slot.endTime) {
        const startLabel = slot.startTime >= NUMBER.TWELVE ? "PM" : "AM";
        const endLabel = slot.endTime >= NUMBER.TWELVE ? "PM" : "AM";
        let startTime = slot.startTime;
        let endTime = slot.endTime;
        if (slot.startTime > NUMBER.TWELVE) {
            startTime = slot.startTime % NUMBER.TWELVE;
        }
        if (slot.endTime > NUMBER.TWELVE) {
            endTime = slot.endTime % NUMBER.TWELVE;
        }
        return `${startTime} ${startLabel} - ${endTime} ${endLabel}`;
    }
    return "N/A";
};

export const getMonthsListInDateList = (dateList = []) => {
    const monthsList = new Set();
    for (const date of dateList) {
        monthsList.add(getMonthWithYear(date));
    }
    return Array.from(monthsList);
};

export const getMonthListInDateList = (dateList = []) => {
    const monthsList = new Set();
    for (const date of dateList) {
        monthsList.add(getMonth(date));
    }
    return Array.from(monthsList);
};

/**
 *
 * @param {Date} date the date from which diff is needed
 * @returns {Number} 1
 */
export const getDaysPassedFromCurrentDate = (date) => {
    return differenceInDays(new Date(), new Date(date));
};

// Use differenceInCalendarDays from date-fns to count different dates as separate days:
export const getCalendarDaysPassedFromCurrentDate = (date) => {
    return differenceInCalendarDays(new Date(), new Date(date));
};
export const getTimeLableWithZeroFromSlots = (slot) => {
    if (slot && slot.startTime && slot.endTime) {
        const startLabel = slot.startTime >= NUMBER.TWELVE ? "PM" : "AM";
        const endLabel = slot.endTime >= NUMBER.TWELVE ? "PM" : "AM";
        let startTime = slot.startTime;
        let endTime = slot.endTime;
        if (slot.startTime > NUMBER.TWELVE) {
            startTime = slot.startTime % NUMBER.TWELVE;
        }
        if (slot.endTime > NUMBER.TWELVE) {
            endTime = slot.endTime % NUMBER.TWELVE;
        }
        return `${+startTime < 10 ? `0${startTime}` : startTime} ${startLabel} - ${+endTime < 10 ? `0${endTime}` : endTime} ${endLabel}`;
    }
    return "N/A";
};

export const paddZeroToTime = (str, min) => {
    if (!str) return "";
    let [digit, character] = str?.trim()?.split(" ") || [];
    digit = `${digit >= 10 ? digit : `${0}${digit}`}:${min ? min : "00"}`;
    character = character?.toLowerCase();
    return `${digit} ${character}`;
};

// 12:00 pm - 02:00 pm
export const formattedTimeSlots = (slot) => {
    const [from, to] = slot?.displaySlots?.split("-") || [];
    return `${paddZeroToTime(from, slot?.startMinute)} - ${paddZeroToTime(to, slot?.endMinute)}`;
};

