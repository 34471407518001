
export const uncategorizedEvents =  {
    "carClick": {
        "event": "carClick"
    },
    "carsImpression": {
        "event": "carsImpression"
    },
    "carDetails": {
        "event": "carDetails"
    },
    "startPurchase": {
        "event": "startPurchase"
    },
    "checkout": {
        "event": "checkout"
    },
    "thankYou": {
        "event": "Purchase success"
    },
    "b2cDownloadButtonShown": {
        "event": "custom_event",
        "eventAction": "download_app"
    },
    "epicDropSaleBannerClicked": {
        "event": "b2c_epic_drop_sale_banner",
        "eventAction": "epic_drop_sale_banner"
    },
    "ramadanBankOffersBannerClicked": {
        "event": "b2c_ramadan_bank_offers_banner",
        "eventAction": "ramadan_bank_offers_banner"
    },
    "partnerOfferBannerClicked": {
        "event": "b2c_partner_offer_banner",
        "eventAction": "partner_offer_banner"
    },
    "b2cGetFinancingOfferClick": {
        "event": "custom_event",
        "eventAction": "get_financing_offers_clicked"
    },
    "trendingSearch": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "trending_search"
    },
    "recentSearch": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "recent_search"
    },
    "popularBrand": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "popular_brand"
    },
    "recentlyAddedCar": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "recently_added_car_click"
    },
    "recentlyAddedViewAll": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "recently_added_view_all"
    },
    "recentlyViewedCar": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "recently_viewed_car_click"
    },
    "recentlyViewedViewAll": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "recently_viewed_view_all"
    },
    "recommendedCars": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "recommended_car_click"
    },
    "recommendedCarsViewAll": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "recommended_view_all"
    },
    "suggestedModelClick": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "suggested_model"
    },
    "popularCategoriesModal": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "popular_categories"
    },
    "resumeSessionModal": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "resume_session"
    },
    "budgetFriendlyModal": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "budget_friendly"
    },
    "topBodyTypeModal": {
        "eventAction": "search_suggestion",
        "event": "custom_event",
        "eventLabel": "top_body_type"
    },
    "ga4Login": {
        "event": "login"
    },
    "ga4Logout": {
        "event": "logout"
    },
    "ga4Search": {
        "event": "search"
    },
    "ga4ViewItemList": {
        "event": "view_item_list"
    },
    "ga4SelectContent": {
        "event": "select_content"
    },
    "ga4ViewItem": {
        "event": "view_item"
    },
    "ga4AddToCart": {
        "event": "add_to_cart"
    },
    "ga4BeginCheckout": {
        "event": "begin_checkout"
    },
    "ga4EcommercePurchase": {
        "event": "ecommerce_purchase"
    },
    "clearEccomerce": {
        "ecommerce": null
    }
};
