import React, { useRef } from "react";

// Styles
import styles from "./styles.css";

// Constants
import { testimonials } from "../../../constants/app-constants";
import VideoPlayer from "../../shared/video-player";
import SlickWrapper from "../../shared/slick-wrapper";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const TestimonialsDetail = () => {
    const sliderRef = useRef();

    const handleMediaEnd = (index) => {
        if (index === testimonials.length) return;
        sliderRef.current.slickNext();
    };

    const triggerGA = () => {
        trackDesktopCustomEventsAE("testimonial", {
            eventLabel: "video_watch"
        });
    };

    const onChangeIndexCallback = () => {
        trackDesktopCustomEventsAE("testimonial", {
            eventLabel: "swipe"
        });
    };

    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        nav: true,
        arrow: true,
        dots: false,
        afterChange: onChangeIndexCallback
    };
    return (
        <div styleName={"styles.happyCustomerWrap"}>
            <div className="container">
                <h2>Our happy customers</h2>
                <div styleName={"styles.flexRow"}>
                    <div styleName={"styles.dGrid"}>
                        <div styleName={"styles.item"}>
                            <h3>10,000+</h3>
                            <p>Car sold in UAE</p>
                        </div>
                        <div styleName={"styles.item"}>
                            <h3>99%</h3>
                            <p>Happy customers</p>
                        </div>
                        <div styleName={"styles.item"}>
                            <h3>4.5</h3>
                            <p>Google rating</p>
                        </div>
                    </div>
                    <div styleName={"styles.sliderWrap"}>
                        <SlickWrapper {...settings} onChangeIndexCallback={onChangeIndexCallback}>
                            {testimonials.map((testimonial, index) => (
                                    <div styleName={"styles.videoWrap"} key={`${testimonial.title}_${index}`}>
                                        <VideoPlayer
                                            autoPlay={true}
                                            url={testimonial.videoUrl}
                                            width="100%"
                                            height="100%"
                                            onEnded={handleMediaEnd.bind(null, index + 1)}
                                            controls={true}
                                            volume={0.75}
                                            light={testimonial.thumb}
                                            onStartCallback={triggerGA}
                                        />
                                        <div styleName={"styles.testimonialCap"}>
                                            <h3>{testimonial.title}</h3>
                                            {testimonial.subtitle && <p>{testimonial.subtitle}</p>}
                                        </div>
                                    </div>
                            ))}
                        </SlickWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TestimonialsDetail;
