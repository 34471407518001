import Types from "./types";

const updateIsDesktopModalView = (isDesktopModalView, sourceUrl) => ({
    type: Types.UPDATE_IS_DESKTOP_MODAL_VIEW,
    isDesktopModalView,
    sourceUrl
});

export {
    updateIsDesktopModalView
};
