/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useRef, useCallback } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Constants
import { NUMBER, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";

// Components
import InputText from "../../shared/input-text";
import CrossIconV2 from "../../shared/cross-image-v2";
import Skeleton from "./skeleton";
import IconSearch from "./images/search-bg-orange.svg";
const SearchModalSection = loadable(() => import("../search-modal-section"));

// Images
import SearchArrowIcon from "./images/search-list-arrow.svg";

// Utilities
import { updateSearchList } from "./selectors";
import Filters from "../../../utils/filters-v2";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import debounce from "../../../utils/helpers/debounce";
import checkSplChar from "../../../utils/helpers/check-spl-char";
import { getCarName } from "../../../utils/helpers/car-properties.ae";

// Tracking
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import carListingEventDataParser from "../../../tracking/parser/car-listing";
import SlickWrapper from "../../shared/slick-wrapper";
import { trackDesktopMultipleEventsAE } from "../../../tracking/desktop/tracking";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";

const MakeModalSearchInfo = ({
    toggelSearchBarModal = () => { },
    fetchAutoSuggestionsConnect,
    selectedCityName,
    selectedCityCode,
    allFilters,
    history,
    reloadCarListConnect,
    clearAllFiltersConnect,
    source,
    isLoggedIn,
    mobile,
    param,
    isNewHeader,
    setSearch,
    searchVal = "",
    selectedFilters,
    urlOptions,
    setSearchResultTrackingDataConnect,
    showSearchContent
}) => {
    const inputRef = useRef(null);
    const [isSuggestionListEmpty, setIsSuggestionListEmpty] = useState(false);
    const [searchInput, setsearchInput] = useState(searchVal);
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [isSuggestionLoading, setIsSuggestionLoading] = useState(true);
    const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });

    const fetchSearchResults = () => {
        fetchAutoSuggestionsConnect({ search: searchInput }).then((response) => {
            const { defaultSuggestion = [], suggestions = [] } = response || {};
            setSearchSuggestions(suggestions || defaultSuggestion);
            setIsSuggestionLoading(false);
        }).catch(() => {
            setSearchSuggestions([]);
            setIsSuggestionLoading(false);
        });
    };

    useEffect(() => {
        if (searchVal) {
            setsearchInput(searchVal);
        }
    }, [searchVal]);

    useEffect(() => {
        fetchSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (showSearchContent) {
            inputRef.current.focus();
        } else inputRef.current.blur();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSearchContent]);

    // eslint-disable-next-line max-params
    const eventData = (searchQuery = "", suggestionType = "default", index = 0, customDataList = []) => {
        let topTwentySuggestion = searchSuggestions.slice(0, NUMBER.TWENTY);
        topTwentySuggestion = topTwentySuggestion?.map((item) => ({
            suggestion: item?.suggestionText || ""
        }));

        if (customDataList.length > NUMBER.ZERO) {
            if (suggestionType === "recent") {
                topTwentySuggestion = customDataList.map((item) => ({
                    suggestion: item?.label || ""
                }));
            }
            if (suggestionType === "trending") {
                topTwentySuggestion = customDataList.map((item) => ({
                    suggestion: item || ""
                }));
            }
            if (suggestionType === "popularBrand") {
                topTwentySuggestion = customDataList.map((item) => ({
                    suggestion: item?.displayText || ""
                }));
            }
            if (suggestionType === "recentlyViewed" || suggestionType === "recentlyAdded" || suggestionType === "recommended") {
                topTwentySuggestion = customDataList.map((item) => ({
                    suggestion: getCarName(item)?.toUpperCase() || ""
                }));
            }
        }

        const data = carListingEventDataParser(topTwentySuggestion, {
            typedQuery: searchInput,
            searchQuery,
            searchSuggestionPos: index,
            suggestionType
        });
        trackDesktopCustomEventsAE("searchSuggestions", data.searchSuggestions);
    };

    const getOptions = (inputValue) => {
        const payload = { search: inputValue };
        if (inputValue.length > NUMBER.ZERO) {
            fetchAutoSuggestionsConnect(payload).then(response => {
                const { suggestions = [] } = response || {};
                if (suggestions.length > NUMBER.ZERO) {
                    setSearchSuggestions(suggestions);
                    setIsSuggestionListEmpty(false);
                } else {
                    setIsSuggestionListEmpty(true);
                }
            }).catch(() => {
                setSearchSuggestions([]);
            });
        }
    };

    const delayedQuery = useCallback(
        debounce((val) => {
            getOptions(val);
        }, NUMBER.THREE_HUNDRED), []
    );

    const handleInputChange = (event) => {
        if (checkSplChar(event.target.value)) {
            return false;
        }
        const searchInputValue = event.target.value.replace("=", "");
        setsearchInput(searchInputValue);
        delayedQuery(searchInputValue);
        return true;
    };

    const triggerGA4SearchEvent = (value = "") => {
        if (value?.length < NUMBER.ONE) return;
        trackDesktopCustomEventsAE("ga4Search", {
            search_term: value,
            user_id: mobile,
            user_status: isLoggedIn ? USER_LOGIN_STATUS.LOGGED_IN : USER_LOGIN_STATUS.LOGGED_OUT,
            login_type: isLoggedIn ? USER_LOGIN_MEDIUM.PHONE : USER_LOGIN_MEDIUM.NONE
        });
    };

    const onSearchClickHandler = async (option) => {
        const { value = "", filter = "", isExactSearch = false } = option || {};
        clearAllFiltersConnect();
        setSearch(value);
        const currentFilters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { relativeURL: listingUrl } = currentFilters.getListingPayload({
            filters: allFilters,
            options: {
                ...(
                    value && {
                        search: value,
                        ...(isExactSearch ? { matchType: "EXACT" } : {})
                    }
                ),
                ...(
                    filter && {
                        searchFilter: filter
                    }
                )
            }
        });
        reloadCarListConnect(true);
        const [pathname, search] = listingUrl.split("?");
        history.replace({ pathname, search, state: { source } });
        toggelSearchBarModal();
        if (value.length) {
            updateSearchList({ value });
        }
        await yieldToMainThread();
        triggerGA4SearchEvent(value);
        scrollToTop();
    };

    const handleOnKeyDown = (event) => {
        if (event.keyCode === NUMBER.THIRTEEN || event.which === NUMBER.THIRTEEN) {
            const typedValue = event.target.value;
            if (typedValue?.length) {
                setSearchResultTrackingDataConnect({ typedQuery: typedValue, searchQuery: "" });
                onSearchClickHandler({ value: typedValue });
            }
        }
    };

    const autoComplete = (e, value) => {
        setsearchInput(value);
        e.stopPropagation();
        inputRef.current.focus();
    };

    const highlightSearchResult = (searchResult) => {
        const smallSearchInput = searchInput.toLowerCase();
        const newResultText = (searchResult || "").replace(new RegExp(smallSearchInput, "gi"), match => `<span>${match}</span>`);
        return newResultText;
    };

    const clearInput = async () => {
        setsearchInput("");
        setSearch("");
        setSearchResultTrackingDataConnect({});
        if (source !== ROUTE_NAME.HOME) {
            const { relativeURL } = filters.getListingPayload({ selectedFilters, filters: allFilters, options: urlOptions });
            reloadCarListConnect(true);
            history.replace(relativeURL);
        }
        await yieldToMainThread();
        scrollToTop();
    };

    const handleRecentSearchClick = async (option) => {
        onSearchClickHandler(option);
        if (isNewHeader) {
            trackDesktopCustomEventsAE("searchSuggestion", {
                eventLabel: `recent_search`
            });
        } else trackDesktopMultipleEventsAE("newHeaderSearchSuggestionsClick", param, { eventLabel: "recent_search" });
    };

    const handleTrendingSearchClick = async (option) => {
        onSearchClickHandler(option);
        if (isNewHeader) {
            trackDesktopCustomEventsAE("searchSuggestion", {
                eventLabel: `trending_search`
            });
        } else trackDesktopMultipleEventsAE("newHeaderSearchSuggestionsClick", param, { eventLabel: "trending_search" });
    };

    const handlePopularBrandClick = async (option) => {
        onSearchClickHandler(option);
        if (isNewHeader) {
            trackDesktopCustomEventsAE("searchSuggestion", {
                eventLabel: `popular_brand`
            });
        } else trackDesktopMultipleEventsAE("newHeaderSearchSuggestionsClick", param, { eventLabel: "popular_brand" });
    };

    const handleRecentlyViewedCarsEvent = async (data) => {
        toggelSearchBarModal();
        if (data) {
            trackDesktopMultipleEventsAE("newHeaderSearchSuggestionsClick", param, { eventLabel: "recently_viewed_view_all" });
        }
    };

    const handleSuggestionClick = async (option = {}) => {
        const { suggestionText = " " } = option;
        const searchObj = { ...option, value: suggestionText };
        setSearchResultTrackingDataConnect({ typedQuery: searchInput, searchQuery: suggestionText });
        onSearchClickHandler(searchObj);
        await yieldToMainThread();
        trackDesktopCustomEventsAE("suggestedModelClick", { eventCategory: `Buy_used_cars_B2C_${source === ROUTE_NAME.LISTING ? "Listing" : "Home"}_Page` });
    };

    const handleSuggestedExactSearch = () => {
        eventData(searchInput);
        handleSuggestionClick({ suggestionText: searchInput, isExactSearch: true });
    };

    const handleClickHandle = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await yieldToMainThread();
    };

    const handleFocusCallback = () => {
        delayedQuery(searchInput);
    };

    const handleSearchClick = () => {
        if (source === ROUTE_NAME.LISTING) {
            trackDesktopCustomEventsAE("searchBarClick", {eventLabel: "listing"});
        }
        if (source === ROUTE_NAME.DETAIL_PAGE) {
            trackDesktopCustomEventsAE("searchBarDetailClick", {eventLabel: "detail"});
        }
    };

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow: false,
        vertical: true,
        autoplay: true,
        nav: false,
        autoplaySpeed: 2000,
        speed: 200
    };

    return (
        <React.Fragment>
            <div styleName={"styles.modalWrapper"}>
                <div styleName={"styles.searchWrap"} onClick={handleSearchClick}>
                    <div>
                        <InputText
                            // placeholder="Search by brand or model"
                            value={searchInput}
                            inputRef={inputRef}
                            onChange={handleInputChange}
                            onKeyPress={handleOnKeyDown}
                            onFocusCallback={handleFocusCallback}
                            isShowErrorIcon={false}
                        />
                        {!searchInput && <div styleName={"styles.searchButtonWrap"}>
                            <img src={IconSearch} alt="" height={36} width={36} />
                        </div>}
                        {(!searchInput && !showSearchContent) && <div styleName={"styles.sliderText"}>
                            <SlickWrapper {...settings}>
                                <p>Search Toyota</p>
                                <p>Search Nissan Sunny</p>
                                <p>Search Sunroof</p>
                                <p>Search Red SUV</p>
                            </SlickWrapper>
                        </div>}
                        {searchInput && <span styleName={"styles.crossButton"}><CrossIconV2 onClick={clearInput} /></span>}
                    </div>
                </div>
                {showSearchContent &&
                    <React.Fragment>
                        {!isSuggestionLoading && searchInput && isSuggestionListEmpty && (
                            <div styleName={"styles.autoCompleteSuggestion"} onClick={handleSuggestedExactSearch}>
                                Search for <span>"{searchInput}"</span>
                            </div>
                        )}
                        {isSuggestionLoading ? <Skeleton /> :
                            (searchInput ?
                                <div styleName={"styles.searchModalBody"} onClick={handleClickHandle}>
                                    <div styleName={`styles.trendingList ${isSuggestionListEmpty ? "styles.mt24" : ""}`}>
                                        <ul>
                                            {searchSuggestions?.slice(0, NUMBER.TEN)?.map((option, indexIn) => (
                                                <li key={indexIn}
                                                    onClick={() => {
                                                        eventData(option?.suggestionText, "autosuggestion", indexIn + 1);
                                                        handleSuggestionClick(option);
                                                    }}
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: highlightSearchResult(option?.suggestionText) }} />
                                                    <span styleName={"styles.searchListArrow"} onClick={(e) => autoComplete(e, option?.suggestionText)}>
                                                        <img src={SearchArrowIcon} alt="search" />
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div> :
                                <div styleName={"styles.searchModalBody"} onClick={handleClickHandle}>
                                    <SearchModalSection
                                        handlePopularBrandClick={handlePopularBrandClick}
                                        handleTrendingSearchClick={handleTrendingSearchClick}
                                        handleRecentSearchClick={handleRecentSearchClick}
                                        toggelSearchBarModal={handleRecentlyViewedCarsEvent}
                                        eventData={eventData}
                                        source={source}
                                    />
                                </div>
                            )
                        }
                    </React.Fragment>}
            </div>

        </React.Fragment>
    );
};

MakeModalSearchInfo.propTypes = {
    toggelSearchBarModal: PropTypes.func,
    fetchAutoSuggestionsConnect: PropTypes.func,
    allFilters: PropTypes.object,
    history: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    selectedCityName: PropTypes.string,
    selectedCityCode: PropTypes.string,
    clearAllFiltersConnect: PropTypes.func,
    source: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    mobile: PropTypes.string,
    setSearch: PropTypes.func,
    searchVal: PropTypes.string,
    param: PropTypes.string,
    isNewHeader: PropTypes.bool,
    label: PropTypes.string,
    selectedFilters: PropTypes.array,
    urlOptions: PropTypes.object,
    setSearchResultTrackingDataConnect: PropTypes.func,
    showSearchContent: PropTypes.bool
};

export default MakeModalSearchInfo;
