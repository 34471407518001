export const b2cDetailPageEvents =  {
    "spinGalleryND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "360_Gallery_ND"
    },
    "extGalleryND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Ext_Gallery_ND"
    },
    "intGalleryND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Int_Gallery_ND"
    },
    "impGalleryND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Imp_Gallery_ND"
    },
    "tDnD": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "TD_ND"
    },
    "fixedPrice": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "FP",
        "eventLabel": "v2"
    },
    "priceND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Price_ND"
    },
    "overviewND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_ND"
    },
    "featuresND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Features_ND"
    },
    "conditionND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Condition_ND"
    },
    "serviceND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Service_ND"
    },
    "financeND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Finance_ND"
    },
    "customersND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Customers_ND"
    },
    "similarND": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Similar_ND"
    },
    "overviewOneFifty": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_150",
        "eventLabel": "v2"
    },
    "overviewTrans": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_trans",
        "eventLabel": "v2"
    },
    "overviewData": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_data",
        "eventLabel": "v2"
    },
    "overviewOdo": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_odo",
        "eventLabel": "v2"
    },
    "overviewRTA": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_RTA",
        "eventLabel": "v2"
    },
    "overviewMRL": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_MRL",
        "eventLabel": "v2"
    },
    "featureImageSwipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Feature_image_swipe",
        "eventLabel": "v2"
    },
    "featureImageClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Feature_image_click",
        "eventLabel": "v2"
    },
    "featureIconClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Feature_icon_clclick",
        "eventLabel": "v2"
    },
    "featureViewall": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Feature_viewall",
        "eventLabel": "v2"
    },
    "conditionVideoWatch": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Condition_video_watch",
        "eventLabel": "v2"
    },
    "ServiceImageSwipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Service_image_swipe",
        "eventLabel": "v2"
    },
    "happyVideoSwipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Happy_video_swipe",
        "eventLabel": "v2"
    },
    "happyVideoWatch": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Happy_video_watch",
        "eventLabel": "v2"
    },
    "overviewEco": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_Eco",
        "eventLabel": "Green"
    },
    "overviewStructuralDamage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_Structural_Damage",
        "eventLabel": "Green"
    },
    "overviewFreeWarranty": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_Free_Warranty",
        "eventLabel": "Green"
    },
    "overviewEvGovt": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_EV_Govt",
        "eventLabel": "Green"
    },
    "overviewNoSound": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_No_Engine_Sound",
        "eventLabel": "Green"
    },
    "overviewGreenMrl": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_D",
        "eventAction": "Overview_MRL_Certified",
        "eventLabel": "Green"
    },

    "b2cDetailPageVisited": {
        "event": "b2c_detail_page_visited",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "detail_page_visited"
    },
    "viewFullReportDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "View Full Report"
    },
    "downPaymentDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "emi_calculator"
    },
    "tentureDropdownDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "emi_calculator"
    },
    "startPurchaseDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Start Purchase"
    },
    "inspectionSummaryShown": {
        "event": "b2c_inspection_summary_shown",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "inspection_summary_shown"
    },
    "viewFullReportClicked": {
        "event": "b2c_view_full_report_clicked",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "view_full_report_clicked"
    },
    "serviceHistoryShown": {
        "event": "b2c_service_history_shown",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "service_history_shown"
    },
    "freeBenefitsClick": {
        "event": "b2c_free_benefits_breakup",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Free Benefits"
    },
    "breadcrumbsDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "breadcrumbs"
    },
    "CARS24RefurbishmentStandards": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "CARS24 Refurbishment Standards"
    },
    "deliveryDropdownDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Delivery dropdown"
    },
    "exploreAllUsedCarDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Explore all used car"
    },
    "b2cFeaturesSpecsMore": {
        "event": "b2c_features_specs_more",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Features_Specs_More"
    },
    "b2cFeaturesSpecsLess": {
        "event": "b2c_features_specs_less",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Features_Specs_Less"
    },
    "b2cInsuranceRegClickDetailPage": {
        "event": "b2c_insurance_reg_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Insurance_Reg_click"
    },
    "b2c7DayReturnClickDetailPage": {
        "event": "b2c_7Day_return_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "7Day_return_click"
    },
    "financeBenefitClick": {
        "event": "finance_benefit_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Finance_benefit_click"
    },
    "b2cWarrantyClickDetailPage": {
        "event": "b2c_warranty_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Warranty_click"
    },
    "customizeEmi": {
        "event": "customize_emi",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "customize_emi"
    },
    "QualityCardClick": {
        "event": "b2c_quality_card_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "quality_card_click"
    },
    "DeliverySlider": {
        "event": "b2c_Delivery_Slider",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Delivery_Slider"
    },
    "RtaQualityCardClick": {
        "event": "b2c_RTA_test_assurance",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "RTA_test_assurance"
    },
    "ServiceQualityCardClick": {
        "event": "b2c_serviced_cars_card",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "serviced_cars_card"
    },
    "reservedcarNotifyMe": {
        "event": "b2c_reservedcar_notify_me",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "reservedcar_notify_me"
    },
    "b2cNotifymeClick": {
        "event": "b2c_notifyme_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "notifyme_click"
    },
    "b2cReportDownloadLinkRequest": {
        "event": "b2c_report_download_link_request",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "report_download_link_request"
    },
    "b2cReportDownloadLinkSent": {
        "event": "b2c_report_download_link_sent",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "report_download_link_sent"
    },
    "b2cBackToTopShownDetailPage": {
        "event": "b2c_Back_to_Top_Shown",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Back_to_Top_Shown"
    },
    "b2cBackToTopClickedDetailPage": {
        "event": "b2c_Back_to_Top_Clicked",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Back_to_Top_Clicked"
    },
    "b2cSeeMoreFeaturesCliked": {
        "event": "b2c_see_more_features_cliked",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "See_More_features_clicked"
    },
    "b2cFeaturesSearchBarClicked": {
        "event": "b2c_features_search_bar_clicked",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Features_search_bar_clicked"
    },
    "b2cViewAllFeaturesCliked": {
        "event": "b2c_view_all_features_cliked",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "View_all_features_clicked"
    },
    "addToWishlistDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "add_to_wishlist"
    },
    "onClickImperfections": {
        "event": "imperfections_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "imperfections_click"
    },
    "similarCarToCompareClick": {
        "event": "similar_car_to_compare_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "similar_car_to_compare_click",
        "eventLabel": "NA"
    },
    "moreCarsClick": {
        "event": "more_cars_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "more_cars_click"
    },
    "b2cFeaturesImageSwiped": {
        "eventAction": "b2c_features_image_swiped",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "event": "Features_image_swiped"
    },
    "b2cFeaturesImageClicked": {
        "eventAction": "b2c_features_image_clicked",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "event": "Features_image_clicked"
    },
    "serviceHistoryLearnMoreClicked": {
        "event": "b2c_service_history_learn_more_clicked",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "service_history_learn_more_clicked"
    },
    "serviceHistoryDetailsClosed": {
        "event": "b2c_service_history_details_closed",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "service_history_details_closed"
    },
    "ramadanDetailPageCtaClicked": {
        "event": "b2c_ramadan_detail_page_cta",
        "eventAction": "ramadan_detail_page_cta",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventLabel": "ramadan_detail_page_cta"
    },
    "imperfectionAccordionExpanded": {
        "event": "b2c_imperfection_accordion_expanded",
        "eventAction": "imperfection_accordion_expanded",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page"
    },
    "imperfectionAccordionCollapsed": {
        "event": "b2c_imperfection_accordion_collapsed",
        "eventAction": "imperfection_accordion_collapsed",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page"
    },
    "imperfectionImageSwiped": {
        "event": "b2c_imperfection_image_swiped",
        "eventAction": "imperfection_image_swiped",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page"
    },
    "b2cWTBFreeTDDetail": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "detail_free_td_click",
        "eventLabel": "Why to buy - free td"
    },
    "b2cFinanceBannerClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "finance_banner_in_EMI_cal_clicked"
    },
    "couponClickDetail": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "coupon_click_detail"
    },
    "loginStartPurchase": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "login"
    },
    "loginFinancing": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "login"
    },
    "couponOffer": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "coupon_offer"
    },
    "loginForCoupon": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "login"
    },
    "emiPopup": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "zero_dp_details",
        "eventLabel": "emi_popup"
    },
    "similarCarClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "similar_car_click"
    },
    "similarCarSwipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "similar_car_swipe"
    },
    "accident": {
        "event": "custom_event",
        "eventAction": "accident",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page"
    },
    "certifiedClick": {
        "event": "custom_event",
        "eventAction": "certified_click",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page"
    },
    "detailFixedPricePressed": {
        "event": "custom_event",
        "eventAction": "detail_fixed_price_pressed",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page"
    },
    "lite": {
        "event": "custom_event",
        "eventAction": "Lite",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventLabel": "Warranty shown"
    },
    "checkEligibilityClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Check_eligibility_click"
    },
    "salaryEntered": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Salary_entered"
    },
    "currentEMIEntered": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "CurrentEMI_entered"
    },
    "proceedClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Proceed_Click"
    },
    "eligibilityResult": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Eligibility_result"
    },
    "bookEligibilityResult": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Book_Eligibility_result"
    },
    "exploreCarEligibilityResult": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "ExploreCar_Eligibility_result"
    },
    "noExploreCTAEligibilityResult": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "NoExploreCTA_Eligibility_result"
    },
    "priceBenefit": {
        "event": "custom_event",
        "eventAction": "understand_price",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page"
    },
    "seeAllCars24Cars": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "similar_see_all"
    },
    "negotiatePrice": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "negotiable_click",
        "eventLabel": "private_seller"
    },
    "warrantyInfo": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "warranty_info"
    },
    "optionTypeInfo": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "option_type_info"
    },
    "carUspsOption": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "usp_click"
    },
    "newGalleryClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Gallery_click"
    },
    "newGallerySwipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Gallery_swipe"
    },
    "newGalleryIntGalleryTabClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "int_gallery_tab_click"
    },
    "newGalleryBookingInititated": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "pushed_booking_initiated",
        "eventLabel": "gallery"
    },
    "newGalleryShare": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "share",
        "eventLabel": "gallery"
    },
    "newGalleryAddToWishlist": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "add_to_wishlist",
        "eventLabel": "gallery"
    },
    "newGalleryBack": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "back"
    },
    "newGalleryDpGalleryTab": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "dp_gallery_tab"
    },
    "DpAllSimilarCar": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "Detailpage_allSimilarCar"
    },
    "similarCarCtaDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "similar_cars_cta_dp",
        "eventLabel": "detail"
    },
    "similarCarNudgeSwipeDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "similar_car_nudge_swipe",
        "eventLabel": "detail"
    },
    "similarCarNudgeClickDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "similar_car_nudge_click"
    },
    "testimonialsShown": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "testimonials_shown"
    },

    "dpSaleLineLogin": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_page",
        "eventAction": "login"
    },
    "saleBannerLoginClickDetailPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_page",
        "eventAction": "dp_sale_login",
        "eventLabel": "detail"
    },

    "b2cFeaturesPageSearchBarClicked": {
        "event": "b2c_features_page_search_bar_clicked",
        "eventCategory": "Features_List_Page",
        "eventAction": "Features_page_search_bar_clicked"
    },
    "biAlphaLuxEvent": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Detail_Page",
        "eventAction": "BI_ALPHA_LUX"
    }

};
