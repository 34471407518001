
import qs from "querystring";
export default (api) => {
    const whoAmI = (token) => {
        return api.get(`/user/me`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }

        });
    };

    const getTokenInfo = (product = "consumer-app") => {
        return api.get(`/oauth/${product}/token`);
    };

    const getRefreshTokenInfo = (product = "consumer-app") => {
        return api.get(`/oauth/${product}/token/refresh`);
    };

    const sendOTP = (mobile, product = "consumer-app", query = "") => {
        return api.get(`/oauth/${product}/otp/${mobile}${query ? `?${query}` : ""}`, null, {
            // headers: {
            //     phone_number: mobile,
            //     X_COUNTRY: xCountryCode
            // }
        }
        );
    };

    const verifyOTP = (params, product = "consumer-app") => {
        return api.post(`/oauth/${product}/token`, qs.stringify(params), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
    };

    const updateProfile = (firstName, email, token) => {
        return api.put("/user/me", qs.stringify({ firstName, email }), {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
    };

    const login = (params) => {
        return api.get(`/pp/auth/login`, { params });
    };

    const updateKycDocument = (token, documentType, attachments) => {
        return api.post("/pp/user/kyc", {
            documentType,
            attachments: {
                BACK: attachments.back,
                FRONT: attachments.front
            }
        }, {
            headers: {
                Authorization: `${token}`
            }
        }
        );
    };
    const updateDocument = (token, params) => {
        return api.put("/pp/document", params, {
            headers: {
                Authorization: `${token}`,
                "content-type": "multipart/form-data;"
            }
        });
    };

    const getUploadedDocument = (token, documentType) => {
        return api.get("/pp/document", documentType, { headers: { Authorization: `${token}` } });
    };

    const getDocument = (token, documentType) => {
        return api.get(`/pp/user/kyc`, {
            params: { documentType }, headers: {
                Authorization: `${token}`
            }
        });
    };

    const logout = (params, product = "consumer-app") => {
        return api.get(`/oauth/${product}/logout`);
    };

    return {
        whoAmI,
        getTokenInfo,
        sendOTP,
        verifyOTP,
        updateProfile,
        login,
        updateKycDocument,
        updateDocument,
        getUploadedDocument,
        getDocument,
        logout,
        getRefreshTokenInfo
    };
};
