
import React from "react";
import PropTypes from "prop-types";

// styles
import styles from "./styles.css";

// images

// components
import ConvenienceServicesIncludedSection from "./convenience-services-included-section";

const ConvenienceFeeBreakupPopup = ({
    convenienceFeeConfig,
    customHeading = "",
    customPriceRange = ""
}) => {

    const { headerText, priceRange, servicesCovered } = convenienceFeeConfig || {};

    return (
        <div styleName={"styles.contentWrap"} >
            <div  styleName={"styles.contentHeadingCtn"} >
                <p  styleName={"styles.contentHeading"} >{customHeading || headerText} <span>{customPriceRange || priceRange}</span></p>
            </div>
            <div styleName={"styles.servicesConveredCtn"}  >
                <p styleName={"styles.servicesConveredHeading"}  >This one-time fee covers :</p>
            </div>
            <ConvenienceServicesIncludedSection servicesCovered={servicesCovered} />
        </div>
    );
};

ConvenienceFeeBreakupPopup.propTypes = {
    popupName: PropTypes.string,
    convenienceFeeConfig: PropTypes.object,
    onClose: PropTypes.func,
    customHeading: PropTypes.string,
    customPriceRange: PropTypes.string
};

export default ConvenienceFeeBreakupPopup;
