import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import RightArrow from "./images/right-arrow.png";

// Utils
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import useLink from "../../../hooks/use-link";

const LandingHeroBanner = ({ sectionInfo = {} }) => {

    const {
        backgroundImage: {
            url: bannerUrl,
            alternativeText
        } = {},
        subSections = []
    } = sectionInfo || {};

    const {
        sectionData = [],
        title: sectionTitle
    } = subSections[0] || {};

    const { customPush } = useCustomHistoryOperations();

    const onServiceInletClick = async ({primaryCtaUrl, primaryCtaType}) => {
        if (primaryCtaType !== "REDIRECT") {
            await customPush(primaryCtaUrl);
        }
    };

    const { getLinkProps } = useLink();

    return (
        <Fragment>
            <Helmet>
                <link fetchPriority="high" rel="preload" href={bannerUrl} as={"image"} type="image/webp" />
            </Helmet>
            <div styleName={"styles.landingHeroBannerWrapper"}>
                <div styleName={"styles.bannerImg"}>
                    <img src={bannerUrl} alt={alternativeText} />
                </div>
                {(sectionData || []).length && <div styleName={"styles.serviceInletsWrapper"}>
                    {sectionTitle && <div styleName={"styles.mainTitle"}>
                        <span styleName={"styles.leftLine"} />
                        {sectionTitle}
                        <span styleName={"styles.rightLine"} />
                    </div>}
                    <div styleName={"styles.serviceInlets"}>
                        {(sectionData || []).map(subSection => {
                            const {
                                title,
                                subTitle,
                                primaryCtaUrl,
                                primaryCtaType,
                                image: {
                                    url: serviceInletUrl,
                                    alternativeText: serviceInletAlternativeText
                                }
                            } = subSection || {};

                            return (<a {...getLinkProps(`${primaryCtaUrl}`, { onClickHandler: onServiceInletClick.bind(null, {primaryCtaUrl, primaryCtaType}), preventDefault: primaryCtaType !== "REDIRECT" })} styleName={"styles.serviceInlet"} key={`serviceInlet_${title}`} >
                                <img styleName={"styles.serviceImage"} src={serviceInletUrl} alt={serviceInletAlternativeText} />
                                <div styleName={"styles.title"} >
                                    {title && <div>
                                        {title}
                                    </div>}
                                    <img src={RightArrow} width={16} height={16} />
                                </div>
                                {subTitle && <div styleName={"styles.subTitle"} >{subTitle} </div>}
                            </a>);
                        })}
                    </div>
                </div>}
            </div>
        </Fragment>
    );
};

LandingHeroBanner.propTypes = {
    sectionInfo: PropTypes.object
};

export default LandingHeroBanner;
