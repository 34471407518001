/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import InventoryCarCard from "../inventory-car-card";
import Skeleton from "../inventory-car-card/skeleton";
import { AE_LISTING_API_COUNT, COOKIE_CONSTANTS, DEFAULT_AE_CITY_NAME, DEFAULT_PAGE_SIZE, LISTING_TYPE, NUMBER, SORT_DATA } from "../../../constants/app-constants";
import parseLocationSearchParams from "../../../utils/helpers/parse-location-search-params";
import Filters from "../../../utils/filters-v2";
import qs from "querystring";
import styles from "./styles.css";
import { useInView } from "react-intersection-observer";
import ScrollButton from "../../shared/scroll-button";
import NoCarFound from "../no-car-found";
import { SOURCE } from "../inventory-car-card/constant";
import withWishlist from "../with-wishlist";
import { withLogin } from "../login-revamp";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import TrackableCarCardItem from "../trackable-car-card-item";
import carListingEventDataParser, { parseFilterToDimension } from "../../../tracking/parser/car-listing";
import { carListingEventDataParserGA4, parseFilterToDimensionGA4 } from "../../../tracking/parser/car-listing-ga4";
import { BANNER_ID, BANNER_LIST, EXACT_SEARCH_SORT, SEARCH_SORT, SHOW_AD_INDEXES } from "./constants";
import { DEFAULT_SORT } from "./constants";
import BannerSection from "./banner-section";
import NoApiResult from "../no-api-result";
import loadable from "@loadable/component";
import updateRecentlyViewedCookie from "../../../utils/helpers/update-recently-viewed-cookie";
import parseCookie from "../../../utils/helpers/parse-cookie";
import { defaultFilterPayload, defaultParams } from "../car-listing/constants";
import { useHistory, useLocation } from "react-router-dom";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import { insertBannerIntoList } from "../../../utils/helpers/insert-banner-into-list";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import deleteCookie from "../../../utils/helpers/delete-cookie";
const RecommendedCarListV2 = loadable(() => import("../car-list-recommended-v2"), { ssr: true });

const CarList = ({
    content,
    totalCars,
    page,
    isLoading,
    fetchCarListConnect,
    config,
    allFilters,
    isSSR,
    updateSSRStatusConnect,
    selectedCityCode = "",
    selectedCityName = DEFAULT_AE_CITY_NAME,
    urlOptions,
    selectedFilters = [],
    appliedFilters,
    selectedSort,
    mobile,
    shouldReloadList,
    reloadCarListConnect,
    resetCarListConnect,
    isLoggedIn,
    secureToken,
    fetchWishlistedCarsListConnect,
    impressionSource,
    fetchSimilarCarListOnSearchConnect,
    route,
    locales,
    updateSelectedSortConnect,
    fetchRecentlyViewedCarsConnect,
    setRecentlyViewedTotal,
    recentlyViewedCars = [],
    isRecentlyViewedSSR,
    similarCarPage,
    activeSaleConfig,
    updateFilterOptionDataConnect
}) => {
    const history = useHistory();
    const location = useLocation();

    const queryParams = parseLocationSearchParams(location.search);
    const [showErrorScreen, setShowErrorScreen] = useState(false);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [isLoadingSimilarCars, setIsLoadingSimilarCars] = useState(false);
    const [similarCarsOnSearch, setSimilarCarsOnSearch] = useState([]);
    const [similarTotalCars, setSimilarTotalCars] = useState(AE_LISTING_API_COUNT);
    const [hasMoreSimilarItems, setHasMoreSimilarItems] = useState(true);

    const [showViewLatestCars, setShowViewLatestCars] = useState(
        parseInt(queryParams.page) > NUMBER.ONE
    );
    const [viewLatestRef, inView, entry] = useInView({
        threshold: 0
    });
    const [isLoadingSimilarCarsForSeo, setIsLoadingSimilarCarsForSeo] = useState(false);
    const [similarCarsForSeo, setSimilarCarsForSeo] = useState([]);
    const [isRecentlyViewedLoading, setIsRecentlyViewedLoading] = useState(false);
    const isRecentlyViewed = queryParams?.isRecentlyViewed;
    const wishlistSimilarAppID = queryParams?.wsAppId || "";

    const { active: isSaleActive = false, saleDetails = {}, loginMandatory = false} = activeSaleConfig || {};
    const { listingPageBannerInfo = {}} = saleDetails;
    //const {listingBannerPosition = NUMBER.FIVE} = listingPageBannerInfo || {};

    const pathname = location.pathname;
    let listingPath = pathname.slice(1);

    if (listingPath.slice(-1) === "/") {
        listingPath = listingPath.substring(0, listingPath.length - 1);
    }

    const queryString = qs.stringify(queryParams);

    const searchQuery = queryParams.search;
    const searchFilterKey = queryParams?.searchFilter || "";

    const isSeoPath = !Object.keys(queryParams)?.length;
    const isNonSeoPathWithFilter = (!isSeoPath && (searchQuery || appliedFilters.length > NUMBER.ZERO));

    const totalPages = Math.ceil(totalCars / NUMBER.TWENTY_FIVE);
    const isSortSelected = urlOptions && urlOptions.sort && urlOptions.sort !== DEFAULT_SORT;

    const filterDimensions = parseFilterToDimension(allFilters, appliedFilters);
    const filterDimensionsGA4 = parseFilterToDimensionGA4(allFilters, appliedFilters, selectedSort);
    const recentlyViewedCarsData = JSON.parse(parseCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS) || "[]");
    const recentlyViewedCarsLength = recentlyViewedCarsData?.length || NUMBER.ZERO;

    const sortType = () => {
        if (urlOptions.sort) {
            return urlOptions.sort;
        } else if ((searchQuery) || appliedFilters.length > NUMBER.ZERO) {
            return EXACT_SEARCH_SORT;
        }
        return SEARCH_SORT;
    };

    const fetchSimilarCarsForSeo = (similarCarListPage = NUMBER.ZERO) => {

        setHasMoreSimilarItems(false);
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { payload } = filters.getListingPayload({
            selectedFilters,
            filters: allFilters,
            options: {
                ...({
                    ...urlOptions,
                    search: searchQuery,
                    matchType: "SIMILAR",
                    sort: sortType()
                })
            }
        });
        if (similarCarsOnSearch?.length === 0 || similarCarsOnSearch?.length !== similarTotalCars) {

            setIsLoadingSimilarCars(true);
            setIsLoadingSimilarCarsForSeo(true);
            fetchSimilarCarListOnSearchConnect({
                size: AE_LISTING_API_COUNT,
                spath: listingPath,
                variant: "filterV5",
                page: similarCarListPage
            },
                payload, "v3").then(response => {
                    const { results = [], total: similarCarsTotal } = response || {};
                    setSimilarTotalCars(similarCarsTotal);
                    setIsLoadingSimilarCars(false);
                    setSimilarCarsOnSearch([...similarCarsOnSearch, ...results]);
                    setHasMoreSimilarItems(true);
                });
        }
    };

    const onSaleLogin = async () => {
        await yieldToMainThread();
        const filtersObj = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const selectedFilter = {
            filterKey: "discount",
            optionKey: "discount",
            subOptionKey: "true",
            isSelected: true
        };
        updateFilterOptionDataConnect(selectedFilter);
        const { relativeURL } = filtersObj.getListingPayload({ selectedFilters: [...selectedFilter], filters: allFilters });
        await yieldToMainThread();
        history.replace(relativeURL);
        await yieldToMainThread();
        resetCarListConnect(true);
        scrollToTop();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchListing = (nextPage) => {
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { payload } = filters.getListingPayload({
            selectedFilters,
            filters: allFilters,
            options: {
                ...urlOptions,
                ...isSeoPath && { isSeoFilter: true },
                ...((queryParams?.sort && queryParams.sort !== DEFAULT_SORT) && {
                    sort: queryParams.sort
                }),
                ...(searchQuery && {
                    search: searchQuery,
                    matchType: "EXACT",
                    ...(searchFilterKey && { searchFilter: searchFilterKey }),
                    ...(!isSortSelected && {
                        sort: EXACT_SEARCH_SORT
                    })
                })
            }
        });

        const pageNumber = nextPage || 0;

        fetchCarListConnect({
            size: AE_LISTING_API_COUNT,
            spath: listingPath,
            page: pageNumber,
            variant: "filterV5"
        }, payload, "v3").then((res) => {
            if (searchQuery) {
                trackDesktopCustomEventsAE("listingSearchResultClick", {
                    eventLabel: searchQuery,
                    numberofcars: res.total
                });
            }
            if (res?.selectedSort && queryParams?.sort === SORT_DATA.RECENTLY_ADDED.key) {
                updateSelectedSortConnect({
                    key: res?.selectedSort,
                    displayText: SORT_DATA.RECENTLY_ADDED.displayText
                });
            }

            if (res?.total <= (pageNumber + 1) * AE_LISTING_API_COUNT && (isSeoPath || isNonSeoPathWithFilter)) {
                fetchSimilarCarsForSeo(similarCarPage + 1);
            } else {
                setSimilarCarsForSeo([]);
            }
            if (selectedFilters.length > NUMBER.ZERO) {
                const appFilters = selectedFilters.reduce((a, d) => {
                    if (!a.includes(d.filterKey)) { a.push(d.filterKey); }
                    return a;
                }, []);
                trackDesktopCustomEventsAE("filterApplyActions", {
                    eventLabel: appFilters.join(" | "),
                    numberofcars: res.total
                });
            }
            setHasMoreItems(true);
        }).catch(() => {
            setShowErrorScreen(true);
        });
    };

    const loadMore = () => {

        setHasMoreItems(false);
        if ((page + 1 < totalPages) && !isLoading || totalPages === null && !isLoading) {
            fetchListing(page + 1);
        } else if (isSeoPath || isNonSeoPathWithFilter) {
            fetchSimilarCarsForSeo(similarCarPage + 1);
        }
    };

    const onClickViewLatestCars = async () => {
        await yieldToMainThread();
        window.scrollTo(0, 0);
        setHasMoreItems(true);
        updateSSRStatusConnect(false);
        await yieldToMainThread();
        setShowViewLatestCars(false);
    };

    const shouldShowViewLatestCars = (index) => {
        if (index !== NUMBER.ONE ||
            content.length === NUMBER.ZERO ||
            page < NUMBER.TWO
        ) { return false; }
        return showViewLatestCars;
    };

    useEffect(() => {
        if (!isSSR && shouldReloadList) {
            resetCarListConnect();
            fetchListing();
        }
        if (shouldReloadList) {
            reloadCarListConnect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryString, isSSR, shouldReloadList, isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn && secureToken) {
            fetchWishlistedCarsListConnect(secureToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const eventData = (index, carObject) => {
        const data = carListingEventDataParser(carObject, {
            position: index,
            list: impressionSource,
            filterDimensions
        });
        return {
            name: "carsImpression",
            data: {
                ...data.carsImpression,
                eventLabel: LISTING_TYPE.CAR_LISTING_PAGE
            }
        };
    };

    const triggerGA4ViewItemListEvent = (index = 0, carObject = {}) => {
        const dataGA4 = carListingEventDataParserGA4(carObject, {
            position: index + 1,
            filterDimensions: filterDimensionsGA4,
            listingType: LISTING_TYPE.CAR_LISTING_PAGE,
            locationId: selectedCityCode
        });
        return {
            name: "ga4ViewItemList",
            data: {
                user_id: mobile,
                ...dataGA4,
                eventLabel: LISTING_TYPE.CAR_LISTING_PAGE
            }
        };
    };

    const triggerGA4SelectContentEvent = (index, car = {}) => {
        const dataGA4 = carListingEventDataParserGA4(car, {
            position: index + 1,
            filterDimensions: filterDimensionsGA4,
            listingType: LISTING_TYPE.CAR_LISTING_PAGE,
            locationId: selectedCityCode
        });
        trackDesktopCustomEventsAE("clearEccomerce");
        trackDesktopCustomEventsAE("ga4SelectContent", {
            user_id: mobile,
            ...dataGA4
        });
    };

    const onClickCallback = async (index = 0, car = {}) => {
        await yieldToMainThread();
        const data = carListingEventDataParser(car, {
            position: index + 1,
            list: impressionSource,
            filterDimensions
        });
        trackDesktopCustomEventsAE("carClick", data.carClick);
        const isCarReserved = ((car?.booked && car?.listingActive) || (!car?.booked && !car?.listingActive));
        if (isCarReserved) {
            trackDesktopCustomEventsAE("reservedcarCardClick", {
                eventLabel: car?.appointmentId || "NA"
            });
        } else {
            trackDesktopCustomEventsAE("carDetailsClickListingPage", {
                eventLabel: car?.appointmentId || "NA"
            });
        }
        triggerGA4SelectContentEvent(index, car);
    };

    const getBannerList = () => {

        const propositionCard = BANNER_LIST;
        let propositionCardUpdated = { ...propositionCard };

        if (isSaleActive) {
            const seasonalSaleBanner =  [{
            id: BANNER_ID.SEASONAL_SALE_BANNER,
            src: null,
            action: { isClickable: false },
            props: {
                seasonalSale: listingPageBannerInfo,
                isloginMandatoryForSale: loginMandatory
            }
        }];
            propositionCardUpdated = insertBannerIntoList(propositionCardUpdated, NUMBER.FIVE, seasonalSaleBanner);
        }

        return propositionCardUpdated;
    };
    const renderInBetweenBanner = (itemIndex) => {
        const bannerList = getBannerList()[itemIndex] || [];
        return (<BannerSection
            bannerList={bannerList}
            isLoggedIn={isLoggedIn}
            // reloadCarList={reloadCarListConnect}
            onSaleLogin={onSaleLogin}
        />);
    };

    // To update similarCar list to empty on searchQuery Change
    useEffect(() => {
        if (similarCarsOnSearch && similarCarsOnSearch?.length > 0) {
            setSimilarCarsOnSearch([]);
        }
        if (totalCars <= NUMBER.SIX && !isLoading && (similarCarsOnSearch || []).length === 0 && !isLoadingSimilarCars && !isLoadingSimilarCarsForSeo) {
            loadMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery, isLoading, totalCars]);

    useEffect(() => {
        if (isSeoPath && (page + 1 > totalPages)) {
            fetchSimilarCarsForSeo(similarCarPage + 1);
        } else {
            setSimilarCarsForSeo([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSeoPath]);

    const fetchRecentlyUsedCars = () => {
        setIsRecentlyViewedLoading(true);
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sf: `appointmentId:${recentlyViewedCarsData?.join(";")}` }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: recentlyViewedCarsLength
        };
        const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });
        const { payload } = filters.getListingPayload({ ...filterPayload });
        fetchRecentlyViewedCarsConnect(params, payload).then(({ total, results }) => {
            if (total < recentlyViewedCarsLength) {
                updateRecentlyViewedCookie(results);
            }
            setRecentlyViewedTotal(total);
            setIsRecentlyViewedLoading(false);
        }).catch(() => {
            setShowErrorScreen(true);
            setIsRecentlyViewedLoading(false);
            deleteCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS);
        });
    };

    useEffect(() => {
        if (isRecentlyViewed && !isRecentlyViewedSSR) {
            fetchRecentlyUsedCars();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecentlyViewed]);

    // const handleView = (view, index) => {
    //     const isPopupShown = parseCookie(COOKIE_CONSTANTS.LOCATION_POPUP_SHOWN) || false;
    //     if (view && !isPopupShown && index === NUMBER.EIGHT) {
    //         trackDesktopCustomEventsAE("locationPageShown", {
    //             eventLabel: "popup_listing"
    //         });
    //         updateLocationPopupShownConnect(true);
    //     }
    // };

    const totalCarsNeedToLoad = Math.abs(totalCars - content?.length > DEFAULT_PAGE_SIZE ? DEFAULT_PAGE_SIZE : totalCars - content?.length);
    return (
        <React.Fragment>
            {(!inView && entry && showViewLatestCars && !isLoading && page > 1) &&
                <ScrollButton text={"View Latest Cars"} onClick={onClickViewLatestCars} />
            }
            {isRecentlyViewed && <div styleName={"styles.flexRow"}>
                {
                    !isRecentlyViewedLoading && (recentlyViewedCars || []).map((item, index) => (
                        <React.Fragment key={index} ><div styleName="styles.cardWrap styles.gridThree">
                            <TrackableCarCardItem
                                event={eventData(index, item)}
                                eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                itemIndex={index}
                            >
                                <InventoryCarCard
                                    cardRef={shouldShowViewLatestCars(index) ? viewLatestRef : undefined}
                                    {...item}
                                    carData={item}
                                    config={config}
                                    source={SOURCE.LISTING}
                                    onClickCallback={() => onClickCallback(index, item)}
                                    routeName={route.name}
                                />
                            </TrackableCarCardItem>
                        </div>
                            {((index + NUMBER.ONE) !== content.length) && SHOW_AD_INDEXES.includes(index) && renderInBetweenBanner(index)}
                        </React.Fragment>
                    ))
                }
                {(isLoadingSimilarCars || isLoading) && Array(...new Array(!isNaN(totalCarsNeedToLoad) ? totalCarsNeedToLoad : NUMBER.SIX)).map((i, index) => {
                    return <div key={index} styleName="styles.cardWrap styles.gridThree"><Skeleton /></div>;
                })}
            </div>}
            <div styleName="styles.carListWrap">
                {((totalCars > NUMBER.ZERO) && (isRecentlyViewed || wishlistSimilarAppID)) && <h2 styleName={"styles.heading"}>Recommended Cars as per your search</h2>}
                <InfiniteScroll
                    pageStart={0}
                    threshold={600}
                    loadMore={loadMore}
                    hasMore={hasMoreItems}
                    initialLoad={false}
                >
                    <div styleName={"styles.flexRow styles.mbTwenty"}>
                        {
                            content.map((item, index) => (
                                <React.Fragment key={index} >
                                    <div styleName="styles.cardWrap styles.gridThree">
                                        <TrackableCarCardItem
                                            event={eventData(index, item)}
                                            eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                            itemIndex={index}
                                            // onVisible={(visible) => handleView(visible, index)}
                                        >
                                            <InventoryCarCard
                                                cardRef={shouldShowViewLatestCars(index) ? viewLatestRef : undefined}
                                                {...item}
                                                carData={item}
                                                config={config}
                                                source={SOURCE.LISTING}
                                                onClickCallback={() => onClickCallback(index, item)}
                                                routeName={route.name}
                                                crawable={index < NUMBER.FOURTY}
                                            />
                                        </TrackableCarCardItem>
                                    </div>
                                    {((index + NUMBER.ONE) !== content.length) && SHOW_AD_INDEXES.includes(index) && renderInBetweenBanner(index)}
                                </React.Fragment>
                            ))
                        }
                        {(isLoadingSimilarCars || isLoading) && Array(...new Array(totalCarsNeedToLoad || DEFAULT_PAGE_SIZE)).map((i, index) => {
                            return <div key={index} styleName="styles.cardWrap styles.gridThree"><Skeleton /></div>;
                        })}
                    </div>
                    {/* <div className={"row"}>
                        {content.length <= NUMBER.TWENTY && isSeoPath && Object.keys(INTER_LINKING_FILTERS).map(item => Number(item) < content.length ? null : renderAdditionalListComponents(item))}
                    </div> */}
                    {(totalCars === 0 && (similarCarsOnSearch || []).length === 0 && !isLoading && !isLoadingSimilarCars) && <NoCarFound />}
                </InfiniteScroll>
                {(similarCarsOnSearch?.length > NUMBER.ZERO || similarCarsForSeo?.length > NUMBER.ZERO) && (
                    <div styleName={`styles.searchSimilarCardWrap ${(totalCars === 0 && !isLoading) ? "styles.shiftTopThirty" : ""}`}>
                        <h2>{similarTotalCars} {locales.similarCarsSection.title}</h2>
                        <InfiniteScroll
                            pageStart={0}
                            hasMore={hasMoreSimilarItems}
                            loadMore={fetchSimilarCarsForSeo}
                            initialLoad={false}
                        >
                            <section styleName={"styles.flexRow"}>

                                {(similarCarsOnSearch?.length > NUMBER.ZERO ? similarCarsOnSearch : similarCarsForSeo).map((item, index) => (
                                    <React.Fragment key={`${index}_${item?.appointmentId}`}>
                                        <div styleName="styles.cardWrap styles.gridThree">
                                            <TrackableCarCardItem
                                                event={eventData(index, item)}
                                                eventDataGA4={triggerGA4ViewItemListEvent(index, item)}
                                                itemIndex={index}
                                            >
                                                <InventoryCarCard
                                                    key={`${index}_${item?.appointmentId}`}
                                                    cardRef={shouldShowViewLatestCars(index) ? viewLatestRef : undefined}
                                                    {...item}
                                                    carData={item}
                                                    config={config}
                                                    source={SOURCE.LISTING}
                                                    onClickCallback={() => onClickCallback(index, item)}
                                                    routeName={route.name}
                                                    crawable={!(isSeoPath && index > NUMBER.FOUR)}
                                                />
                                            </TrackableCarCardItem>
                                        </div>
                                    </React.Fragment>
                                ))}
                                {(isLoadingSimilarCars) && Array.from({ length: 4 }).map((i, index) => {
                                    return <div key={index} styleName="styles.cardWrap styles.gridThree"><Skeleton key={index} /></div>;
                                })}

                            </section>
                        </InfiniteScroll>
                    </div>
                )}
                {(searchQuery && !isRecentlyViewedLoading) && (
                    <RecommendedCarListV2 route={route} />
                )}
            </div>

            {showErrorScreen && (
                <NoApiResult
                    showErrorScreen={showErrorScreen}
                    setShowErrorScreen={setShowErrorScreen}
                />
            )}
        </React.Fragment>
    );
};

CarList.propTypes = {
    content: PropTypes.array,
    totalCars: PropTypes.number,
    page: PropTypes.number,
    isLoading: PropTypes.bool,
    fetchCarListConnect: PropTypes.func,
    config: PropTypes.object,
    allFilters: PropTypes.object,
    isSSR: PropTypes.bool,
    updateSSRStatusConnect: PropTypes.func,
    selectedCityCode: PropTypes.string,
    urlOptions: PropTypes.object,
    selectedFilters: PropTypes.array,
    appliedFilters: PropTypes.array,
    selectedSort: PropTypes.object,
    mobile: PropTypes.string,
    appliedSuggestions: PropTypes.array,
    shouldReloadList: PropTypes.bool,
    reloadCarListConnect: PropTypes.func,
    resetCarListConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    secureToken: PropTypes.string,
    fetchWishlistedCarsListConnect: PropTypes.func,
    impressionSource: PropTypes.string,
    fetchCarListTotalCountConnect: PropTypes.func,
    route: PropTypes.object,
    fetchSimilarCarListOnSearchConnect: PropTypes.func,
    locales: PropTypes.object,
    updateSelectedSortConnect: PropTypes.func,
    selectedCityName: PropTypes.string,
    fetchRecentlyViewedCarsConnect: PropTypes.func,
    setRecentlyViewedTotal: PropTypes.func,
    recentlyViewedCars: PropTypes.array,
    isRecentlyViewedSSR: PropTypes.bool,
    selectedCity: PropTypes.bool,
    similarCarPage: PropTypes.number,
    activeSaleConfig: PropTypes.object,
    updateFilterOptionDataConnect: PropTypes.func,
    updateLocationPopupShownConnect: PropTypes.func
};

export default withWishlist(withLogin(CarList));
