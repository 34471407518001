import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Images
import DefaultPersonIcon from "./images/person-icon.png";
import StarIcon from "./images/startIcon.svg";

// components
import LazyImage from "../../shared/lazy-image";

// constants

// tracking

const TestimonialCard = ({ name = "", rating = "", comment = "", reviewDate }) => {

    return (
        <div styleName={"styles.testimonialCard"} >
            <div>
                <div styleName={"styles.personInfoCtn"} >
                    <div styleName={"styles.personImgWrapper"}  >
                        <LazyImage src={DefaultPersonIcon} />
                    </div>
                    <div>
                        {name && <div styleName={"styles.nameCtn"} >
                            <p styleName={"styles.nameText"} >{name}</p>
                        </div>}
                        <div styleName={"styles.ratingCtn"} >
                            {Array.from({ length: rating }, (_, index) => (
                                <LazyImage src={StarIcon} key={`rating_${index}`} />
                            ))}
                        </div>
                    </div>
                </div>
                {comment && <div styleName={"styles.testimonialTextCtn"}  >
                    <p styleName={"styles.testimonialText"} >
                        {comment}
                    </p>
                </div>}
                {reviewDate && <div styleName={"styles.testimonialDateCtn"} >
                    <p styleName={"styles.testimonialDate"} >{reviewDate}</p>
                </div>}
            </div>
        </div>
    );
};

TestimonialCard.propTypes = {
    name: PropTypes.string,
    rating: PropTypes.string,
    comment: PropTypes.string,
    reviewDate: PropTypes.string
};

export default TestimonialCard;
