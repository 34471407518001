export default {
    homePageLoad: {
        event: "sell_homepage",
        eventCategory: "Homepage",
        eventAction: "sell_homepage",
        eventLabel: "NA"
    },
    sellDownloadClick: {
        event: "sell_download",
        eventCategory: "Homepage",
        eventAction: "sell_download_click",
        eventLabel: "NA"
    },
    sellAppProceedClick: {
        event: "sell_app_proceed",
        eventCategory: "Homepage",
        eventAction: "sell_app_proceed_click"
    },
    sellFAQsClick: {
        event: "sell_faqs",
        eventCategory: "Homepage",
        eventAction: "sell_faqs_click"
    },
    sellFAQsViewMore: {
        event: "sell_faqs_view_more",
        eventCategory: "Homepage",
        eventAction: "sell_faqs_view_more_click"
    },
    sellGetCarPrice: {
        event: "sell_get_car_price",
        eventCategory: "Seller_questions",
        eventAction: "sell_get_car_price_click"
    },
    sellMake: {
        event: "sell_make",
        eventCategory: "Seller_questions",
        eventAction: "sell_make_click"
    },
    sellModel: {
        event: "sell_model",
        eventCategory: "Seller_questions",
        eventAction: "sell_model_click"
    },
    sellYear: {
        event: "sell_year",
        eventCategory: "Seller_questions",
        eventAction: "sell_year_click"
    },
    b2cMobileNumber1Digit: {
        event: "b2c_mobile_number_1digit",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "mobile_number_1digit"
    },
    sellNumberProceed: {
        event: "sell_number_proceed",
        eventCategory: "Seller_questions",
        eventAction: "sell_number_proceed_click"
    },
    enterMobileNumber: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Enter Mobile Number"
    },
    verify: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Verify"
    },
    otpEntered: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "otp_entered"
    },
    odoSubmitClick: {
        event: "sell_odometer_submit",
        eventCategory: "Seller_questions",
        eventAction: "sell_odometer_submit_click"
    },
    carSpecsSubmitClick: {
        event: "sell_car_specs",
        eventCategory: "Seller_questions",
        eventAction: "sell_car_specs_click"
    },
    otherInfoSubmitClick: {
        event: "sell_other_info",
        eventCategory: "Seller_questions",
        eventAction: "sell_other_info_click"
    },
     getCarPrice: {
        event: "get_car_price",
        eventCategory: "Seller_questions",
        eventAction: "get_car_price_click"
    },
    getMake: {
        event: "make",
        eventCategory: "Seller_questions",
        eventAction: "make_click"
    },
    getModel: {
        event: "model",
        eventCategory: "Seller_questions",
        eventAction: "model_click"
    },
    getYear: {
        event: "year",
        eventCategory: "Seller_questions",
        eventAction: "year_click"
    },
    getNumberProceed: {
        event: "number_proceed",
        eventCategory: "Seller_questions",
        eventAction: "number_proceed_click"
    },
    getOdometerSubmit: {
        event: "odometer_submit",
        eventCategory: "Seller_questions",
        eventAction: "odometer_submit_click"
    },
    getCarSpecs: {
        event: "car_specs",
        eventCategory: "Seller_questions",
        eventAction: "car_specs_click"
    },
    getCancel: {
        event: "cancel",
        eventCategory: "Seller_questions",
        eventAction: "cancel_click"
    },
    getUseCurrentLocation: {
        event: "use_current_location",
        eventCategory: "Schedule_inspection",
        eventAction: "use_current_location_click"
    },
    getReschedule: {
        event: "reschedule",
        eventCategory: "Schedule_inspection",
        eventAction: "reschedule_click"
    },
    getSellMyCar: {
        event: "sell_sell_my_car",
        eventCategory: "Homepage",
        eventAction: "sell_sell_my_car_click"
    },
    getYesFromRIMismatch: {
        event: "yes",
        eventCategory: "RI_mismatch",
        eventAction: "yes_click"
    },
    getNoFromRIMismatch: {
        event: "no",
        eventCategory: "RI_mismatch",
        eventAction: "no_click"
    },
    getUploadDocsScreen: {
        event: "upload_documents_page",
        eventCategory: "sell_upload_documents",
        eventAction: "visit"
    },
    getUploadDone: {
        event: "done",
        eventCategory: "sell_done",
        eventAction: "sell_done_click"
    },
    getMoreCarBrands: {
        event: "more_car_brands",
        eventCategory: "Seller_questions",
        eventAction: "more_car_brands_click"
    },
    getPricePageOpen: {
        event: "Price_page_open",
        eventCategory: "Seller_Price_Page",
        eventAction: "Seller_price_page_visit"
    },
    getSellWatchVideo: {
        event: "sell_watch_video",
        eventCategory: "Homepage",
        eventAction: "sell_watch_video_click"
    },
    getSellWhatsappBookmark: {
        event: "sell_whatsapp_bookmark",
        eventCategory: "Seller_questions",
        eventAction: "sell_whatsapp_bookmark_click"
    },
    getSellSeeAlFAQs: {
        event: "sell_see_all_faqs",
        eventCategory: "Seller homepage",
        eventAction: "sell_see_all_faqs_click"
    },
    getSellEditQuestions: {
        event: "sell_edit_questions",
        eventCategory: "Seller_questions",
        eventAction: "sell_edit_questions_click"
    },
    getSellTimeClick: {
        event: "sell_time",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_time_click"
    },
    getSellDateClick: {
        event: "sell_date",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_date_click"
    },
    getSellBookAppointment: {
        event: "sell_book_appointment",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_book_appointment_click"
    },
    getSellWalkinAppointmentClik: {
        event: "sell_walkin_appointment",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_walkin_appointment_click"
    },
    getSellWalkinLocation: {
        event: "sell_walkin_location",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_walkin_location_click"
    },
    getSellWalkinBookAppointment: {
        event: "sell_walkin_bookanappointment",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_walkin_bookanappointment_click"
    },
    getSellEditDetails: {
        event: "sell_edit_details",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_edit_details_click"
    },
    getSellCardStatusClick: {
        event: "sell_status_card_click",
        eventCategory: "Homepage",
        eventAction: "sell_status_card_click_click"
    },
    getSellWhatsappChat: {
        event: "sell_whatsapp_chat",
        eventCategory: "Seller_questions",
        eventAction: "sell_whatsapp_chat_click"
    },
    getSellRequestCall: {
        event: "sell_request_call",
        eventCategory: "Seller_questions",
        eventAction: "sell_request_call_click"
    },
    getSellMMVYClick: {
        event: "sell_mmvy",
        eventCategory: "Homepage",
        eventAction: "sell_mmvy_click"
    },
    getSellTellUsBrandClick: {
        event: "sell_tellusbrand",
        eventCategory: "Homepage",
        eventAction: "sell_tellusbrand_click"
    },
    getSellConvenienceFee: {
        event: "sell_conveniencefee",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_conveniencefee_click"
    },
    getKnowmoreAssumptions: {
        event: "sell_knowmore_assumptions",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_knowmore_assumptions_click"
    },
    getSellCancelAppointment: {
        event: "sell_cancel_appointment",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_cancel_appointment_click"
    },
    getSellBookVideoInspection: {
        event: "sell_book_video_inspection",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_book_video_inspection_click"
    },
    getSellSeeHowVideo: {
        event: "sell_see_how_video",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_see_how_video_click"
    },
    getSellAddressPage: {
        event: "sell_address_page",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_address_page_open"
    },
    getSellDatePage: {
        event: "sell_date_page",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_date_page_open"
    },
    getSellTimeSection: {
        event: "sell_time_section",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_time_section_open"
    },
    getSellEnterHouse: {
        event: "sell_enter_house",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_enter_house_click"
    },
    getSellLandmark: {
        event: "sell_landmark",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_landmark_click"
    },
    getSellAddressProceed: {
        event: "sell_address_proceed",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_address_proceed_click"
    },
    getSellBook: {
        event: "sell_book",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_book_click"
    },
    sellHomepageLoad: {
        event: "sell_homepage",
        eventCategory: "Homepage",
        eventAction: "homepage_load"
    },
    sellSearchLocation: {
        event: "sell_search_location",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_search_location_click"
    },
    selectLocation: {
        event: "select_location",
        eventCategory: "Schedule_inspection",
        eventAction: "select_location_click"
    },
    b2cMobileNumberDigit: {
        event: "b2c_mobile_number_1digit",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "mobile_number_1digit"
    },
    verifyOtp: {
        event: "custom_event",
        eventCategory: "Buy_used_cars_B2C_All_Page",
        eventAction: "Verify"
    },
    sellGsFailPage: {
        event: "sell_gs_fail_page",
        eventCategory: "Seller_questions",
        eventAction: "sell_gs_fail_page_load"
    },
    sellNoDate: {
        event: "sell_no_date",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_no_date_page"
    },
    sellNoSlots: {
        event: "sell_no_slots",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_no_slots_page"
    },
    getSellHiviPage: {
        event: "sell_HIVI_page",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_HIVI_page_load"
    },
    getSellSelectVi: {
        event: "sell_select_VI",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_select_VI_click"
    },
    getSellSelectHi: {
        event: "sell_select_HI",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_select_HI_click"
    },
    getSellProceedInspection: {
        event: "sell_proceed_inspection",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_proceed_inspection_click"
    },
    getSellProceedInspectionFallback: {
        event: "sell_proceed_inspection_fallback",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_proceed_inspection_fallback_click"
    },
    getSellChangeLocation: {
        event: "sell_change_location",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_change_location_click"
    },
    getSellHubSelect: {
        event: "sell_hub_select",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_hub_select_click"
    },
    getSellNext: {
        event: "sell_next",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_next_click"
    },
    getSellGuideline: {
        event: "sell_guideline",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_guideline_click"
    },
    getSellDocuments: {
        event: "sell_documents",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_documents_click"
    },
    spiCardClick: {
        event: "spi_card",
        eventCategory: "Schedule_inspection",
        eventAction: "spi_card_click"
    },
    sppCardClick: {
        event: "spp_card",
        eventCategory: "Schedule_inspection",
        eventAction: "spp_card_click"
    },
    selectDiyClick: {
        event: "sell_select_DIY",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_select_DIY_click"
    },
    addPhotoLoad: {
        event: "sell_addphoto",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_addphoto_load"
    },
    addPhotoClick: {
        event: "sell_addphoto_click",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_addphoto_click"
    },
    uploadedLoad: {
        event: "sell_uploaded",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_uploaded_load"
    },
    uploadedSubmitClick: {
        event: "sell_uploaded_submit",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_uploaded_submit_click"
    },
    addMoreImageClick: {
        event: "sell_addmoreimg",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_addmoreimg_click"
    },
    startSelf: {
        event: "sell_startself",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_startself_click"
    },
    removeImageClick: {
        event: "sell_remove_image",
        eventCategory: "Schedule_inspection",
        eventAction: "sell_remove_image_click"
    },
    backButtonPopupOpen: {
        event: "sell_back_button_popup",
        eventCategory: "Seller_questions",
        eventAction: "back_button_popup_open"
    },
    backButtonPopupClose: {
        event: "sell_back_button_popup",
        eventCategory: "Seller_questions",
        eventAction: "back_button_popup_close"
    },
    backButtonContinue: {
        event: "sell_continue",
        eventCategory: "Seller_questions",
        eventAction: "sell_continue_click"
    },
    backButtonTalkToAgent: {
        event: "sell_talktoagent",
        eventCategory: "Seller_questions",
        eventAction: "sell_talktoagent_click"
    },
    backButtonBackToLast: {
        event: "sell_back_to_last",
        eventCategory: "Seller_questions",
        eventAction: "sell_back_to_last_click"
    },
    mpOfferpageLoad: {
        event: "sell_mp_pitch",
        eventCategory: "mp_pitch",
        eventAction: "mp_offer_page_load"
    },
    mpPackageSelectClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "package_select_click"
    },
    mpPackageConfirmClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "package_confirm_click"
    },
    mpInfoIconClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "info_icon_click"
    },
    mpCallButtonClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "call_button_click"
    },
    mpOrderSummaryLoad: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "order_summary_load"
    },
    mpSetCarPriceLoad: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "set_car_price_load"
    },
    mpToggleLP: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "toggle_lp"
    },
    mpToggleMAP: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "toggle_map"
    },
    mpConfirmPrice: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "confirm_price"
    },
    mpCalenderLoad: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "calendar_load"
    },
    mpCalL1Checkbox: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "calendar_l1_checkbox"
    },
    mpCalL2Checkbox: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "calendar_l2_checkbox"
    },
    mpConfirmCal: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "confirm_calendar"
    },
    mpChangeLocationClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "change_location_click"
    },
    mpLocationScreenLoad: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "location_screen_load"
    },
    mpSellSearchLocationClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "sell_search_location_click"
    },
    mpSelectLocationClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "select_location_click"
    },
    mpSellAddressPageOpen: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "sell_address_page_open"
    },
    mpSellEnterHouseClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "sell_enter_house_click"
    },
    mpSellLandmarkClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "sell_landmark_click"
    },
    mpSellAdressProceedClick: {
        event: "sell_custom_event",
        eventCategory: "mp_pitch",
        eventAction: "sell_address_proceed_click"
    },
    sellCenterLoad: {
        event: "sell_custom_event",
        eventCategory: "seller_centre",
        eventAction: "seller_centre_load"
    },
    sellCenterviewListingClick: {
        event: "sell_custom_event",
        eventCategory: "seller_centre",
        eventAction: "view_listing_click"
    },
    sellCenterOfferTabClick: {
        event: "sell_custom_event",
        eventCategory: "seller_centre",
        eventAction: "offer_tab_click"
    },
    sellCenterPriceTabClick: {
        event: "sell_custom_event",
        eventCategory: "seller_centre",
        eventAction: "price_tab_click"
    },
    sellCenterCalenderTabClick: {
        event: "sell_custom_event",
        eventCategory: "seller_centre",
        eventAction: "calendar_tab_click"
    },
    sellViewOfferCard: {
        event: "sell_custom_event",
        eventCategory: "seller_centre",
        eventAction: "view_offer_card"
    }
};

export const SELLER_DESKTOP_EVENTS = {
    homePageLoad: "homePageLoad",
    sellDownloadClick: "sellDownloadClick",
    sellAppProceedClick: "sellAppProceedClick",
    sellFAQsClick: "sellFAQsClick",
    sellFAQsViewMore: "sellFAQsViewMore",
    sellGetCarPrice: "sellGetCarPrice",
    sellMake: "sellMake",
    sellModel: "sellModel",
    sellYear: "sellYear",
    b2cMobileNumber1Digit: "b2cMobileNumber1Digit",
    sellNumberProceed: "sellNumberProceed",
    enterMobileNumber: "enterMobileNumber",
    verify: "verify",
    otpEntered: "otpEntered",
    odoSubmitClick: "odoSubmitClick",
    carSpecsSubmitClick: "carSpecsSubmitClick",
    otherInfoSubmitClick: "otherInfoSubmitClick",

    getCarPrice: "getCarPrice",
    getMake: "getMake",
    getModel: "getModel",
    getYear: "getYear",
    getNumberProceed: "getNumberProceed",
    getOdometerSubmit: "getOdometerSubmit",
    getCarSpecs: "getCarSpecs",
    getCancel: "getCancel",
    getUseCurrentLocation: "getUseCurrentLocation",
    getGoToHomepage: "getGoToHomepage",
    getReschedule: "getReschedule",
    getYesClicked: "getYesClicked",
    getSellMyCar: "getSellMyCar",
    getBuyCar: "getBuyCar",
    getExteriorPanels: "getExteriorPanels",
    getYesFromRIMismatch: "getYesFromRIMismatch",
    getNoFromRIMismatch: "getNoFromRIMismatch",
    getUploadDocsScreen: "getUploadDocsScreen",
    getUploadDone: "getUploadDone",
    getMoreCarBrands: "getMoreCarBrands",
    getPricePageOpen: "getPricePageOpen",
    getSellWatchVideo: "getSellWatchVideo",
    getSellWhatsappBookmark: "getSellWhatsappBookmark",
    getSellSeeAlFAQs: "getSellSeeAlFAQs",
    getSellEditQuestions: "getSellEditQuestions",
    getSellTimeClick: "getSellTimeClick",
    getSellDateClick: "getSellDateClick",
    getSellBookAppointment: "getSellBookAppointment",
    getSellWalkinLocation: "getSellWalkinLocation",
    getSellWalkinBookAppointment: "getSellWalkinBookAppointment",
    getSellWalkinAppointmentClik: "getSellWalkinAppointmentClik",
    getSellEditDetails: "getSellEditDetails",
    sellHomepageLoad: "sellHomepageLoad",
    getSellCardStatusClick: "getSellCardStatusClick",
    getSellWhatsappChat: "getSellWhatsappChat",
    getSellRequestCall: "getSellRequestCall",
    getSellMMVYClick: "getSellMMVYClick",
    getSellTellUsBrandClick: "getSellTellUsBrandClick",
    getSellConvenienceFee: "getSellConvenienceFee",
    getKnowmoreAssumptions: "getKnowmoreAssumptions",
    getSellCancelAppointment: "getSellCancelAppointment",
    getSellBookVideoInspection: "getSellBookVideoInspection",
    getSellSeeHowVideo: "getSellSeeHowVideo",
    getSellAddressPage: "getSellAddressPage",
    getSellDatePage: "getSellDatePage",
    getSellTimeSection: "getSellTimeSection",
    getSellEnterHouse: "getSellEnterHouse",
    getSellLandmark: "getSellLandmark",
    getSellAddressProceed: "getSellAddressProceed",
    getSellBook: "getSellBook",
    sellSearchLocation: "sellSearchLocation",
    selectLocation: "selectLocation",
    b2cMobileNumberDigit: "b2cMobileNumberDigit",
    verifyOtp: "verifyOtp",
    sellGsFailPage: "sellGsFailPage",
    sellNoDate: "sellNoDate",
    sellNoSlots: "sellNoSlots",
    getSellHiviPage: "getSellHiviPage",
    getSellSelectVi: "getSellSelectVi",
    getSellSelectHi: "getSellSelectHi",
    getSellProceedInspection: "getSellProceedInspection",
    getSellProceedInspectionFallback: "getSellProceedInspectionFallback",
    getSellChangeLocation: "getSellChangeLocation",
    getSellHubSelect: "getSellHubSelect",
    getSellNext: "getSellNext",
    getSellGuideline: "getSellGuideline",
    getSellDocuments: "getSellDocuments",
    spiCardClick: "spiCardClick",
    sppCardClick: "sppCardClick",
    selectDiyClick: "selectDiyClick",
    addPhotoLoad: "addPhotoLoad",
    addPhotoClick: "addPhotoClick",
    uploadedLoad: "uploadedLoad",
    uploadedSubmitClick: "uploadedSubmitClick",
    addMoreImageClick: "addMoreImageClick",
    startSelf: "startSelf",
    removeImageClick: "removeImageClick",
    backButtonPopupOpen: "backButtonPopupOpen",
    backButtonPopupClose: "backButtonPopupClose",
    backButtonContinue: "backButtonContinue",
    backButtonTalkToAgent: "backButtonTalkToAgent",
    backButtonBackToLast: "backButtonBackToLast",
    mpOfferpageLoad: "mpOfferpageLoad",
    mpPackageSelectClick: "mpPackageSelectClick",
    mpPackageConfirmClick: "mpPackageConfirmClick",
    mpInfoIconClick: "mpInfoIconClick",
    mpCallButtonClick: "mpCallButtonClick",
    mpOrderSummaryLoad: "mpOrderSummaryLoad",
    mpSetCarPriceLoad: "mpSetCarPriceLoad",
    mpToggleLP: "mpToggleLP",
    mpToggleMAP: "mpToggleMAP",
    mpConfirmPrice: "mpConfirmPrice",
    mpCalenderLoad: "mpCalenderLoad",
    mpCalL1Checkbox: "mpCalL1Checkbox",
    mpCalL2Checkbox: "mpCalL2Checkbox",
    mpConfirmCal: "mpConfirmCal",
    mpChangeLocationClick: "mpChangeLocationClick",
    mpLocationScreenLoad: "mpLocationScreenLoad",
    mpSellSearchLocationClick: "mpSellSearchLocationClick",
    mpSelectLocationClick: "mpSelectLocationClick",
    mpSellAddressPageOpen: "mpSellAddressPageOpen",
    mpSellEnterHouseClick: "mpSellEnterHouseClick",
    mpSellLandmarkClick: "mpSellLandmarkClick",
    mpSellAdressProceedClick: "mpSellAdressProceedClick",
    sellCenterLoad: "sellCenterLoad",
    sellCenterviewListingClick: "sellCenterviewListingClick",
    sellCenterOfferTabClick: "sellCenterOfferTabClick",
    sellCenterPriceTabClick: "sellCenterPriceTabClick",
    sellCenterCalenderTabClick: "sellCenterCalenderTabClick",
    sellViewOfferCard: "sellViewOfferCard"
};
