import { NUMBER, COOKIE_CONSTANTS } from "../../constants/app-constants";
import deleteCookie from "./delete-cookie";
import saveCookie from "./save-cookie";

export default (cars) => {
    const rvAppointmentIds = [];
    (cars || []).forEach(car => {
        if (car?.appointmentId) {
            rvAppointmentIds.push(car?.appointmentId);
        }
    });
    deleteCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS);
    saveCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS, JSON.stringify(rvAppointmentIds), NUMBER.THIRTY);
};
