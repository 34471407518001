import { createReducer } from "../../../utils/redux/create-reducer";
import { defaultHomePageTemplate } from "./constants";
import Types from "./types";

const RECOMMENDED_CARS_INITIAL_STATE = {
    content: [],
    totalCars: 0,
    totalPages: null,
    page: 0,
    isLoading: false,
    error: null,
    config: {},
    shouldReloadList: false,
    isFetched: false
};

export const INITIAL_STATE = {
    content: [],
    metaContent: {
        metaTitle: null,
        metaDescription: null,
        metaKeyWord: null,
        heading: null,
        h1tag: "Used cars"
    },
    footerMetaContent: [],
    totalCars: 0,
    totalCarCount: 0,
    totalPages: null,
    isSSR: false,
    page: 0,
    isLoading: false,
    error: null,
    config: {},
    shouldReloadList: false,
    isFetched: false,
    recommended: RECOMMENDED_CARS_INITIAL_STATE,
    similarCarPage: -1,
    homepageTemplate: defaultHomePageTemplate
};

export const fetchCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const fetchTotalCarCount = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        totalCarCount: data.total
    };
};

export const fetchCarListSuccess = (state = INITIAL_STATE, { data, params }) => {
    return {
        ...state,
        content: [...state.content, ...data.results],
        metaContent: { ...data.metaContent },
        footerMetaContent: data?.footerMetaContent,
        totalCars: data.total,
        totalPages: data.totalPages,
        isLoading: false,
        error: null,
        ...((params && params.page >= 0) && { page: params.page }),
        config: data.config,
        isFetched: true
    };
};

export const fetchCarListFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        error,
        isLoading: false
    };
};

export const updateSSRStatus = (state = INITIAL_STATE, { isSSR }) => {
    return {
        ...state,
        isSSR
    };
};

export const resetCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        content: [],
        totalPages: null,
        similarCarPage: -1
    };
};

export const reloadCarList = (state = INITIAL_STATE, { shouldReloadList }) => {
    return {
        ...state,
        shouldReloadList
    };
};

// RECOMMENDED SECTION
export const fetchRecommendedCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        recommended: {
            ...state.recommended,
            isLoading: true
        }
    };
};

export const fetchRecommendedCarListSuccess = (state = INITIAL_STATE, { data, params }) => {
    return {
        ...state,
        recommended: {
            ...state.recommended,
            content: [...state.recommended?.content, ...data.results],
            metaContent: { ...data.metaContent },
            footerMetaContent: data?.footerMetaContent,
            totalCars: data.total,
            totalPages: data.totalPages,
            isLoading: false,
            error: null,
            ...((params && params.page >= 0) && { page: params.page }),
            config: data.config,
            isFetched: true
        }
    };
};

export const fetchRecommendedCarListFailure = (state = INITIAL_STATE, { error }) => {
    return {
        ...state,
        recommended: {
            ...state.recommended,
            error,
            isLoading: false
        }
    };
};

export const resetRecommendedCarList = (state = INITIAL_STATE) => {
    return {
        ...state,
        recommended: {
            ...state.recommended,
            content: [],
            totalPages: null
        }
    };
};

export const reloadRecommendedCarList = (state = INITIAL_STATE, { shouldReloadList }) => {
    return {
        ...state,
        recommended: {
            ...state.recommended,
            shouldReloadList
        }
    };
};

export const incrementSimilarCarPageCount = (state = INITIAL_STATE) => {
    return {
        ...state,
        similarCarPage: state.similarCarPage + 1
    };
};
export const resetSimilarCarPageCount = (state = INITIAL_STATE) => {
    return {
        ...state,
        similarCarPage: -1
    };
};

export const fetchHomepageTemplateSuccess = (state = INITIAL_STATE, {pageContent}) => {
    return {
        ...state,
        homepageTemplate: pageContent || state.homepageTemplate || {}
    };
};

export const HANDLERS = {
    [Types.FETCH_CAR_LIST]: fetchCarList,
    [Types.FETCH_CAR_LIST_SUCCESS]: fetchCarListSuccess,
    [Types.FETCH_CAR_LIST_FAILURE]: fetchCarListFailure,
    [Types.UPDATE_SSR_STATUS]: updateSSRStatus,
    [Types.RESET_CAR_LIST]: resetCarList,
    [Types.RELOAD_CAR_LIST]: reloadCarList,
    [Types.FETCH_CAR_LIST_TOTAL]: fetchTotalCarCount,
    [Types.INCREMENT_SIMILAR_CAR_PAGE_COUNT]: incrementSimilarCarPageCount,
    [Types.RESET_SIMILAR_CAR_PAGE_COUNT]: resetSimilarCarPageCount,

    // Recommended Section
    [Types.FETCH_RECOMMENDED_CAR_LIST]: fetchRecommendedCarList,
    [Types.FETCH_RECOMMENDED_CAR_LIST_SUCCESS]: fetchRecommendedCarListSuccess,
    [Types.FETCH_RECOMMENDED_CAR_LIST_FAILURE]: fetchRecommendedCarListFailure,
    [Types.RESET_RECOMMENDED_CAR_LIST]: resetRecommendedCarList,
    [Types.RELOAD_RECOMMENDED_CAR_LIST]: reloadRecommendedCarList,

    //Homepage Templates
    [Types.FETCH_HOMEPAGE_TEMPLATE]: fetchHomepageTemplateSuccess
};

export default createReducer(INITIAL_STATE, HANDLERS);
