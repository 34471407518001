import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// hooks
import useLink from "../../../hooks/use-link";
import { NUMBER } from "../../../constants/app-constants";
import IconSpinner from "../../shared/icon-spinner";

// Images

// components

// utils

// constants

const MsiteIframe = ({
    url = "",
    width = "100%",
    height = "100%",
    isLoggedIn,
    getRefreshTokenInfoConnect,
    restrictedPaths = []
}) => {

    const { createLink } = useLink();
    const domainUrl = createLink("/").absoluteUrl.slice(0, -1);
    const urlParsed = url.includes("?") ? `${url}&device=mobile` : `${url}?device=mobile`;

    const [loginSyncCounter, setLoginSyncCounter] = useState(NUMBER.ONE);
    const [loginSyncTriggerSource, setLoginSyncTriggerSource] = useState("");
    const [showRestrictedPathLoader, setShowRestrictedPathLoader] = useState(true);

    const startLoginSync = (source = "") => {
        setLoginSyncTriggerSource(source);
        window.setTimeout(() => {
            getRefreshTokenInfoConnect().then(() => {
                if (source !== "mobile") {
                    setLoginSyncCounter(prev => prev + NUMBER.ONE);
                }
            }).catch(() => { });
        }, NUMBER.HUNDRED);
    };

    const diplayRestrictedPathLoader = () => {
        setShowRestrictedPathLoader(true);
        window.setTimeout(() => {
            setShowRestrictedPathLoader(false);
        }, NUMBER.THOUSAND);
    };

    const handleRouteChange = (pathPassed) => {
        let isRestrictedPath = false;
        restrictedPaths.forEach(path => {
            isRestrictedPath = isRestrictedPath || (path || "").replaceAll("/", "").includes((pathPassed || "").replaceAll("/", ""));
        });

        if (isRestrictedPath) {
            diplayRestrictedPathLoader();
            setLoginSyncCounter(prev => prev + NUMBER.ONE);
        }
    };

    useEffect(() => {
        if (loginSyncTriggerSource !== "mobile") {
            startLoginSync();
        } else {
            setLoginSyncTriggerSource("");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {

        diplayRestrictedPathLoader();

        // Event listener for messages from the iframe
        const handleMessage = (event) => {
            if (event.origin !== domainUrl) {
                return;
            }

            const { eventData, eventType } = event?.data || {};

            switch (eventType) {
                case "login":
                    startLoginSync("mobile");
                    break;
                case "logout":
                    startLoginSync();
                    break;
                case "routeChange":
                    handleRouteChange(eventData);
                    break;
                default:
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div styleName={"styles.iframeWrapper"} >
            {showRestrictedPathLoader ?
                <div styleName={"styles.loaderWrapper"} >
                    <div styleName={"styles.spinnerWrapper"} >
                        <IconSpinner />
                    </div>
                </div>
                :
                <React.Fragment />
            }
            <iframe key={loginSyncCounter} src={urlParsed} width={width} height={height} frameBorder="0" />
        </div>
    );
};

MsiteIframe.propTypes = {
    url: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    getRefreshTokenInfoConnect: PropTypes.func,
    restrictedPaths: PropTypes.array
};

export default MsiteIframe;
