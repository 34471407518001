import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ConvenienceFeeBreakupPopup from "./component";

const mapStateToProps = ({
    config: {
        convenienceFeeConfig
    } = {}
}) => ({
    convenienceFeeConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConvenienceFeeBreakupPopup));
