export const pageSectionsConfig = {
    "SUPER_APP_HOME_PAGE_DESKTOP_NAV_BAR": "desktopSuperAppHomepageNavBar",
    "SUPER_APP_HOME_PAGE_DESKTOP_HERO_BANNER": "desktopSuperAppHomepageLandingBanner",
    "SUPER_APP_HOME_PAGE_DESKTOP_FILTERS": "desktopSuperAppHomepageFilters",
    "SUPER_APP_HOME_PAGE_DESKTOP_CAR_RECOMMENDATION": "desktopSuperAppHomepageCarRecommendation",
    "SUPER_APP_HOME_PAGE_DESKTOP_EXCLUSIVE_OFFERS": "desktopSuperAppHomepageExclusiveOffers",
    "SUPER_APP_HOME_PAGE_DESKTOP_BENEFITS": "desktopSuperAppHomepageBenefitsSection",
    "SUPER_APP_HOME_PAGE_DESKTOP_HOW_IT_WORKS": "desktopSuperAppHomepageHowItWorksSection",
    "SUPER_APP_HOME_PAGE_DESKTOP_LOCATIONS": "desktopSuperAppHomepageLocations",
    "SUPER_APP_HOME_PAGE_DESKTOP_REQUEST_CALLBACK_SECTION": "desktopSuperAppHomepageRequestCallback",
    "SUPER_APP_HOME_PAGE_DESKTOP_TESTIMONIALS": "desktopSuperAppHomepageTestimonial",
    "SUPER_APP_HOME_PAGE_DESKTOP_BLOGS_SECTION": "desktopSuperAppHomepageBlogSection",
    "SUPER_APP_HOME_PAGE_DESKTOP_BUDGET_SECTION": "desktopSuperAppHomepageBudgetSection",
    "SUPER_APP_HOME_PAGE_DESKTOP_FAQS": "desktopSuperAppHomepageFaqs"
};
