/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component
import ICON_CAR_SPECS from "../../shared/icon-car-specs";
import BasicDetailsContent from "../../shared/basic-details-content";
import HighlightInfoPopup from "./highlight-info-popup";

// Constants
import { ASSORTMENT_CATEGORY_LABEL, NUMBER } from "../../../constants/app-constants";
import { SECTION_NAME, FEATURE_ICON } from "./constant";

import IconInfo from "../../shared/images/icons/info-icon-outline.svg";
import RTAPass from "./images/rta-pass.svg";
import ConvenienceFeeBreakupPopup from "../convenience-fee-breakup-popup-v2";

const CarDetailOverview = ({
    carHighlights,
    carUsps = [],
    warrantyDuration,
    assortmentFlag,
    togglePriceBenifitsConnect
}) => {

    const getInfoPopup = (itemData) => {

        if (itemData?.key === "manufactureWarranty") {
            return (
                <HighlightInfoPopup
                    title={"Manufacturer warranty"}
                    description={itemData?.longDescription}
                    renderIcon={<img src={IconInfo} alt="" width="16" height="16" />}
                />
            );
        } else if (itemData?.key === "convenienceFee") {
            return (
                <HighlightInfoPopup
                    title={"Convenience Fee"}
                    renderIcon={<img src={IconInfo} alt="" width="16" height="16" />}
                    renderCustomComponent={() => <ConvenienceFeeBreakupPopup />}
                />
            );
        }

        return <React.Fragment/>;
    };

    return (
        <React.Fragment>
            {(carHighlights || []).length > NUMBER.ZERO && (
                <div styleName={"styles.overviewSection"}>
                    <h2>Overview</h2>
                    <div styleName={"styles.gridRow"}>
                        {(carHighlights || [])?.slice(NUMBER.ZERO, NUMBER.THREE).map((item) => {

                            const showInfoIcon = (item.key === "manufactureWarranty") || (item?.key === "convenienceFee");

                            return (
                                <div key={item?.key} styleName="styles.overviewItems">
                                    <div styleName={"styles.iconWrap"}>
                                        <img src={ICON_CAR_SPECS[item.iconName] || ICON_CAR_SPECS.default} alt={item.name} width="36" height="36" />
                                    </div>
                                    <div styleName={"styles.content"}>
                                        <h4>{item.name}</h4>
                                        <div styleName={"styles.flexRow"}>
                                            <p>{item.description}</p>
                                            {showInfoIcon ? getInfoPopup(item) : <React.Fragment />}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {assortmentFlag === ASSORTMENT_CATEGORY_LABEL.BETA_ACCIDENT &&
                            <div key={"minorAccident"} styleName="styles.overviewItems">
                                <div >
                                    <img src={RTAPass} alt="Accident icon" width="60" height="60" />
                                </div>
                                <div styleName={"styles.content"}>
                                    <h4>Minor accident reported</h4>
                                    <div styleName={"styles.flexRow"}>
                                        <p>Refurbished to high standards & has passed all relevant RTA safety standards.</p>
                                        <img src={IconInfo} alt="" width="16" height="16" onClick={() => togglePriceBenifitsConnect()} />
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            )}

            <BasicDetailsContent isDesktop={true} />
            <div styleName={"styles.featureScroll"}>
                {(carUsps || []).map((item, index) => (
                    <div
                        key={index}
                        styleName={"styles.item"}
                    // onClick={() => openPrimeModal(item.sectionName, item.ga_name)}
                    >
                        <img src={FEATURE_ICON[item.sectionName]} alt="" width="24" height="24" />
                        <h3>{item.title}</h3>
                        <p>{item.sectionName === SECTION_NAME.FREE_WARRANTY ? `upto ${warrantyDuration || NUMBER.THREE} months` : item.desc}</p>
                    </div>
                ))}
            </div>

        </React.Fragment>
    );
};

CarDetailOverview.propTypes = {
    carHighlights: PropTypes.object,
    warrantyDuration: PropTypes.number,
    carUsps: PropTypes.array,
    assortmentFlag: PropTypes.string,
    togglePriceBenifitsConnect: PropTypes.func
};

export default CarDetailOverview;
