import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// Styles
import styles from "./styles.css";

// components
import LazyImage from "../../shared/lazy-image";
import MsiteIframe from "../msite-iframe";
import useLink from "../../../hooks/use-link";
import { InView } from "react-intersection-observer";
import { ROUTE_PATHS } from "../../../constants/routes-constants";

const ServicingHomeBanner = ({
    sectionInfo = {},
    updateShowAppDownloadWidgetConnect
}) => {

    const {
        title = "",
        subTitle = "",
        backgroundImage: {
            url: bannerUrl = "",
            alternativeText = ""
        } = {},
        subSections = []
    } = sectionInfo || {};

    let subsectionData = [];

    if (subSections.length) {
        const { sectionData } = subSections[0];
        subsectionData = sectionData;
    }

    const { createLink } = useLink();
    const servicingFlowUrl = createLink("/car-servicing-desktop/").absoluteUrl;

    const onServiciningFlowShown = (inView) => {
        if (inView) {
            updateShowAppDownloadWidgetConnect(false);
        } else {
            updateShowAppDownloadWidgetConnect(true);
        }

    };

    return (
        <React.Fragment>
            <Helmet>
                <link fetchPriority="high" rel="preload" href={bannerUrl} as={"image"} type="image/png" />
            </Helmet>
            <div styleName={"styles.bannerWrapper"} >
                <img src={bannerUrl} alt={alternativeText} />
                <div styleName={"styles.bannerContentWrapper"} >
                    <div styleName={"styles.bannerContent"} >
                        <div>
                            {title && <h1>{title}</h1>}
                        </div>
                        <div>
                            {subTitle && <h2>{subTitle}</h2>}
                        </div>
                        <div>
                            <div styleName={"styles.highlightsSection"}   >
                                {(subsectionData || []).map((subSectionInfo, idx) => {
                                    const {
                                        image: {
                                            url: imageUrl = "",
                                            alternativeText: subsectionAlternativeText = ""
                                        } = {},
                                        title: subsectionTitle = ""
                                    } = subSectionInfo || {};
                                    return (
                                        <div styleName={"styles.highlightsCard"} key={`heroBannerSubsection_${idx}`} >
                                            <LazyImage src={imageUrl} alt={subsectionAlternativeText} />
                                            {title && <p>{subsectionTitle}</p>}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div styleName={"styles.servicingMsiteFlowWrapper"}>
                    <InView rootMargin={"0px 0px 0px 0px"} as="div" onChange={onServiciningFlowShown}>
                        <MsiteIframe
                            url={servicingFlowUrl}
                            width={"500px"}
                            height={"740px"}
                            restrictedPaths={[
                                "/",
                                ROUTE_PATHS.carServicing
                            ]}
                        />
                    </InView>
                </div>
            </div>
        </React.Fragment>
    );
};

ServicingHomeBanner.propTypes = {
    onCtaClick: PropTypes.func,
    sectionInfo: PropTypes.object,
    updateShowAppDownloadWidgetConnect: PropTypes.func
};

export default ServicingHomeBanner;
