import React, { useState, useEffect } from "react";
import styles from "./styles.css";
import UserIcon from "./images/userIcon.svg";
import InputText from "../../shared/input-text";
import Button from "../../shared/button";
import PropTypes from "prop-types";
import nameValidator from "../../../utils/validators/name-validator";
import { validateEmail } from "../../../utils/validators/email-validator";
import withLogin from "../with-login";
import { NUMBER, USER_LOGIN_MEDIUM, USER_LOGIN_STATUS } from "../../../constants/app-constants";
import H2 from "../../shared/h2";
import ToggleSwitch from "../../shared/toggle-switch";
import { CHANNEL_TYPES } from "../../../constants/api-constants";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";

const ProfileInfo = ({
    name,
    mobile,
    history,
    email,
    secureToken,
    isDNDActive,
    updateProfileConnect,
    updateDNDStatusConnect,
    updateUserConsentConnect,
    getUserConsentConnect,
    logoutConnect,
    locales
}) => {
    const [userName, setUserName] = useState(name);
    const [userEmail, setUserEmail] = useState(email);
    const [profileUpdating, setProfileUpdating] = useState(false);
    const [dndUpdating, setDndUpdating] = useState(false);

    const onNameChange = (e) => {
        setUserName(e.target.value);
    };
    const onEmailChange = (e) => {
        setUserEmail(e.target.value);
    };

    const onClickProfileUpdate = () => {
        setProfileUpdating(true);
        updateProfileConnect(userName, userEmail, secureToken).then(() => {
            window.setTimeout(() => {
                setProfileUpdating(false);
            }, NUMBER.THOUSAND);
        });
    };

    const toggleDNDStatus = () => {
        setDndUpdating(true);
        // trackDesktopCustomEventsAE(!isDNDActive ? "b2cDndProfileOn" : "b2cDndProfileOff");
        updateDNDStatusConnect(!isDNDActive);
        const consentPayload = {
            uid: mobile,
            optIn: !isDNDActive,
            channel: CHANNEL_TYPES.DND
        };
        updateUserConsentConnect(consentPayload).then(() => {
            window.setTimeout(() => {
                setDndUpdating(false);
            }, NUMBER.THOUSAND);
        });
    };

    const triggerGA4LogoutEvent = () => {
        trackDesktopCustomEventsAE("ga4Logout", {
            user_id: mobile,
            user_status: USER_LOGIN_STATUS.LOGGED_OUT,
            login_type: USER_LOGIN_MEDIUM.NONE
        });
        trackDesktopCustomEventsAE("userLogoutEvent");
    };

    const onClickLogout = (e) => {
        e.preventDefault();
        triggerGA4LogoutEvent();
        logoutConnect();
        history.push(`/`);
    };

    useEffect(() => {
        if (mobile) {
            getUserConsentConnect({ uid: mobile })
                .then((consentDetail) => {
                    updateDNDStatusConnect(consentDetail?.dnd || false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobile]);

    useEffect(() => {
        setUserName(name);
    }, [name]);

    useEffect(() => {
        setUserEmail(email);
    }, [email]);

    const isProceedDisabled = !nameValidator(userName) || !validateEmail(userEmail);

    return (
        <React.Fragment>
            <div className={"container"} styleName={"styles.wrapper"}>
                <H2 text={locales.heading} />
                <div className={"row"}>
                    <div className={"col-md-5"}>
                        <div styleName={"styles.profileCircle"}>
                            <img src={UserIcon} alt="profile" />
                        </div>
                    </div>
                    <div className={"col-md-7"}>
                        <div styleName={"styles.mobileNumber"}>
                            <label>{locales.info.mobile.label}</label>
                            <p>{`+971 ${mobile}`}</p>
                        </div>
                        <div styleName={"styles.inputField"}>
                            <InputText
                                validator={nameValidator}
                                value={userName}
                                onChange={onNameChange}
                                errorMessage={locales.info.name.error}
                                text={locales.info.name.label} />
                        </div>
                        <div styleName={"styles.inputField styles.email"}>
                            <InputText
                                value={userEmail}
                                onChange={onEmailChange}
                                validator={validateEmail}
                                errorMessage={locales.info.email.error}
                                text={locales.info.email.label} />
                        </div>
                        <div styleName={"styles.dndOption"}>
                            {locales.dndLabel}
                            <div styleName={"styles.switchBlue"}>
                                <ToggleSwitch
                                    checked={!isDNDActive}
                                    onChangeCallback={toggleDNDStatus}
                                    disabled={dndUpdating}
                                    blueVariant={true}
                                />
                            </div>
                        </div>
                        <div styleName={"styles.buttonWrap"}>
                            <Button
                                disabled={isProceedDisabled}
                                onClick={onClickProfileUpdate}
                                isLoading={profileUpdating}
                                text={profileUpdating ? locales.info.button.updating : locales.info.button.update} />
                            <a href="" onClick={onClickLogout}>{locales.logout}</a>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

ProfileInfo.propTypes = {
    mobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    email: PropTypes.string,
    secureToken: PropTypes.string,
    history: PropTypes.object,
    updateProfileConnect: PropTypes.func,
    logoutConnect: PropTypes.func,
    locales: PropTypes.object,
    isDNDActive: PropTypes.bool,
    updateDNDStatusConnect: PropTypes.func,
    getUserConsentConnect: PropTypes.func,
    updateUserConsentConnect: PropTypes.func,
    updateEmailIdPostBcConnect: PropTypes.func
};

export default withLogin(ProfileInfo);
