/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component

// Constants

const HighlightInfoPopup = ({ renderIcon, description, title, renderCustomComponent }) => {
    return (
        <div className="infoWrapper">
            <div styleName={"styles.infoImage"}>
                {renderIcon}
                <span styleName={"styles.infoPopup"}>
                    {
                        renderCustomComponent ?
                            renderCustomComponent()
                            :
                            <React.Fragment>
                                <p styleName={"styles.title"}>{title}</p>
                                <p>{description}</p>
                            </React.Fragment>
                    }
                </span>
            </div>
        </div>
    );
};

HighlightInfoPopup.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    renderIcon: PropTypes.object,
    renderCustomComponent: PropTypes.func
};

export default HighlightInfoPopup;
