export const STEP_NAMES = {
    Brand: "brand",
    Model: "model",
    FuelType: "fuelType",
    Transmission: "transmission",
    Engine: "engine",
    Year: "year",
    Variant: "variant",
    State: "registrationState",
    Confirmation: "confirmation",
    KilometerDriven: "kilometersDriven",
    Branch: "branch",
    Date: "date",
    Time: "time",
    Override: "override",
    User: "user",
    Location: "location",
    Price: "price",
    Body: "body",
    Priority: "priority",
    CarService: "carService",
    Personal: "personal",
    Residential: "residential",
    Income: "income",
    BookAppointment: "bookAppointment",
    LeadCreation: "leadCreation",
    Address: "address",
    AppointmentSlots: "slots",
    AssuredPrice: "assuredPrice"
};

export const EVALUATION_BASE_ROUTE = "/car-evaluation";
export const SELLER_HOME_ROUTE = "/sell-used-car/";
export const APPOINTMENT_STATUS = {
    CONFIRMED: "CONFIRMED"
};
export const ROUTE_PATHS = {
    Brand: "brand",
    Model: "model",
    FuelType: "fuelType",
    Cylinders: "cylinders",
    Transmission: "transmission",
    Engine: "engine",
    Year: "year",
    Variant: "variant",
    LeadCreation: "lead-creation",
    CarCondition: "car-condition",
    BookAppointment: "book-appointment",
    Address: "address",
    AppointmentSlots: "slots",
    AssuredPrice: "assured-price",
    PlanSelector: "plan-selector",
    Confirmation: "confirmation",
    DropOff: "drop-off",
    NoGsEligible: "gs-non-eligible",
    EditCarDetails: "edit",
    Store: "store",
    Status: "status",
    LoanStatus: "loan-status",
    Documents: "documents",
    PaymentMode: "payment-mode",
    RTAAppointment: "rta-appointment",
    CancelAppointment: "cancel-appointment",
    SellCarByCity: "/sell-used-:carMakeModel-cars-:city",
    SellBrand: "/car-evaluation/brand",
    AddPhotos: "add-photos",
    NoOffer: "no-offer"
};

export const SELL_ROUTES = {
    Brand: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.Brand}`,
    Model: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.Model}`,
    Year: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.Year}`,
    Variant: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.Variant}`,
    Engine: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.Engine}`,
    Transmission: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.Transmission}`,
    FuelType: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.FuelType}`,
    Cylinders: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.Cylinders}`,
    LeadCreation: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.LeadCreation}`,
    EditCarDetails: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.EditCarDetails}`,
    CarCondition: `${EVALUATION_BASE_ROUTE}/${ROUTE_PATHS.CarCondition}`,
    BookAppointment: `/${ROUTE_PATHS.BookAppointment}`,
    Address: `/book-appointment/${ROUTE_PATHS.Address}`,
    AppointmentSlots: `/book-appointment/${ROUTE_PATHS.AppointmentSlots}`,
    AssuredPrice: `/book-appointment/${ROUTE_PATHS.AssuredPrice}`,
    Confirmation: `/book-appointment/${ROUTE_PATHS.Confirmation}`,
    Store: `/book-appointment/${ROUTE_PATHS.Store}`,
    NoOffer: `/book-appointment/${ROUTE_PATHS.NoOffer}`,
    InspectionDone: `/seller/revaluation/0`,
    PriceAccepted: `/seller/post-price-acceptance/status`,
    Cancelled: `/book-appointment/${ROUTE_PATHS.CancelAppointment}`,
    AddPhotos: `/book-appointment/${ROUTE_PATHS.AddPhotos}`,
    SellerCenter: `/seller-center`,
    SellPlanSelector: `/book-appointment/${ROUTE_PATHS.PlanSelector}`,
    ListPlanSelect: `/seller/marketplace`
};

export const EVALUATION_TABS = [
    { name: "Brand", index: 1, stepId: ROUTE_PATHS.Brand, route: SELL_ROUTES.Brand, label: "make" },
    { name: "Model", index: 2, stepId: ROUTE_PATHS.Model, route: SELL_ROUTES.Model, label: "model" },
    { name: "Year", index: 3, stepId: ROUTE_PATHS.Year, route: SELL_ROUTES.Year, label: "year" },
    { name: "Variant", index: 4, stepId: ROUTE_PATHS.Variant, route: SELL_ROUTES.Variant, label: "variant" },
    { name: "Engine", index: 5, stepId: ROUTE_PATHS.Engine, route: SELL_ROUTES.Engine, label: "engineSize" },
    { name: "Transmission", index: 6, stepId: ROUTE_PATHS.Transmission, route: SELL_ROUTES.Transmission, label: "transmission" },
    { name: "FuelType", index: 7, stepId: ROUTE_PATHS.FuelType, route: SELL_ROUTES.FuelType, label: "fuelType" },
    { name: "Cylinders", index: 8, stepId: ROUTE_PATHS.Cylinders, route: SELL_ROUTES.Cylinders, label: "noOfCylinders" }
];

export const GA_EVENTS_CONSTANTS = {
    SEND_OTP: "SEND_OTP",
    VERIFY_OTP: "VERIFY_OTP",
    CANCEL: "CANCEL",
    CROSS: "CROSS",
    BACK: "BACK",
    ODOMETER_RANGE_READING: "ODOMETER_RANGE_READING",
    SPECIFICATION: "SPECIFICATION",
    EXTERIOR_CONDITION: "EXTERIOR_CONDITION",
    CHANGE_NUMBER: "CHANGE_NUMBER",
    INTENDED_SALE_TIME: "INTENDED_SALE_TIME",
    EXPECTED_CAR_PRICE: "EXPECTED_CAR_PRICE"
};

export const INTERNAL_REJECTION_REASON = "Rejected due to Assortment";

export const LEAD_STATUS = {
    CREATED: "CREATED",
    INSPECTION_QUESTION_DONE: "INSPECTION_QUESTION_DONE",
    OTP_VERIFIED: "OTP_VERIFIED",
    CONFIRMED: "CONFIRMED",
    INSPECTION_DONE: "INSPECTION_DONE",
    DISPUTE_RAISED: "DISPUTE_RAISED",
    RAISE_DISPUTE: "RAISE_DISPUTE",
    DOCUMENTS_UPLOADED: "DOCUMENTS_UPLOADED",
    PRICE_ACCEPTED: "PRICE_ACCEPTED",
    CANCELLED: "CANCELLED"
};

export const SELF_INSPECTION_OPTION_TYPE = {
    GRID: "GRID",
    LIST: "LIST",
    GRID_LIST: "GRID_LIST"
};

export const QUESTIONS_TYPE = {
    CONTROL: "experimentC",
    VARIANT: "experimentN",
    BVC: "experimentValuationReport"
};

const STATIC_TOKEN = {
    STAGING: "753a92c3-b53c-4c34-b159-d4ea4222ac3d",
    QA: "753a92c3-b53c-4c34-b159-d4ea4222ac3d",
    PRODUCTION: "af027882-20bc-443c-80ee-39c150844c4b"
};
export const SLOT_PANNER_TOKEN = `bearer ${STATIC_TOKEN[process.env.HOST_ENV]}`;

export const STATUS_ROUTES = {
    CREATED: SELL_ROUTES.CarCondition,
    INSPECTION_QUESTION_DONE: SELL_ROUTES.AssuredPrice,
    PRICE_ACCEPTED: SELL_ROUTES.PriceAccepted,
    CONFIRMED: SELL_ROUTES.Confirmation,
    INSPECTION_DONE: SELL_ROUTES.InspectionDone,
    RAISE_DISPUTE: SELL_ROUTES.InspectionDone,
    DOCUMENTS_UPLOADED: SELL_ROUTES.PriceAccepted,
    CANCELLED: SELL_ROUTES.Cancelled,
    SELLER_CENTER: SELL_ROUTES.SellerCenter,
    NO_OFFER: SELL_ROUTES.NoOffer
};
export const FEEDBACK_OPTIONS = {
    BETTER_OFFER: "BETTER_OFFER",
    HIGHER_PRICE: "HIGHER_PRICE",
    CHANGED_MIND: "CHANGED_MIND",
    OTHERS: "OTHERS"
};
export const LOCATION_FAQ_CATEGORY = "Location";
export const ABHU_DHABI_CITY = "ABU_DHABI";
export const DUBAI_CITY = "dubai";
export const SELLER_CITY_CODES = {
    "dubai": "DU_DUBAI",
    "abu-dhabi": "CC_AE_2"
};

export const COUPON_PRICE = 2000;
export const PRE_OWNED_CARS = 1500;
export const COUPON_CODE = "TRADEINCAR500";
export const BUY_NOW_ROUTE = "/buy-used-cars-dubai/";
export const SELLER_ONLINE_INSPECTION_VIDEO = "https://www.youtube.com/watch?v=-JZFNZ7fLfk";
