import { ListingService, cmsService} from "../../../service";
import Types from "./types";
// import {updateAppliedFilters} from "../filters/actions";
import getFiltersData from "../../../utils/filters-v2/get-filters-data";
import initAppliedFilters from "../../../utils/filters-v2/init-applied-filters";
import { updateSSRFiltersList } from "../filters/actions";
import { AE_LISTING_API_COUNT, cmsPageSlugs, DEVICE_TYPE, NUMBER } from "../../../constants/app-constants";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { logout } from "../../ae.configuration/actions";

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data, params) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data,
    params
});

const cmsPageSlug = cmsPageSlugs.homePageDesktop;

const fetchCarListFailure = (error) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error });

const fetchCarList = (params, queryString, version = "v3") => (dispatch, getState) => {
    dispatch(fetchCarListInit());
    const { user: { secureToken = "" } } = getState() || {};
    const platform = DEVICE_TYPE.DESKTOP;
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, version, secureToken, platform)
            .then(response => {
                if (response.data?.total === NUMBER.ZERO) {
                    trackDesktopCustomEventsAE("zeroResults", {eventLabel: "listing"});
                }
                dispatch(fetchCarListSuccess(response.data, params));
                if (response.data.selectedFilters) {
                    const parsedFilters = getFiltersData(response.data.selectedFilters);
                    const appliedFiltersData = initAppliedFilters(parsedFilters.allFilters);
                    dispatch(updateSSRFiltersList({...appliedFiltersData, isSSR: false}));
                }
                resolve(response.data);
            }).catch(error => {
                if (typeof error === "string" && (error || "").includes("401")) {
                    dispatch(logout());
                    window.setTimeout(() => {
                        window.location.href = "/";
                    }, NUMBER.THOUSAND);
                }
                dispatch(fetchCarListFailure(error));
                reject(error);
            });
    });
};

const fetchListCarTotalList = (data, params) => ({
    type: Types.FETCH_CAR_LIST_TOTAL,
    data,
    params
});

const fetchCarListTotalCount = (params, queryString) => (dispatch, getState) => {
    const { user: { secureToken = "" } } = getState() || {};
    const platform = DEVICE_TYPE.DESKTOP;
    ListingService.fetchListing(params, queryString, "", secureToken, platform)
        .then((response) => dispatch(fetchListCarTotalList(response.data)));
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const resetCarList = () => ({
    type: Types.RESET_CAR_LIST
});

const reloadCarList = (shouldReloadList) => ({
    type: Types.RELOAD_CAR_LIST,
    shouldReloadList
});

const fetchSimilarCarListOnSearch = (params, queryString, version = "v3") => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    const platform = DEVICE_TYPE.DESKTOP;
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, version, secureToken, platform)
            .then(response => {
                resolve(response.data);
                if (response?.data?.total > AE_LISTING_API_COUNT) {
                    dispatch({ type: Types.INCREMENT_SIMILAR_CAR_PAGE_COUNT });
                } else {
                    dispatch({ type: Types.RESET_SIMILAR_CAR_PAGE_COUNT });
                }
            })
            .catch(error => reject(error));
    });
};

// RECOMMENDED ACTIONS
const fetchRecommendedCarListInit = () => ({
    type: Types.FETCH_RECOMMENDED_CAR_LIST
});

const fetchRecommendedCarListSuccess = (data, params) => ({
    type: Types.FETCH_RECOMMENDED_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchRecommendedCarListFailure = (error) => ({ type: Types.FETCH_RECOMMENDED_CAR_LIST_FAILURE, error });

const fetchRecommendedCarList = (params, queryString) => (dispatch, getState) => {
    const { user: { secureToken } } = getState();
    const platform = DEVICE_TYPE.DESKTOP;
    dispatch(fetchRecommendedCarListInit());
    return new Promise((resolve, reject) => {
        ListingService.fetchListing(params, queryString, "v3", secureToken, platform)
            .then(response => {
                dispatch(fetchRecommendedCarListSuccess(response.data, params));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchRecommendedCarListFailure(error));
                reject(error);
            });
    });
};

const resetRecommendedCarList = () => ({
    type: Types.RESET_RECOMMENDED_CAR_LIST
});

const reloadRecommendedCarList = (shouldReloadList) => ({
    type: Types.RELOAD_RECOMMENDED_CAR_LIST,
    shouldReloadList
});

const fetchHomepageTemplate = (data) => ({
    type: Types.FETCH_HOMEPAGE_TEMPLATE,
    pageContent: data?.pageContent || {}
});

const fetchHomepageContent = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        try {
            const pageContentResponse = await cmsService.fetchPageContent(cmsPageSlug);
            const data = {pageContent: {...pageContentResponse?.data?.data, isDefaultConfig: false}};
            dispatch(fetchHomepageTemplate(data));
        } catch (err) {
            reject(err);
        }
    });
};

export {
    fetchCarList,
    updateSSRStatus,
    resetCarList,
    reloadCarList,
    fetchCarListTotalCount,
    fetchSimilarCarListOnSearch,
    // RECOMMENDED
    fetchRecommendedCarList,
    resetRecommendedCarList,
    reloadRecommendedCarList,
    fetchHomepageTemplate,
    fetchHomepageContent
};
