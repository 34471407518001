
export const b2cPostBcPageEvents = {
    "checkoutSelectDate": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "Select_Date"
    },
    "checkoutSelectSlot": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "Select_Slot"
    },
    "checkoutTdsBooked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "TDS_Booked"
    },
    "tdsPageVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "TDS_Page_visited"
    },
    "hubTdsGetDirection": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubtds_get_direction"
    },
    "hubtdsAddemptySlot": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubtds_addempty_slot"
    },
    "hubtdsBookmorecar": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubtds_bookmorecar"
    },
    "hubtdsVideoplay": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubtds_videoplay"
    },
    "hubtdsHelpcta": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubtds_helpcta"
    },
    "hubtdsReschedule": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubtds_reschedule"
    },
    "hubtdsBackbutton": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubtds_backbutton"
    },
    "hometdsAddemptySlot": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hometds_addempty_slot"
    },
    "hometdsReschedule": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hometds_reschedule"
    },
    "hometdsBackbutton": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hometds_backbutton"
    },
    "viewSimilarcomponent": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "view_similarcomponent"
    },
    "hubsimilarCarclick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubsimilar_carclick"
    },
    "homesimilarCarclick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "homesimilar_carclick"
    },
    "hubsimilarCarswipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubsimilar_carswipe"
    },
    "hubtdsAllSimilarCar": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hubsimilar_allsimilarcar"
    },
    "hometdsHelpcta": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "hometds_helpcta"
    },
    "homesimialrAllSimilarCar": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "homesimialr_allSimilarCar"
    },
    "homesimilarCarswipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "homesimilar_carswipe"
    },
    "checkEligibilityActive": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "check_eligibility_active"
    },
    "positiveResult": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "positive_result"
    },
    "negativeResult": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "negative_result"
    },
    "loanEligibilityEditFinancialDetails": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "edit_financial_detail"
    },
    "loanEligibilityCheckNowClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "Check_now_click"
    },

    "postBCLogin": {
        "event": "custom_event",
        "eventCategory": "Buyer_LoginPage"
    },
    "myBookingSeeMore": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "My_Bookings_See_More",
        "eventLabel": "NA"
    },
    "cancelBooking": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "Cancel_Booking"
    },
    "tokenPayment": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "Token_Payment"
    },
    "callButtonOrderSummary": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "Call_Button_order_summary",
        "eventLabel": "NA"
    },
    "orderSummaryDocsSubmit": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "order_summary_docs_submit"
    },
    "orderSummaryCarClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "order_summary_Car_click",
        "eventLabel": "NA"
    },
    "orderSummaryBookingRefund": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "order_summary_booking_refund_i",
        "eventLabel": "NA"
    },
    "orderSummaryTokenAmount": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "order_summary_token_amount_i",
        "eventLabel": "NA"
    },
    "seeMoreComplimentaryBenefits": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "see_more_complimentary_benefits",
        "eventLabel": "NA"
    },
    "postBcOrderSummaryVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "order_summary_visited"
    },
    "postBcOrderSummaryCarClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "order_summary_Car_click"
    },
    "postBcOrderSummaryAdditonalCharge": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "order_summary_additonal_charge"
    },
    "postBcCallButtonClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "call_Button_clicked"
    },
    "postBcCallOptionSelected": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "call_option_selected"
    },
    "postBcOrderSummaryStageCardClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "order_summary_stage_card_clicked"
    },
    "postBcTestDriveVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "test_drive_visited"
    },
    "postBcTokenVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "token_visited"
    },
    "postBcTokenCtaClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "token_cta_clicked"
    },
    "postBcShareDocsVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "share_docs_visited"
    },
    "postBcShareDocsUploadedSuccess": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "share_docs_uploaded_success"
    },
    "postBcShareDocsUploadedFailed": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "share_docs_uploaded_failed"
    },
    "postBcShareDocsSubmitted": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "share_docs_submitted"
    },
    "postBcLoanSummaryVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "loan_summary_visited"
    },
    "postBcSalesAgreementVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "sales_agreement_visited"
    },
    "postBcSalesAgreementCtaClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "sales_agreement_cta_clicked"
    },
    "postBcSalesAgreementDownload": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "sales_agreement_download"
    },
    "postBcInsuranceVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "insurance_visited"
    },
    "postBcInsuranceCtaClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "insurance_cta_clicked"
    },
    "postBcRegistrationVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "registration_visited"
    },
    "postBcRegistrationMapLinkClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "registration_map_link_clicked"
    },
    "postBcFinalPaymentVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "final_payment_visited"
    },
    "postBcFinalPaymentCtaClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "final_payment_cta_clicked"
    },
    "postBcHelpVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "help_visited"
    },
    "postBcDeliveryVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "delivery_visited"
    },
    "postBcDeliveryScheduleClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "delivery_schedule_clicked"
    },
    "postBcDeliveryRescheduleClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "delivery_reschedule_clicked"
    },
    "postBcDeliveryMaplinkClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "delivery_maplink_clicked"
    },
    "postBcTdNpsClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "TD_nps_clicked"
    },
    "postBcTdNpsVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "TD_nps_visited"
    },
    "postBcD1NpsClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "d1_nps_clicked"
    },
    "postBcD1NpsVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "d1_nps_visited"
    },
    "postBcD30NpsClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "d30_nps_clicked"
    },
    "postBcD30NpsVisited": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "d30_nps_visited"
    },
    "exploreEligibleCars": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "explore_eligible_cars"
    },
    "nonRefundableIcon": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "non_refundable_icon"
    },
    "otherChargesKnowMore": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "other_charges_know_more"
    },
    "payTokenCta": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "pay_token_cta"
    },
    "retryTokenPayment": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "retry_token_payment"
    },
    "tdMrlVideoClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "MRL_VIDEO_Clicked"
    },
    "tdBookMoreCarsTdPageHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "Book_more_cars_TDPAGE_Home"
    },
    "tdBookMoreCarsTdPageMrl": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "Book_more_cars_TDPAGE_MRL"
    },
    "tdEmptySlotClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "Empty_slot_click"
    },
    "tdPageOpenFromHome": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "TD_page_open_from_Home"
    },
    "tdCarImageClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "Car_image_click"
    },
    "tdReschedule": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "TD_RESCHEDULE"
    },
    "tdMrlLocationTdsPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "MRL_LOCATION_TDS_PAGE"
    },
    "MeetlinkOpen": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "MeetlinkOpen"
    },
    "ShareLink": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "ShareLink"
    },
    "availableEmptySlot": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "available_empty_slot"
    },
    "addMoreCarsPlusbutton": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "addmorecars_plusbutton"
    },
    "refurbUserConcernAdded": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "User_concern_added"
    },
    "refurbSubmitCtaClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "Submit_CTA_clicked"
    },
    "refurbViewTeamCommentsClicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Order_Summary",
        "eventAction": "View_team_comments_clicked"
    },
    "joinNowShown": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PostBC_Page",
        "eventAction": "JoinNow_Shown"
    }
};
