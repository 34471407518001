/* eslint-disable max-params */
export default (api) => {

    const submitServicingLead = (mobileNumber, leadSource) => {
        return api.post(`campaign/servicing/create-lead`, { mobileNumber, leadSource });
    };

    const submitBankValuationLead = ({customerPhone, cli}) => {
        return api.post(`campaign/customer/callback`, { customerPhone, cli });
    };

    return {
        submitServicingLead,
        submitBankValuationLead
    };
};
