/* eslint-disable complexity */
import React, { useEffect, useState } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import parseCookie from "../../../utils/helpers/parse-cookie";
import { COOKIE_CONSTANTS, HOME_CAR_SECTION, NUMBER } from "../../../constants/app-constants";
import LazyComponent from "../../shared/lazy-component";
import loadable from "@loadable/component";
import { defaultFilterPayload, defaultParams } from "../car-listing/constants";
import Filters from "../../../utils/filters-v2";
import updateRecentlyViewedCookie from "../../../utils/helpers/update-recently-viewed-cookie";
import deleteCookie from "../../../utils/helpers/delete-cookie";

const HomeCarSection = loadable(() => import("../home-car-section"));

const CarRecommendations = ({
    isReturningUser,
    setIsReturningUserConnect,
    fetchRecentlyViewedCarsConnect,
    selectedCityCode,
    selectedCityName,
    hasCitySkip,
    recentlyViewedCars = []
}) => {

    const filtersData = {
        [HOME_CAR_SECTION.POPULAR_CARS]: "Popular cars",
        [HOME_CAR_SECTION.RECENTLY_VIEWED]: "Recently viewed",
        [HOME_CAR_SECTION.RECOMMENDED]: "Recommended cars"
    };

    const [selectedFilter, setSelectedFilter] = useState("");
    const [showFilters, setShowFilters] = useState(false);

    const onFilterClick = (filterkey) => {
        setSelectedFilter(filterkey);
    };

    const recentlyViewedCarsDataFromCookie = JSON.parse(parseCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS) || "[]") || [];
    const hasUserVisitedBefore = recentlyViewedCars?.length !== NUMBER.ZERO || false;
    const filters = new Filters({ cityCode: selectedCityCode, cityName: selectedCityName });

    const fetchRecentlyViewedCars = () => {
        const filterPayload = {
            ...defaultFilterPayload,
            options: { sf: `appointmentId:${recentlyViewedCarsDataFromCookie?.join(";")}` }
        };
        const params = {
            ...defaultParams,
            variant: "filterV5",
            size: NUMBER.TEN
        };
        const { payload } = filters.getListingPayload({ ...filterPayload, hasCitySkip });
        fetchRecentlyViewedCarsConnect(params, payload).then(({ total, results }) => {
            // Handle edge case where recently viewed cars might get de-listed
            if (total < recentlyViewedCarsDataFromCookie?.length) {
                updateRecentlyViewedCookie(results);
            }
            // eslint-disable-next-line no-unused-vars
        }).catch((error) => {  deleteCookie(COOKIE_CONSTANTS.RECENTLY_VIEWED_CARS);});
    };

    useEffect(() => {
        if (recentlyViewedCarsDataFromCookie?.length) {
            fetchRecentlyViewedCars();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isReturningUser) {
            setIsReturningUserConnect(hasUserVisitedBefore);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasUserVisitedBefore]);

    useEffect(() => {
        if (recentlyViewedCars?.length >= NUMBER.THREE) {
            setShowFilters(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentlyViewedCars?.length]);

    const displayFilters = showFilters ? [HOME_CAR_SECTION.RECENTLY_VIEWED, HOME_CAR_SECTION.RECOMMENDED] : [];

    const title = isReturningUser ? "Based on your recent activity" : "Popular cars";

    useEffect(() => {
        if (!isReturningUser) {
            setSelectedFilter(HOME_CAR_SECTION.POPULAR_CARS);
        } else if (displayFilters.length) {
            setSelectedFilter(displayFilters[0]);
        } else {
            setSelectedFilter(HOME_CAR_SECTION.RECOMMENDED);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReturningUser, showFilters]);

    return (
        <div styleName={"styles.carRecommendationsWrapper"}>
            <div styleName={"styles.mainTitle"}>
                <span />
                {title}
                <span />
            </div>
            {displayFilters.length ? <div styleName={"styles.filters"}>
                {
                    (displayFilters || []).map(filter => {
                        return (
                            <div key={filtersData[filter]} styleName={`styles.filterTile ${filter === selectedFilter ? "styles.selectedFilter" : ""}`} onClick={onFilterClick.bind(null, filter)}>{filtersData[filter]}</div>
                        );
                    })
                }
            </div> : <React.Fragment />}
            <LazyComponent>
                <div styleName={"styles.carSliderWrapper"}>
                    <HomeCarSection sectionName={selectedFilter} />
                </div>
            </LazyComponent>
        </div>
    );
};

CarRecommendations.propTypes = {
    isReturningUser: PropTypes.bool,
    setIsReturningUserConnect: PropTypes.func,
    selectedCityCode: PropTypes.string,
    selectedCityName: PropTypes.string,
    recentlyViewedCars: PropTypes.array,
    hasCitySkip: PropTypes.bool,
    fetchRecentlyViewedCarsConnect: PropTypes.func
};
export default CarRecommendations;
