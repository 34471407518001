import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import MsiteIframe from "./component";
import { getRefreshTokenInfo } from "../../ae.configuration/actions";

const mapStateToProps = ({
    user: { isLoggedIn }
}) => ({
    isLoggedIn
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getRefreshTokenInfoConnect: getRefreshTokenInfo
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MsiteIframe));
