export const GALLERY_TAB_TYPE = {
    VIEW_360: "VIEW_360",
    PHOTOS: "PHOTOS",
    EXTERIOR: "EXTERIOR",
    INTERIOR: "INTERIOR",
    GALLERY: "GALLERY",
    IMPERFECTIONS: "IMPERFECTIONS"
};

export const GA_IMAGE_MAP = {
    [GALLERY_TAB_TYPE.VIEW_360]: "spinGalleryND",
    [GALLERY_TAB_TYPE.EXTERIOR]: "extGalleryND",
    [GALLERY_TAB_TYPE.INTERIOR]: "intGalleryND",
    [GALLERY_TAB_TYPE.IMPERFECTIONS]: "impGalleryND"
};
