import { UserAuthService, UserConsentService } from "../../../service";
import { updateDNDStatus, updateWAStatus } from "../../ae.configuration/actions";

const sendOTP = (mobile, product, query) => () => {
    return new Promise((resolve, reject) => {
        UserAuthService.sendOTP(mobile, product || "consumer-app", query)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};

const verifyOtp = (params) => () => {
    return new Promise((resolve, reject) => {
        UserAuthService.verifyOTP(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const login = (params) => () => {
    return new Promise((resolve, reject) => {
        UserAuthService.login(params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const updateUserConsent = (params, product = "consumer-app") => () => {
    return new Promise((resolve, reject) => {
        UserConsentService.sendConsent(params, product)
            .then(response => resolve(response))
            .catch(error => reject(error));
    });
};

const getUserConsent = (params, product = "consumer-app") => () => {
    return new Promise((resolve, reject) => {
        UserConsentService.getUserConsentDetails(params, product)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
    });
};

const fetchUserConsent = (params, product = "consumer-app") => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch(getUserConsent({ uid: params.uid }, product))
            .then((consentDetail = {}) => {
                dispatch(updateDNDStatus(consentDetail?.dnd || false));
                if (!consentDetail?.whatsapp) {
                    dispatch(updateWAStatus(consentDetail?.whatsapp || false));
                }
                resolve(consentDetail);
            }).catch(error => reject(error));
    });
};

export {
    sendOTP,
    verifyOtp,
    login,
    updateUserConsent,
    getUserConsent,
    fetchUserConsent
};
