import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import HomePageHeader from "./component";

import {updateCity} from "../header-revamp-v2/actions";
import { resetSellerFaqs } from "../faq/actions";
import { reloadCarList } from "../car-listing/actions";
import { updateLocationPopupShown, userProfile } from "../../ae.configuration/actions";

const mapStateToProps = ({
    filters: {
        allFilters,
        appliedFilters
    },
    carListing: {
        homepageTemplate = {}
    },
    location: { selectedCity },
    config: { cityList: cities },
    user: {
        secureToken,
        mobilePhone,
        gaId,
        isLoggedIn,
        name: userName
    }
}) => ({
    selectedCity,
    cities,
    allFilters,
    secureToken,
    mobilePhone,
    gaId,
    isLoggedIn,
    userName,
    appliedFilters,
    homepageTemplate
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCityConnect: updateCity,
    resetSellerFaqsConnect: resetSellerFaqs,
    reloadCarListConnect: reloadCarList,
    userProfileConnect: userProfile,
    updateLocationPopupShownConnect: updateLocationPopupShown
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePageHeader));
