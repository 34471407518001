/* eslint-disable max-statements */
/* eslint-disable complexity */

import React, { memo, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

import LandingHeroBanner from "../landing-hero-banner";
import UsedCarsCatagory from "../used-cars-catagory";
import LazyComponent from "../../shared/lazy-component";
import ErrorHandler from "../../shared/with-error-handler";
import MetaTagsCarHome from "../../shared/meta-tags-car-home";
import FaqSection from "../faq-section";
import BudgetCars from "../budget-cars";

// Components
const OurPresenceInRegions = loadable(() => import("../presence-regions"), { ssr: true });
const Cars24ExclusiveBenefit = loadable(() => import("../cars24-exclusive-benefits"));
const ExclusiveBannersSection = loadable(() => import("../exclusive-banners-section"), { ssr: false });
const DownloadCars24AppFloatingWidget = loadable(() => import("../download-cars24-app-floating-widget"), {ssr: false});
const WhyChooseCars24 = loadable(() => import("../why-choose-cars24"), {ssr: false});

// Utilities
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";

// Utilities
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import CarRecommendations from "../car-recommendations";
import isEmpty from "../../../utils/helpers/is-empty";

// Constants
import { pageSectionsConfig } from "./constants";

const MemoizedHomePageSection = memo(({ sectionInfo }) => {
    return (
        <Fragment>
            <div styleName={"styles.exclusiveBenefitWrap"}>
                <LazyComponent>
                    <Cars24ExclusiveBenefit sectionInfo={sectionInfo} />
                </LazyComponent>
            </div>
        </Fragment>
    );
});

const Home = ({
    isSSR,
    selectedCityName,
    reloadCarListConnect,
    clearAllFiltersConnect,
    updateSSRStatusConnect,
    fetchFaqConnect,
    homePageFaq = [],
    initializeTestInjectionReducerConnect,
    homepageTemplate,
    fetchHomepageContentConnect
}) => {

    useEffect(() => {
        if (isSSR) {
            updateSSRStatusConnect(false);
        }
    }, [isSSR, updateSSRStatusConnect]);

    useEffect(() => {
        if (homepageTemplate?.isDefaultConfig || isEmpty(homepageTemplate)) {
            fetchHomepageContentConnect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // COMMENT BELOW LINE , to Check SSR and CSR cases
        initializeTestInjectionReducerConnect("HOME USE EFFECT");

        if (!homePageFaq?.length) {
            fetchFaqConnect({ isHomepage: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        reloadCarListConnect(true);
        clearAllFiltersConnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getHomePageFaqs = () => {
        let faqsForSchema = [];
        (homePageFaq || []).forEach(({ faqList }) => {
            faqsForSchema = [...faqsForSchema, ...faqList];
        });
        return faqsForSchema;
    };

    const onViewAllCallback = () => {
        trackDesktopCustomEventsAE("faqViewAllClick");
    };

    const handleFaqExpand = () => {
        trackDesktopCustomEventsAE("faqClickHome");
    };
    const onScreenFilters = [FILTER_ENTITIES.MAKE, FILTER_ENTITIES.PRICE, FILTER_ENTITIES.BODY_TYPE, FILTER_ENTITIES.CATEGORY];

    const { pageSections = [] } = homepageTemplate || {};

    return (
        <ErrorHandler>
            <MetaTagsCarHome />
            {
                (pageSections || []).map(pageSectionInfo => {
                    const {pageSection} = pageSectionInfo || {};
                    const { name: pageSectionName } = pageSection || {};
                    switch (pageSectionName) {
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_HERO_BANNER:
                            return (<section styleName={"styles.heroBannerContainer"}>
                                <LandingHeroBanner  sectionInfo ={pageSection}/>
                            </section>);
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_FILTERS:
                            return (<section styleName={"styles.usedCarCategory"}>
                                <UsedCarsCatagory filterTypes={onScreenFilters} />
                            </section>);
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_CAR_RECOMMENDATION:
                            return (<section styleName={"styles.carRecommendationContainer"}>
                                <CarRecommendations />
                            </section>);
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_EXCLUSIVE_OFFERS:
                            return (<ExclusiveBannersSection sectionInfo={pageSection} />);
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_HOW_IT_WORKS:
                            return (<MemoizedHomePageSection sectionInfo ={pageSection} />);

                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_BENEFITS:
                            return (<WhyChooseCars24 sectionInfo={pageSection} />);
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_LOCATIONS:
                            return (<section styleName={"styles.ourPresenceSection"}>
                                <OurPresenceInRegions />
                            </section>);
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_BLOGS_SECTION:
                            return (<></>);
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_BUDGET_SECTION:
                            return (<section styleName={"styles.budgetCarsSection"}>
                                <BudgetCars />
                            </section>);
                        case pageSectionsConfig.SUPER_APP_HOME_PAGE_DESKTOP_FAQS:
                            return (<section styleName={"styles.faqWrapper"}>
                                <FaqSection
                                    title={pageSection?.title || ""}
                                    selectedCityName={selectedCityName}
                                    faqs={getHomePageFaqs()}
                                    onViewAllCallback={onViewAllCallback}
                                    onFaqExpandCallback={handleFaqExpand}
                                />
                            </section>);
                        default: return null;
                    }
                })
            }
            <div>
                <DownloadCars24AppFloatingWidget />
            </div>
        </ErrorHandler>
    );
};

Home.propTypes = {
    selectedCityName: PropTypes.string,
    reloadCarListConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    isSSR: PropTypes.bool,
    updateSSRStatusConnect: PropTypes.func,
    homePageFaq: PropTypes.array,
    fetchFaqConnect: PropTypes.func,
    initializeTestInjectionReducerConnect: PropTypes.func,
    homepageTemplate: PropTypes.object,
    fetchHomepageContentConnect: PropTypes.func
};

MemoizedHomePageSection.propTypes = {
    sectionInfo: PropTypes.number
};

export default Home;
