import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    FETCH_PAGE_CONTENT_SUCCESS
    FETCH_SERVICE_DESCRIPTION_SUCCESS

    UPDATE_SHOW_APP_DOWNLOAD_WIDGET
`,
    {
        prefix: "carServicing/"
    }
);
