import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    UPDATE_IS_DESKTOP_MODAL_VIEW
    
`,
    {
        prefix: "desktopModalViewConig/"
    }
);
