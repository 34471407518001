import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ServicingHomeBanner from "./component";
import { updateShowAppDownloadWidget } from "../config-ssr/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateShowAppDownloadWidgetConnect: updateShowAppDownloadWidget
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ServicingHomeBanner);
