import { AE_HOMEPAGE_PAGINATION_SIZE, NUMBER } from "../../../constants/app-constants";
import { FILTER_ENTITIES } from "../../../utils/filters-v2/constants";

export const defaultParams = {
    size: AE_HOMEPAGE_PAGINATION_SIZE,
    page: NUMBER.ZERO,
    variant: "filterV5"
};

export const defaultFilterPayload = {
    selectedFilters: [],
    filters: {}
};

export const minWidthFilters = {
    [FILTER_ENTITIES.MAKE]: "36px",
    [FILTER_ENTITIES.PRICE]: "44px",
    [FILTER_ENTITIES.YEAR]: "27px",
    [FILTER_ENTITIES.EMI]: "20px",
    [FILTER_ENTITIES.BODY_TYPE]: "62px",
    [FILTER_ENTITIES.ODOMETER_READING]: "48px"
};

export const defaultHomePageTemplate = {
    "isDefaultConfig": true,
    "name": "Super app homepage (Desktop)",
    "slug": "home-page-desktop",
    "seo": "",
    "breadcrumb": [],
    "schema": "",
    "pageSections": [
        {
            "displayOrder": 1,
            "pageSection": {
                "id": 3358,
                "name": "desktopSuperAppHomepageNavBar",
                "title": "Navigation Bar",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": 3211,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/10/7787f62d-d47d-436f-9802-e4124d82fb39Group%20632017.png",
                            "caption": null,
                            "alternativeText": "cars24_Logo"
                        },
                        "title": "cars24Logo"
                    },
                    {
                        "image": {
                            "id": 3212,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/10/f29a4e00-2cf8-4156-a742-1ce541b85192location_on.png",
                            "caption": null,
                            "alternativeText": "location"
                        },
                        "title": "location",
                        "description": "{ color: \"#ffffff\"}"
                    },
                    {
                        "title": "searchBar"
                    },
                    {
                        "image": {
                            "id": 3213,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/10/71614139-8fe3-4368-9e4d-502b04e74e22car%20key.png",
                            "caption": null,
                            "alternativeText": "sell_car"
                        },
                        "title": "sellCar",
                        "description": "{color: \"#ffffff\",\nbackground: \"#0E453E\"}"
                    },
                    {
                        "image": {
                            "id": 3214,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/10/6eb2e3ec-fd12-4681-83f8-9bc437418867person.png",
                            "caption": null,
                            "alternativeText": "User"
                        },
                        "title": "userName",
                        "description": "{ color: \"#ffffff\"}"
                    },
                    {
                        "image": {
                            "id": 3215,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/10/86cfb3b0-99d5-4c28-9324-a1132303b7ecmenu.png",
                            "caption": null,
                            "alternativeText": "menu_bar"
                        },
                        "title": "menuBar"
                    }
                ],
                "backgroundColor": "#0032A6",
                "gradientColor": "linear-gradient(to left, #f8931f, #ff6359)",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 2,
            "pageSection": {
                "id": 3069,
                "name": "desktopSuperAppHomepageLandingBanner",
                "title": "Landing Banner",
                "sectionType": "HeroBanner",
                "subSections": [
                    {
                        "id": 3179,
                        "name": "desktopSuperAppHomepageServiceInlets",
                        "title": "Browse from all our services",
                        "sectionType": "Grid",
                        "sectionData": [
                            {
                                "image": {
                                    "id": 3127,
                                    "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/d10c312a-79dc-4c12-ae78-305182ed5bd3Buy%20Car.webp",
                                    "caption": null,
                                    "alternativeText": "buy_car"
                                },
                                "title": "Buy car",
                                "subTitle": "Best in class cars to buy",
                                "description": "",
                                "primaryCtaUrl": "/buy-used-cars-dubai/",
                                "primaryCtaType": "NAVIGATE"
                            },
                            {
                                "image": {
                                    "id": 3128,
                                    "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/024f71d9-0dc0-4b86-af43-c4c4e581de0cSell%20Car.webp",
                                    "caption": null,
                                    "alternativeText": "sell_car"
                                },
                                "title": "Sell car",
                                "subTitle": "Get best price for your car",
                                "primaryCtaUrl": "/sell-used-car/",
                                "primaryCtaType": "NAVIGATE"
                            },
                            {
                                "image": {
                                    "id": 3134,
                                    "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/12a37570-05f9-4c86-af0b-fba60576db22Hire%20Driver.webp",
                                    "caption": null,
                                    "alternativeText": "hire_driver"
                                },
                                "title": "Hire driver",
                                "subTitle": "Best drivers at service",
                                "primaryCtaUrl": "/chauferly-safe-driver-dubai/",
                                "primaryCtaType": "REDIRECT"
                            },
                            {
                                "image": {
                                    "id": 3135,
                                    "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/00657091-4ca2-45c4-b5a0-20c6b37d2164Loan.webp",
                                    "caption": null,
                                    "alternativeText": "loan"
                                },
                                "title": "Get loan",
                                "subTitle": "Best loan offers for you",
                                "primaryCtaUrl": "/car-loan/",
                                "primaryCtaType": "NAVIGATE"
                            },
                            {
                                "image": {
                                    "id": 3142,
                                    "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/c7b9ff20-6153-42ba-a582-dd7fbd580aeeServicing.webp",
                                    "caption": null,
                                    "alternativeText": "car_servicing"
                                },
                                "title": "Servicing",
                                "subTitle": "Best services & prices",
                                "primaryCtaUrl": "/car-servicing/",
                                "primaryCtaType": "NAVIGATE"
                            },
                            {
                                "image": {
                                    "id": 3143,
                                    "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/12a54ba1-e54f-4fd6-a42d-8e440ccd4bedValuation.webp",
                                    "caption": null,
                                    "alternativeText": "valuation_certificate"
                                },
                                "title": "Valuation",
                                "subTitle": "Get loan evaluation",
                                "primaryCtaUrl": "/bank-valuation-certificate/",
                                "primaryCtaType": "NAVIGATE"
                            }
                        ],
                        "subsectionOrder": null,
                        "buNames": [],
                        "statsig": false
                    }
                ],
                "subsectionOrder": null,
                "backgroundImage": {
                    "caption": null,
                    "alternativeText": "Landing Banner",
                    "url": "https://media-ae.cars24.com/blog/cms/cms/2025/02/25/10a96a9e-23f6-492f-909e-e29a5579ff92Top%20fold%20hero%20image.webp"
                },
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 3,
            "pageSection": {
                "id": 3180,
                "name": "desktopSuperAppHomepageFilters",
                "title": "Used car Filters",
                "sectionType": "ContentWithImage",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 4,
            "pageSection": {
                "id": 3181,
                "name": "desktopSuperAppHomepageCarRecommendation",
                "title": "Based on your activity",
                "sectionType": "CardSection",
                "sectionData": [
                    {}
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 5,
            "pageSection": {
                "id": 3182,
                "name": "desktopSuperAppHomepageExclusiveOffers",
                "title": "Your personalised offers",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": 3155,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/31f62842-65d6-4db3-a3c9-dbdeb0023c7aExchange%20car.webp",
                            "caption": null,
                            "alternativeText": "exchange_car"
                        },
                        "title": "Exchange \\n your car",
                        "subTitle": "Get up to AED 20000 OFF",
                        "primaryCtaUrl": "/buy-used-cars-uae/",
                        "primaryCtaText": "View Offers",
                        "primaryCtaType": "REDIRECT"
                    },
                    {
                        "image": {
                            "id": 3156,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/bd9cb43a-ad18-4307-bbcf-d637bee59009Downpayment.webp",
                            "caption": null,
                            "alternativeText": "zero_downpayment"
                        },
                        "title": "Zero \\n Downpayment",
                        "subTitle": "Quick and easy finance",
                        "primaryCtaUrl": "/car-loan/",
                        "primaryCtaText": "Know More",
                        "primaryCtaType": "REDIRECT"
                    },
                    {
                        "image": {
                            "id": 3157,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/11e96002-a806-4248-9a89-583aa5733f2fSell%20car.webp",
                            "caption": null,
                            "alternativeText": "sell_car"
                        },
                        "title": "Sell your car to \\n us hasselfree",
                        "subTitle": "Most trusted car company",
                        "primaryCtaUrl": "/sell-used-car/",
                        "primaryCtaText": "Sell Now",
                        "primaryCtaType": "REDIRECT"
                    },
                    {
                        "image": {
                            "id": 3162,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/6a5278bc-7041-4d7d-8d06-82cf6a1c5b13Loan.webp",
                            "caption": null,
                            "alternativeText": "loans"
                        },
                        "title": "Make your \\n dreams real",
                        "subTitle": "with a personal loan",
                        "primaryCtaUrl": "/car-loan/",
                        "primaryCtaText": "Apply Now",
                        "primaryCtaType": "REDIRECT"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 6,
            "pageSection": {
                "id": 3183,
                "name": "desktopSuperAppHomepageHowItWorksSection",
                "title": "How it works",
                "sectionType": "GridSubSection",
                "sectionData": [
                    {
                        "image": {
                            "id": 3144,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/cd377a53-6c2e-473c-90c8-9c661f2ecb82Test%20drives.webp",
                            "caption": null,
                            "alternativeText": "free_test_drive"
                        },
                        "title": "Free Test Drive On All Cars",
                        "subTitle": "Visit us or book free test drive from the comfort of your home."
                    },
                    {
                        "image": {
                            "id": 3146,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/63ec80d0-8682-4a4a-95d2-989ea59ece307%20day%20trial.webp",
                            "caption": null,
                            "alternativeText": "7_day_trail"
                        },
                        "title": "7 Day Trial, Full Refund",
                        "subTitle": "Try your car for a week. Not happy? Return for full refund"
                    },
                    {
                        "image": {
                            "id": 3147,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/3c3f48fc-841c-4183-afc1-1b4055bfcae3Easy%20finance.webp",
                            "caption": null,
                            "alternativeText": "easy_finance"
                        },
                        "title": "Easy Insurance Purchase ",
                        "subTitle": "Buy top-notch insurance plans at great discounts."
                    },
                    {
                        "image": {
                            "id": 3148,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/a4d2aa80-23e8-483a-8e75-25343081d05cWarranty.webp",
                            "caption": null,
                            "alternativeText": "warranty"
                        },
                        "title": "Upto 6 Month Warranty ",
                        "subTitle": "10,000 kms service & extended warranty plans available."
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 7,
            "pageSection": {
                "id": 3184,
                "name": "desktopSuperAppHomepageBenefitsSection",
                "title": "Why buy from CARS24",
                "sectionType": "Grid",
                "sectionData": [
                    {
                        "image": {
                            "id": 3149,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/7d14e6a0-0836-47cc-897d-7532c1bcaaf4Best%20in%20class.png",
                            "caption": null,
                            "alternativeText": "best_in_class"
                        },
                        "title": "Best in class services",
                        "subTitle": "Buy from CARS24 range of high quality cars. Enjoy competitive loan, additional services and hassle free transfers"
                    },
                    {
                        "image": {
                            "id": 3189,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/b359da4a-6c2d-4b66-a9db-47c856b4034d7%20day%20return.png",
                            "caption": null,
                            "alternativeText": "easy_return"
                        },
                        "title": "7 day return policy",
                        "subTitle": "Free return of the vehicle within 7 days of purchase"
                    },
                    {
                        "image": {
                            "id": 3190,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/4bd55394-bb7d-42fd-87f3-e554d2bef956State%20of%20art.png",
                            "caption": null,
                            "alternativeText": "state_of_art"
                        },
                        "title": "State of the art facility",
                        "subTitle": "Service centres equipped with the most advanced tech & machines"
                    },
                    {
                        "image": {
                            "id": 3191,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/1400449e-c0c5-498d-8f93-e72bd8adb604Trust.png",
                            "caption": null,
                            "alternativeText": "trust"
                        },
                        "title": "Trusted by 20K+ car buyers",
                        "subTitle": "Trusted by thousands of customers across UAE"
                    },
                    {
                        "image": {
                            "id": 3192,
                            "url": "https://media-ae.cars24.com/blog/cms/cms/2025/03/06/97bfd188-5417-402c-8956-19760c8a1019High%20quality.png",
                            "caption": null,
                            "alternativeText": "high_quality"
                        },
                        "title": "High quality used cars",
                        "subTitle": "Best quality used cars with top maintenance records"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 8,
            "pageSection": {
                "id": 3185,
                "name": "desktopSuperAppHomepageLocations",
                "title": "Our presence in 4 regions in UAE ",
                "sectionType": "ContentWithImage",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 9,
            "pageSection": {
                "id": 3188,
                "name": "desktopSuperAppHomepageRequestCallback",
                "title": "Drive smart with our app",
                "sectionType": "Contact",
                "sectionData": [
                    {
                        "email": "em@gmail.com",
                        "title": "Texter"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 10,
            "pageSection": {
                "id": 3189,
                "name": "desktopSuperAppHomepageBudgetSection",
                "title": "Used cars by budget in UAE",
                "sectionType": "SEOContentSection",
                "sectionData": [
                    {
                        "title": "Testing",
                        "description": "head"
                    }
                ],
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        },
        {
            "displayOrder": 11,
            "pageSection": {
                "id": 3190,
                "name": "desktopSuperAppHomepageFaqs",
                "title": "Frequently asked questions",
                "sectionType": "Faq",
                "subsectionOrder": null,
                "buNames": [],
                "statsig": false
            }
        }
    ],
    "globalCities": null
};
