import React from "react";
import { Helmet } from "react-helmet-async";

// Styles

// Components
import withAELayout from "../../../components/ae.desktop/layout";
import ErrorHandler from "../../../components/shared/with-error-handler";
import CarServicingHome from "../../../components/ae.desktop/car-servicing-home-page";

// Constants
import { appUrl } from "../../../constants/url-constants";
import { ROUTE_PATHS } from "../../../constants/routes-constants";

//Locale
import serviceContractLocale from "../../../locales/desktop/service-contract";

const CarServicingHomeWrapped = () => {
    const baseUrl = `${appUrl()}/${ROUTE_PATHS.carServicing}`;
    const { meta: { title: seoTitle, description } } = serviceContractLocale;
    return (
        <React.Fragment>
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={description} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${baseUrl}`} rel="canonical" />
                <link rel="alternate" href="https://www.cars24.ae/car-servicing/" hrefLang="en-AE" data-react-helmet="true" />
                <link rel="alternate" href="https://www.cars24.com/" hrefLang="en-IN" data-react-helmet="true" />
                <link rel="alternate" href="https://www.cars24.com.au/" hrefLang="en-AU" data-react-helmet="true" />
            </Helmet>
            <ErrorHandler>
                <CarServicingHome />
            </ErrorHandler>
        </React.Fragment>
    );
};
export default withAELayout(CarServicingHomeWrapped, true, true);

