import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarRecommendations from "./component";
import { fetchRecentlyViewedCars, setIsReturningUser } from "../filters/actions";

const mapStateToProps = ({
    location: {
        selectedCity: {
            code: selectedCityCode,
            name: selectedCityName,
            hasCitySkip
        } = {}
    },
    filters: {
        recentlyViewedCars,
        isReturningUser,
        showRecentlyUsedCars
    }
}) => ({
    selectedCityCode,
    selectedCityName,
    isReturningUser,
    showRecentlyUsedCars,
    recentlyViewedCars,
    hasCitySkip
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    setIsReturningUserConnect: setIsReturningUser,
    fetchRecentlyViewedCarsConnect: fetchRecentlyViewedCars
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarRecommendations);
