
export const c2cCheckoutPageEvents = {
    "c2cLogin": {
        "event": "sell_custom_event",
        "eventCategory": "mp_pitch",
        "eventAction": "c2c_login"
    },
    "c2cEnterPrice": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "Enterprice"
    },
    "c2cSubmitOffer": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "Submit_Offer"
    },
    "c2cEnteredLowPrice": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "Entered_Lowprice",
        "eventLabel": "NA"
    },
    "c2cSubmitOfferPostRejection": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "Submit_Offer_PostRejection"
    },
    "c2cHelpC2cCheckout": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "Help_C2C_Checkout",
        "eventLabel": "NA"
    },
    "c2cBackButtonC2cCheckout": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "Backbutton_C2C_Checkout",
        "eventLabel": "NA"
    },
    "c2cCheckoutPageVisit": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "Checkout_Page_Visit"
    },
    "c2cSellerCounterOfferPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "SellerCounterOffer_Page"
    },
    "c2cSellerCounterOfferAction": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_C2C_Checkout_Page",
        "eventAction": "SellerCounterOffer_Action"
    }
};
