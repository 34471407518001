import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import loadable from "@loadable/component";

// Component
import withAELayout from "../../../components/ae.desktop/layout";
import ErrorHandler from "../../../components/shared/with-error-handler";
const BvcWrapper = loadable(() => import("../../../components/ae.desktop/bvc-wireframe"), {ssr: true});

//Utils
import valuationCertificateLocale from "../../../locales/desktop/valuation-certificate";
import { appUrl } from "../../../constants/url-constants";

//Constants
import { ROUTE_PATHS } from "../../../constants/routes-constants";

const BankValuationCertificate = () => {

    const baseUrl = `${appUrl()}/${ROUTE_PATHS.bankValuationCertificate}/`;
    const { meta: metaLocale } = valuationCertificateLocale;

    return (
        <Fragment>
             <Helmet>
                <title>{metaLocale.title}</title>
                <meta name="description" content={metaLocale.description} />
                <meta name="ROBOTS" content="INDEX,FOLLOW" />
                <link href={`${baseUrl}`} rel="canonical" />
                <link rel="alternate" href="https://www.cars24.ae/bank-valuation-certificate/" hrefLang="en-AE" data-react-helmet="true" />
                <link rel="alternate" href="https://www.cars24.com/" hrefLang="en-IN" data-react-helmet="true" />
                <link rel="alternate" href="https://www.cars24.com.au/" hrefLang="en-AU" data-react-helmet="true" />
            </Helmet>
            <ErrorHandler>
              <BvcWrapper />
            </ErrorHandler>
        </Fragment>
    );
};

export default withAELayout(BankValuationCertificate, true, true);
