export const b2cListingPageEvents = {
    "appDownloadBottomDrawerListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "app_download_bottom_drawer"
    },
    "b2cListingPageVisited": {
        "event": "b2c_listing_page_visited",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "listing_page_visited"
    },
    "carDetailsClickListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "car_details_click"
    },
    "filterSortListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "filter_sort"
    },
    "filterApplyActionsListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "filter_apply_actions"
    },
    "listingSearchClick": {
        "eventAction": "listing_search",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "event": "listing_search_clicked"
    },
    "listingSearchResultClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "Search Result Click "
    },
    "changeCityListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "Change City",
        "eventLabel": "NA"
    },
    "b2cZeroDPListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "Zero_DP_click",
        "eventLabel": "NA"
    },
    "filterNoCar": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "filter_no_car"
    },
    "reservedcarCardClick": {
        "event": "b2c_reservedcar_card_click",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "reservedcar_card_click"
    },
    "b2cBackToTopShownListingPage": {
        "event": "b2c_Back_to_Top_Shown",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "Back_to_Top_Shown",
        "eventLabel": "NA"
    },
    "b2cBackToTopClickedListingPage": {
        "event": "b2c_Back_to_Top_Clicked",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "Back_to_Top_Clicked"
    },
    "b2cBuyerSellCardClick": {
        "event": "b2c_Buyer_sell_card_click",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "buyer_sell_card_click",
        "eventLabel": "NA"
    },
    "addToWishlistListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "add_to_wishlist"
    },
    "removeFromWishlist": {
        "event": "remove_from_wishlist",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "remove_from_wishlist"
    },
    "filterByBudget": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "filter_by_budget"
    },
    "filterByMake": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "filter_by_make"
    },
    "filterByBodyType": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "filter_by_body_type"
    },
    "ramadanOfferCardClicked": {
        "event": "b2c_ramadan_offer_card",
        "eventAction": "ramadan_offer_card",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventLabel": "ramadan_offer_card"
    },
    "filterBucket": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "filter_bucket"
    },
    "clickSort": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "click_sort"
    },
    "listingBanner": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "listing_banner"
    },
    "guidedFilter": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "guided_filter"
    },
    "quickFilter": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "quick_filter"
    },
    "loginWishlist": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "login"
    },
    "loginMenu": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "login"
    },
    "loginAddToWishlist": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "login"
    },
    "loginMyBookings": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "login"
    },
    "loginsellorder": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "login"
    },
    "saleListingBannerLogin": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "login"
    },
    "listingPageSaleBanner": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "listing_banner"
    },
    "clearAll": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "clear_all"
    },
    "clearFilter": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "clear_filters"
    },
    "financeFilter": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "zero_dp_details",
        "eventLabel": "finance_filter"
    },
    "wishlistSimilarCarListingCarClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "similar_car_listing_car_click"
    },
    "compareCtaClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "compare_cta_click"
    },
    "compareCars": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "compare_cars"
    },
    "startPurchaseCompareCars": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "Start Purchase",
        "eventLabel": "car_compare"
    },
    "addToWishlist": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "add_to_wishlist",
        "eventLabel": "car_compare"
    },
    "differences": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "differences",
        "eventLabel": "car_compare"
    },
    "sellCarListingBannerClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "sell_banner"
    },
    "loginSellCarBanner": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "login"
    },
    "listingCarCardClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "listing_car_card_click"
    },
    "multiplepushComponent": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "multiplepush_component"
    },
    "ctaAddcars": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "cta_addcars"
    },
    "ctaViewcars": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "cta_viewcars"
    },
    "modelGuidedViewAll": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "model_guided_view_all"
    },
    "carAffordabilityListingComp": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "loan_eligibility_new_comp"
    },
    "carAffordabilityLoanToggle": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "loan_toggle"
    },
    "carAffordabilityLoanNudgeShown": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "loan_nudge _shown"
    },
    "openAppDownloadBottomDrawer": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "app_download_bottom_drawer_shown"
    },
    "similarCarNudgeSwipe": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "similar_car_nudge_swipe",
        "eventLabel": "listing"
    },
    "similarCarNudgeClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "similar_car_nudge_click",
        "eventLabel": "listing"
    },
    "loginSnackbarClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "login_snackbar_nudge_click"
    },
    "saveSearchSnackbarClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "save_search_snackbar_click"
    },
    "saleSnackBarClickListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "sale_snackbar",
        "eventLabel": "login"
    },
    "saleBannerLoginClickListingPage": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "listing_banner",
        "eventLabel": "sale_login"
    },
    "carCategoryKnowMoreClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "car_category_banner"
    },
    "listingCarCardWishlistClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "listing_card_wishlist_click"
    },
    "listingPageAppDownloadBannerClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "app_download_banner_click"
    },
    "userSessionStart": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_Listing_Page",
        "eventAction": "clevertap_id_tracker"
    },
    "locationCtaListing": {
        "event": "custom_event",
        "eventCategory": "B2C_Buy_Used_Listing_Page",
        "eventAction": "location_cta",
        "eventLabel": "listing"
    }
};
