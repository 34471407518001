export const parseJsonObj = (jsonString = "") => {

    if (!jsonString) {
        return {};
    }

    const correctedStr = jsonString.replace(/(\w+):/g, "\"$1\":"); // Ensure keys are quoted
    const jsonObj = JSON.parse(correctedStr);

    return jsonObj;
};
